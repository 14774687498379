import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";

export default function MoveBalance(props) {
  const color = props.action === "CREDIT" ? "green" : "red";

  const handleChangeAmount = (event) => {
    const input = event.target.value;
    const formattedInput = input.replace(/[^0-9.]/g, "");
    if (/^\d*(\.\d{0,2})?$/.test(formattedInput)) {
      formik.setFieldValue("amount", formattedInput);
    }
  };

  const validationSchema = yup.object({
    company: yup
      .string()
      .required("Please retype the Company")
      .test(
        "company_match",
        "Must match with the one shown in the previous field.",
        (value) => formik.values.company === props.companyName
      ),
    amount: yup
      .number()
      .typeError("Amount must be a number")
      .positive("Amount must be a positive number")
      .max(9999999.99, "Amount must be less than 10,000,000")
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .nullable()
      .required("Amount is required"),
    reference: yup.string().required("Reference is required"),
  });

  const formik = useFormik({
    initialValues: {
      idBalance: "",
      company: "",
      amount: "",
      reference: "",
      action: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      moveBalanceMutation.mutate({ ...values });
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setFieldValue("idBalance", props.idBalance);
    formik.setFieldValue("action", props.action);
  }, []);

  const queryClient = useQueryClient();

  const moveBalanceMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/balance/movements/apply`,
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["balances"] });
        props.handleClose();
      },
      onError: (err) => {
        console.error(err);
        // const msg = err?.response?.data
        //    ? err.response.data
        //    : err.message;
        //   setFeedback({
        //   open: true,
        //   type: "error",
        //   message: msg,
        // });
      },
    }
  );

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container p={1}>
            <Grid item xs={6} style={{ maxWidth: "300px" }}>
              <Typography variant="h5">Company:</Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {props.companyName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container p={1}>
            <Grid item xs={6} style={{ maxWidth: "300px" }}>
              <Typography variant="h5">Retype the Company:</Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                id="company"
                value={formik.values.company}
                onChange={formik.handleChange}
                onPaste={(event)=>event.preventDefault()}
                InputProps={{
                  style: { fontSize: "2em" },
                  inputProps: {
                    style: { textAlign: "left", padding: 1, margin: 1 },
                  },
                }}
                error={formik.touched.company && Boolean(formik.errors.company)}
                helperText={formik.touched.company && formik.errors.company}
                fullWidth
                type="text"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid container p={1}>
            <Grid item xs={6} style={{ maxWidth: "150px" }}>
              <Typography variant="h5">Action:</Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography variant="h4" color={color}>
                {props.action}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid container p={1}>
            <Grid item xs={6} style={{ maxWidth: "150px" }}>
              <Typography variant="h5">Amount:</Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                id="amount"
                value={formik.values.amount}
                onChange={handleChangeAmount}
                // onChange={formik.handleChange}
                InputProps={{
                  style: { fontSize: "2em", color: color },
                  inputProps: {
                    style: { textAlign: "center", padding: 1, margin: 1 },
                  },
                }}
                error={formik.touched.amount && Boolean(formik.errors.amount)}
                helperText={formik.touched.amount && formik.errors.amount}
                fullWidth
                type="text"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid container p={1}>
            <Grid item xs={6} style={{ maxWidth: "150px" }}>
              <Typography variant="h5">Reference:</Typography>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                id="reference"
                value={formik.values.reference}
                onChange={formik.handleChange}
                fullWidth
                rows={2}
                multiline
                placeholder="Bank, account, transaction"
                InputProps={{
                  style: {
                    fontSize: "1.5em",
                    color: color,
                    padding: 10,
                    margin: 0,
                  },
                }}
                error={
                  formik.touched.reference && Boolean(formik.errors.reference)
                }
                helperText={formik.touched.reference && formik.errors.reference}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} pt={3}>
          <Button fullWidth variant="contained" type="submit" disabled={moveBalanceMutation.isLoading} >
            SAVE
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
