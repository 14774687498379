import React, { useState} from 'react';
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import PaymentsSummary from "../components/PaymentsSummary";
import { Box } from "@mui/material";

export default function QueryPaymentsSummary(props) {


  const [employerId, setEmployerId] = useState(props.employerIdentifier);

  async function fetchPaymentsSummary() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/payfile/summary?idEmployer=`+employerId
    );
    return response;
  }


  const { 
    data, 
    isFetching, 
    isLoading, 
    isError, 
    refetch 
  } = useQuery(["paymentsSummary", employerId], () => fetchPaymentsSummary(), {
    keepPreviousData: true
  });


  return (
    <Box sx={{margin: '0', padding: '0 150px'}}>
      <br />
      <br />
      <br />
      <PaymentsSummary 
        isFetching={isFetching} 
        isLoading={isLoading} 
        isError={isError} 
        data={data} 
        refetchFunction={refetch} 
        title="Payment Requests"
        setEmployerIdFunction={setEmployerId}
        employerIdentifier={employerId}
      />
    </Box>
  );
}
