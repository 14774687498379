import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


export default function RefreshingIndicator(props) {
    return (
        <div style={{marginBlock:"auto"}}>
          <CircularProgress style={{color:"darkgray" , marginRight:"10px"}} size="1rem" color="inherit" />
          <span style={{color:"darkgray" }}>Refreshing...</span>
        </div>
    );
}