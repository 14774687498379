import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { Container, Grid, Button, Typography } from "@mui/material";
import locationImg from "./../images/locations.svg";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
// import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
// import DeleteIcon from "@mui/icons-material/Delete";
import SetLocation from "../components/SetLocation";
import RenderOnRole from "../components/RenderOnRole";

export default function Locations() {
  const columns = [
    { field: "id", headerName: "ID", width: 1, hide: true },
    { field: "description", headerName: "Description", width: 180 , sortable: false, renderCell: (params) => {
      return (
          <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.description}</Typography>
        )       
        }   
    },
    { field: "address_line_1", headerName: "Address Line 1", width: 180 , sortable: false, renderCell: (params) => {
      return (
          <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.address_line_1}</Typography>
        )       
      }   
    },
    { field: "address_line_2", headerName: "Address Line 2", width: 130 , sortable: false, renderCell: (params) => {
      return (
          <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.address_line_2}</Typography>
       )       
      }     
    },
    { field: "city", headerName: "City", width: 80 , sortable: false, renderCell: (params) => {
      return (
          <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.city}</Typography>
        )       
      }   
    },
    { field: "state", headerName: "City", width: 60 , sortable: false, renderCell: (params) => {
      return (
          <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.state}</Typography>
        )       
      }   
    },
    { field: "zip", headerName: "Zip", width: 60 , sortable: false, renderCell: (params) => {
      return (
          <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.zip}</Typography>
        )       
      }   
    },
    { field: "externalId", headerName: "Identifier", width: 160 , renderCell: (params) => {
        return  <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.external_id}</Typography>
      }
    },
    { field: "inventoryManager", headerName: "Inventory Manager", width: 160 ,
    renderCell: (params) => {
      if(params.row.first_name){
        return <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.first_name+ " "+params.row.last_name}</Typography>
      }else{
        return '';
      }
       
      
    }
    
  
    },
    { field: "email", headerName: "Email", width: 80, renderCell: (params) => {
      return  <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.email}</Typography>
    } },
    { field: "phone", headerName: "Phone", width: 100,renderCell: (params) => {
      return  <Typography fontSize={12} sx={{ width: '33%', flexShrink: 0 }}>{params.row.phone}</Typography>
    }}

    // {
    //   field: "action",
    //   headerName: "Action",
    //   renderCell: (params) => {
    //     return (
    //       // you will find row info in params
    //       <div>
    //         <Button style={{ minWidth: "10px" }}>
    //           <EditLocationAltIcon
    //             onClick={(e) => onEditButtonClick(e, params.row)}
    //             variant="contained"
    //           />
    //         </Button>
    //         <Button style={{ minWidth: "10px" }}>
    //           <DeleteIcon
    //             onClick={(e) => onDeleteButtonClick(e, params.row)}
    //             variant="contained"
    //           />
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const onAddButtonClick = (e, params) => {
    setOpenNewDlg(true);
  };


  async function fetchLocations() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/locations`
    );
    return response;
  }

  const { data, isLoading, isError } = useQuery(["locations"], fetchLocations);

  const [openNewDlg, setOpenNewDlg] = useState(false);

  return (
    <Container maxWidth="xlg">
      <br />
      <br />
      <Grid container spacing={1.5}>
        <Grid item xs={2} md={2}>
          <br />
          <br />
          <img src={locationImg} alt="locations" width="80%" loading="lazy" />
        </Grid>
        <Grid item xs={12} md={8}>
          <h1>Locations</h1>

          {isLoading ? (
            <h4>Loading...</h4>
          ) : !isError ? (
            <div style={{ height: '70vh', width: "100%" }}>
              <RenderOnRole roles={['pc-create-locations']}>
                <Button
                  style={{ margin: "5px" }}
                  onClick={(e) => onAddButtonClick(e)}
                  variant="contained"
                >
                  <AddLocationAltIcon></AddLocationAltIcon>
                  New Location
                </Button>
                </RenderOnRole>
              <DataGrid
                rows={data?.data}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[100]}
              />
            </div>
          ) : (
            <h4>An error ocurred, please try again later...</h4>
          )}
        </Grid>
      </Grid>

      <SetLocation
        action="Create"
        open={openNewDlg}
        setOpen={setOpenNewDlg}
        dialogTitle="Create a Location"
        dialogContentText="To create a location, input the address information and click create button."
      />


    </Container>
  );
}
