import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import HttpService from "../services/HttpService";

export default function DashboardLocations() {
  async function fetchData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/locations/dashboard`
    );
    return response;
  }

  const { data, isLoading } = useQuery(["/locations/dashboard"], () =>
    fetchData()
  );

  if (isLoading) return <></>;

  const columns = [
    {
      field: "location",
      headerName: "Location",
      width: 350,
      editable: false,
    },
    {
      field: "available",
      headerName: "Available cards",
      width: 250,
      editable: false,
    },
    {
      field: "assigned",
      headerName: "Assigned cards",
      width: 250,
      editable: false,
    },
    
  ];

  return (
    <Container maxWidth="xlg" m={0} p={0}>
      <Grid container m={0} p={0}>
        <Grid item xs={12} m={0} p={0}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Cards Distribution by Location
          </Typography>
        </Grid>
        <Grid item xs={12} m={0} p={0}>
          <Accordion sx={{ width: "100%" }} defaultExpanded>
            <AccordionSummary>
              <Typography variant="h7">Available</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  m={0}
                  p={0}
                  justifyContent="center"
                  alignContent="center"
                >
                  <PieChart
                    series={[
                      {
                        data: data.data.map((item, index) => ({
                          id: index,
                          value: item.available,
                          label: item.location,
                        })),
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6} m={0} p={0}>
                  <Box
                    sx={{
                      height: 400,
                      width: "100%",
                      "& .low": {
                        backgroundColor: "#ff943975",
                        color: "#1a3e72",
                      },
                    }}
                  >
                    <DataGrid
                      rows={data.data.map((item, index) => ({
                        id: index,
                        location: item.location,
                        available: item.available,
                        assigned: item.assigned
                      }))}
                      columns={columns}
                      
                      getCellClassName={(params) => {
                        return params?.row?.available <=15 ? 'low' : '';
                      }}

                    />
                  </Box>
                </Grid>
                <Grid item xs={12} m={0} p={0}>
                  <BarChart
                    xAxis={[
                      {
                        scaleType: "band",
                        data: data.data.map((item) => item.location),
                      },
                    ]}
                    series={[{ data: data.data.map((item) => item.available) }]}
                    height={350}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  );
}
