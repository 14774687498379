import React, { Fragment, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Chip, CircularProgress, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography, useTheme } from "@mui/material";
import AssignedCardDetail from '../components/AssignedCardDetail';
import RenderOnRole from './RenderOnRole';
import { useMutation } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import DownloadIcon from '@mui/icons-material/Download';
import RefreshingIndicator from './RefreshingIndicator';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

export default function AssignedCards(props) {

    const [dateFromValue, setDateFromValue] = React.useState(null);
    const [dateToValue, setDateToValue] = React.useState(null);
    const [locationIds, setLocationIds] = React.useState({});
   
   
    const [cleared, setCleared] = React.useState(false);

    const [elements, setElements] = React.useState([]);

    const theme = useTheme();


    React.useEffect(() => {
      if (cleared) {
        const timeout = setTimeout(() => {
          setCleared(false);
        }, 1500);
  
        return () => clearTimeout(timeout);
      }
      return () => {};
    }, [cleared]);

    const {
        isLoading, 
        isFetching, 
        isError, 
        data, 
        refetchFunction, 
        refetchStatsFunction, 
        setPageFunction, 
        setFilterStringFunction,
        setDateFromFunction,
        setDateToFunction,
        dataCount,
        isLoadingDataCount,
        pageSize,
        onPageSizeChange,
        setLocationIdsFunction,
        dataLocations,
        isLoadingLocations 
    } = props;


    const onViewButtonClick = (e,params) => {
       setCardIdentifier(params.id);
       setOpenNewDlg(true);

    }


    const [openNewDlg, setOpenNewDlg] = useState(false);
    const [cardIdentifier, setCardIdentifier] = useState();
    const [filter, setFilter] = useState("");

    const onFilterChange= (filter,params) => {
        const filterString = filter.quickFilterValues.join(" ");
        if (filterString===undefined) {
            setFilterStringFunction("");
            setFilter("");
        }else {
            setFilterStringFunction(filterString);
            setFilter(filterString);
        }
        return filter;
    }


    const dataColumns = [
        { field: "id", headerName: "ID", width: 70, sortable: false },
        { field: "cardNumber", headerName: "Last 4", width: 60 , sortable: false, renderCell: (params) => {
            return (
                <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.cardNumber}</Typography>
            )       
          }   
        },
        { field: "cardProxy", headerName: "Proxy", width: 100, sortable: false, renderCell: (params) => {
            return (
                <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.cardProxy}</Typography>
            )       
    }   },
        { field: "accountNumber", headerName: "Account #", width: 120 , sortable: false, renderCell: (params) => {
            return (
                <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.accountNumber}</Typography>
            )       
    }   },
        { field: "routingNumber", headerName: "Routing #", width: 100 , sortable: false, renderCell: (params) => {
            return (
                <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.routingNumber}</Typography>
            )       
    }   },
        { field: "employeeNo", headerName: "Employee No.", width: 130 , sortable: false, renderCell: (params) => {
            return (
                <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.employeeNo}</Typography>
            )       
    }   },
        { field: "extraInfo01", headerName: "Full Name", width: 180 , sortable: false, renderCell: (params) => {
            return (
                <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.extraInfo01}</Typography>
            )       
    }   },
        { field: "email", headerName: "Email", width: 230, sortable: false, renderCell: (params) => {
            return (
                <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.email?.startsWith("paycard_noemail_") ? "No Email" : params.row.email}</Typography>
            )       
    }   },
    { field: "locationDescription", headerName: "location", width: 140, sortable: false, renderCell: (params) => {
        return (
            <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.locationDescription}</Typography>
        )       
}   },
        { field: "status", headerName: "Status", width: 100 , sortable: false, renderCell: (params) => {
            return (
                <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.status}</Typography>
            )       
            }   
        },
        { field: "assignDate", headerName: "Assigned Date", width: 160 , sortable: false, renderCell: (params) => {
            return (
                <Typography fontSize={14} sx={{ width: '33%', flexShrink: 0 }}>{params.row.assignDate}</Typography>
            )       
            }   
        },
        { field: "details", headerName: "Details", width: 80, sortable: false, renderCell: (params) => {
                return (
                <RenderOnRole roles={['pc-view-detail']}>
                    <Button style={{ minWidth: "10px" }}
                        ><VisibilityIcon
                            onClick={(e) => onViewButtonClick(e, params.row)}
                            variant="contained"
                        />
                    </Button> 
                </RenderOnRole>
                )       
        }}
      ];


      const downloadAssignedMutation = useMutation(
        (params) => {
            const locations = Array.from(locationIds).length > 0 ? Array.from(locationIds).join(",") : null;

          return HttpService.getAxiosClient().get(
            window.API_URL + `/paycards/assigned/export?filter=`+filter+`&from=`+dateFromValue+`&to=`+dateToValue+(locations!==null ? `&locations=`+locations: ""),
            {responseType: 'blob'}
          );
        },
        {
          onSuccess: (response) => {
            const { headers, data } = response;
            const disposition = headers['content-disposition'];
            let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
            if (filename.toLowerCase().startsWith("utf-8''"))
               filename = decodeURIComponent(filename.replace("utf-8''", ''));
            else
               filename = filename.replace(/['"]/g, '');
            let url = window.URL.createObjectURL(data);
            let a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a); // append the element to the dom
            a.click();
            a.remove(); // afterwards, remove the element 
            setFeedback({
              open: true,
              type: "success",
              message: "The file was download succesfully",
              onClose: () => {  setFeedback({open: false}); },
            });
            
          },
          onError: (err) => {
            if (err?.response?.status===500) {
              setFeedback({
                open: true,
                type: "error",
                message: "There was an error trying to download the file",
              });
            }
            else {
              const msg = err?.response?.data
              ? err.response.data
              : err.message;
               setFeedback({
               open: true,
               type: "error",
               message: msg,
             });
            }
    
          },
        }
    );

    const [feedback, setFeedback] = useState({
        open: false,
        type: "error",
        message: "",
      });


    const handleFeedbackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setFeedback({ open: false, type: "error", message: "" });
        };
    
        
    const handleChangeElements = (event) => {
        const {
            target: { value },    
        } = event;
        setLocationIdsFunction(value);
        setLocationIds(value);
        //refetchFunction();
        setElements(
        // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

    };

    const onDownloadDetailsButtonClick = (e, params) => { 
        downloadAssignedMutation.mutate(null);
    };

    return (

        <Fragment>
            <div style={{display : "flex"}}>
                <div style={{marginRight:"20px"}}><h1>Paycards assigned</h1></div> 
                <div style={{display : "flex", justifyContent: "space-between"}}>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems:"center"}}>
                        <Button
                                style={{ margin: "10px", height: "40px" }}
                                onClick={onDownloadDetailsButtonClick}
                                variant="contained"
                                disabled={downloadAssignedMutation.isLoading}
                                color={downloadAssignedMutation.isLoading ? "secondary" : "primary"}
                            >
                                <DownloadIcon style={{paddingRight:"5px"}}/>
                                {downloadAssignedMutation.isLoading ? "Downloading..." : "Export Assigned Paycards"}
                        </Button>
                    </div>
                </div>
                {isFetching ? (<RefreshingIndicator/>) :""}
            </div>
            <div style={{ textAlign: "right" , display: "flex", justifyContent: "flex-end"}}>
                <FormControl sx={{ m: 1, width: 500, display: "flex", justifyContent: "flex-end" }}>
                    <InputLabel id="demo-multiple-chip-label">Locations</InputLabel>
                    <Select
                    //disabled={disableGroupOptions}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={elements}
                    onChange={handleChangeElements}
                    input={<OutlinedInput id="select-multiple-chip" label="LocationsChip" />}
                    renderValue={(selected, other) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {
                            selected.map((key) => {
                                return <Chip key={key} label={dataLocations?.data.find((item) => item.id === key).description} />;
                        })}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    >
                    {dataLocations?.data.map((option) => (
                        <MenuItem
                        key={option.id}
                        value={option.id}
                        style={getStyles(option.description, elements, theme)}
                        >
                        {option.description}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <div style={{ textAlign: "right" , display: "flex", justifyContent: "flex-end", alignContent:"center", alignItems:"center"   } }>
                    <LocalizationProvider dateAdapter={AdapterDayjs}  >

                        <DatePicker 
                            label="Date from:" 
                            defaultValue={null} 
                            value={dateFromValue}
                            onChange={(newValue) =>  {setDateFromValue (newValue); setDateFromFunction (newValue); refetchFunction(); } }  
                            slotProps={{
                                field: { clearable: true, onClear: () => setCleared(true) },
                            }}                      
                            />
                        <DatePicker 
                            sx={{ marginLeft: "0.5rem" }}
                            label="Date to"
                            value={dateToValue}
                            onChange={(newValue) => {setDateToValue (newValue); setDateToFunction (newValue); refetchFunction(); }}
                            slotProps={{
                                field: { clearable: true, onClear: () => setCleared(true) },
                            }} 
                            minDate={dayjs(dateFromValue).add(1, 'day')} 
                            disabled={dateFromValue===null}
                        />
                    </LocalizationProvider>
                </div>
            </div>



            {isLoading || isLoadingDataCount || isLoadingLocations  ? (
                <CircularProgress color="inherit" />
            ) : !isError ? (
            <div style={{ height: 500, width: "100%", paddingTop:"1rem" }}>
                <DataGrid
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50 , 100]}
                    onPageSizeChange={onPageSizeChange}
                    columnVisibilityModel={{id: false}}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                    toolbar: {
                        showQuickFilter: true, 
                        quickFilterProps: { debounceMs: 500 },
                    },
                    }}
                    rows={data?.data}
                    columns={[ 
                        ...dataColumns.filter((item)=> item.hidden!==true),
                    ]}
                    rowCount={dataCount?.data}
                    onPageChange={(newPage) => { setPageFunction(newPage);} }
                    paginationMode="server"
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    sx={{ height: 'calc(55vh)', "& .MuiButton-sizeSmall ": { display: "none" }, "& .MuiTypography-root": { fontFamily: 'sans-serif', fontSize: '1rem' } }}


                />
            </div>
            ) : (
            <h4>An error ocurred, please try again later...</h4>
            )}
            {openNewDlg ?
                <AssignedCardDetail
                    action="SAVE"
                    open={openNewDlg}
                    setOpen={setOpenNewDlg}
                    dialogTitle="Assigned Card Detail"
                    dialogContentText="To edit the information, click the pencil."
                    idBundleDetail={cardIdentifier}
                /> :""

            }

        </Fragment>

    );
}