import { Alert, Box, Button, Grid, Snackbar, TextField } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import { useFormik } from "formik";
import * as yup from "yup";

export default function AddCompanyGroup(props) {
  const createCompanyGroupMutation = useMutation(
    (values) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/companyGroups/create`,
        values
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("companyGroups");
        props.handleClose();
      },
      onError: (error) => {
        setFeedback({
          open: true,
          type: "error",
          message: "Error creating the attributes, constraint violated.",
        });
      },
    }
  );

  const validationSchema = yup.object({
    name: yup.string("Enter the group name").required("Value is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createCompanyGroupMutation.mutate({ ...values });
    },
  });

  const queryClient = useQueryClient();


  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  return (
    <div>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="name"
              label="Group name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} pt={3}>
            <Button fullWidth variant="contained" type="submit" disabled={createCompanyGroupMutation.isLoading}>
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
