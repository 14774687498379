import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Snackbar,
    Typography,
  } from "@mui/material";
  import { DataGrid, GridToolbar } from "@mui/x-data-grid";
  import { useMutation, useQuery } from "@tanstack/react-query";
  import HttpService from "../services/HttpService";
  import CloseIcon from "@mui/icons-material/Close";
  import SaveIcon from "@mui/icons-material/Save";
  import PeopleIcon from '@mui/icons-material/People';
  import DeleteIcon from '@mui/icons-material/Delete';
  import AddCompanyGroup from "../components/AddCompanyGroup";
  import ManageGroupMembers from "../components/ManageGroupMembers";
  import { useState } from "react";
  
  async function fetchGroups() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/companyGroups/list`
    );
    return response;
  }
  
  export default function ManageGroups() {
    const [previousValues, setPreviousValues] = useState({});
    const [feedback, setFeedback] = useState({
      open: false,
      type: "error",
      message: "",
    });
  
    const updateCompanyGroupMutation = useMutation(
      (values) => {
        return HttpService.getAxiosClient().put(
          window.API_URL + `/companyGroups/${values.id}`,
          {name : values.row.name}
        );
      },
      {
        onSuccess: (data, value) => {
          setPreviousValues((prevPreviousValues) => {
            const newPreviousValues = { ...prevPreviousValues };
            delete newPreviousValues[value.id];
            return newPreviousValues;
          });
        },
        onError: (error) => {
          setFeedback({
            open: true,
            type: "error",
            message: "Error updating the company group",
          });
        },
      }
    );
  
    const deleteCompanyGroupMutation = useMutation(
      (values) => {
        return HttpService.getAxiosClient().delete(
          window.API_URL + `/companyGroups/${values.id}`
        );
      },
      {
        onSuccess: (dataResponse, value) => {
          data.data = data.data.filter((row) => row.id !== value.id);
        },
        onError: (error) => {
          setFeedback({
            open: true,
            type: "error",
            message: "Error deleting the attribute",
          });
        },
      }
    );
  
    const handleEditCellChange = (id, field, oldValue, newValue) => {
      if (newValue !== undefined && oldValue !== newValue) {
        setPreviousValues((prevPreviousValues) => ({
          ...prevPreviousValues,
          [id]: { ...prevPreviousValues[id], [field]: oldValue },
        }));
      }
    };
  
    const clearEditedRow = (params) => {
      params.row.name = previousValues[params.id].name;
      setPreviousValues((prevPreviousValues) => {
        const newPreviousValues = { ...prevPreviousValues };
        delete newPreviousValues[params.id];
        return newPreviousValues;
      });
    };
  
    const columns = [
      { field: "id", headerName: "id", width: 10, hide: true },
      { field: "name", headerName: "Group Name", width: 400, hide: false ,  editable: true },
      { field: "membersCount", headerName: "Members", width: 150 },
      {
        field: "action",
        headerName: "Action",
        width: 150,
        renderCell: (params) => (
            <div key={"action." + params.id}>
              <IconButton
                disabled={!(params.id in previousValues)}
                key={"save." + params.id}
                onClick={() => {
                  updateCompanyGroupMutation.mutate({ ...params });
                }}
              >
                <SaveIcon />
              </IconButton>
              {params.id in previousValues ? (
                <IconButton
                  key={"clear." + params.id}
                  onClick={() => {
                    clearEditedRow(params);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <>
                <IconButton
                  disabled={params.id in previousValues}
                  key={"members." + params.id}
                  onClick={() => {
                    setMembersDlgInfo({ ...params })
                }}
                >
                  <PeopleIcon />
                </IconButton>
                <IconButton
                  disabled={params.id in previousValues}
                  key={"delete." + params.id}
                  onClick={() => {
                    deleteCompanyGroupMutation.mutate({ ...params })
                }}
                >
                  <DeleteIcon />
                </IconButton>
                
                </>
              )}
            </div>
          )
      },
    ];
  
    const { data, isLoading } = useQuery(
      ["companyGroups"],
      fetchGroups
    );
    const [addGroupDlg, setAddGroupDlg] = useState(false);
    const [membersDlgInfo, setMembersDlgInfo] = useState();

    const addMembersDlgFunct = (event) => {
        return (
          <Dialog   maxWidth="md" open={membersDlgInfo!==undefined} fullWidth onClose={() => {}}>
            <DialogActions
              style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
            >
              <div style={{ width: "100%" }}>
                <DialogTitle>
                  <Typography>Manage Group Members</Typography>
                </DialogTitle>
              </div>
              <IconButton
                onClick={() => {
                    setMembersDlgInfo();
                }}
              >
                <CloseIcon variant="Outlined" />
              </IconButton>
            </DialogActions>
            <DialogContent>
              <ManageGroupMembers handleClose={() => setAddGroupDlg(false)} groupInfo={membersDlgInfo?.row} />
            </DialogContent>
          </Dialog>
        );
      };
  
    const addGroupDlgFunct = (event) => {
      return (
        <Dialog open={addGroupDlg} fullWidth onClose={() => {}}>
          <DialogActions
            style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
          >
            <div style={{ width: "100%" }}>
              <DialogTitle>
                <Typography>Add Company Group</Typography>
              </DialogTitle>
            </div>
            <IconButton
              onClick={() => {
                setAddGroupDlg(false);
              }}
            >
              <CloseIcon variant="Outlined" />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <AddCompanyGroup handleClose={() => setAddGroupDlg(false)} />
          </DialogContent>
        </Dialog>
      );
    };
  
    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        {isLoading ? (
          ""
        ) : (
          <Grid container p={0} m={0} spacing={0}>
            <Grid item xs={12} sx={{ margin: "0px 0px 10px 0px" }}>
              <Box display="flex">
                <Typography variant="h5">Company groups</Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setAddGroupDlg(true)}
                    sx={{ margin: "0px 10px 0px 10px" }}
                >
                  Add Group
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%", height: "80vh" }}>
              <DataGrid
                key={"DataGrid.ManageGroup."+data.data.id}
                rows={data.data}
                columns={columns}
                onCellEditStop={(params, event) =>
                  handleEditCellChange(
                    params.id,
                    params.field,
                    params.value,
                    event?.target?.value
                  )
                }
                disableDensitySelector
              />
            </Grid>
          </Grid>
        )}
        {addGroupDlg ? addGroupDlgFunct() : ""}
        {membersDlgInfo!==undefined ? addMembersDlgFunct() : ""}
        <Snackbar
          open={feedback.open}
          autoHideDuration={6000}
          onClose={() => setFeedback({ ...feedback, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setFeedback({ ...feedback, open: false })}
            severity={feedback.type}
            sx={{ width: "100%" }}
          >
            {feedback.message}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
  