import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";

export default function ModifyCompanyMember(props) {
  const queryClient = useQueryClient();

  async function fetchCompanyData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer/all`
    );
    return response;
  }

  const { data, isLoading } = useQuery(["employers"], () => fetchCompanyData());

  const [newCompany, setNewCompany] = React.useState("");
  const [newMember, setNewMember] = React.useState("");

  const handleChange = (event) => {
    setNewCompany(event.target.value);
  };

  const executeMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/member/fixCompanyMember`,
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["balances"]);
        props.handleClose();
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const changeCompanyMember = (event) => {
    if (newCompany === "" || newMember === "") {
      return;
    }
    const data = {
      customerId: props.memberInfo.idCustomer,
      newCompanyId: newCompany,
      newMemberId: newMember,
    };
    executeMutation.mutate(data);
  };

  useEffect(() => {
    setNewCompany(props.memberInfo.companyId);
  }, []);

  return (
    <div>
      <Box component="form" noValidate autoComplete="off">
        {isLoading ? (
          ""
        ) : (
          <Grid container>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                label="Current Company ID"
                value={props.memberInfo.companyName}
                disabled
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <TextField
                fullWidth
                label="Current Member ID"
                value={props.memberInfo.memberId}
                disabled
              />
            </Grid>
            <Grid item xs={12} p={1}>
              <FormControl fullWidth>
                <InputLabel id="simple-select-label">New Company:</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={newCompany}
                  label="New Company:"
                  onChange={handleChange}
                >
                  {data.data.sort((a, b) => a.name.localeCompare(b.name)).map((menuItem) => (
                    <MenuItem
                      key={menuItem.idEmployer}
                      value={menuItem.idEmployer}
                      name={menuItem.name}
                    >
                      {menuItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} p={1}>
                <TextField
                  fullWidth
                  label="New Member ID"
                  value={newMember}
                  onChange={(e) => setNewMember(e.target.value)}
                  error={newMember === ""}
                  helperText={newMember === "" ? "This field is required" : ""}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} pt={3} pr={1}>
              <Button fullWidth variant="contained" onClick={changeCompanyMember} disabled={executeMutation.isLoading}>
                SAVE
              </Button>
            </Grid>
            <Grid item xs={6} pt={3} pl={1}>
              <Button fullWidth variant="contained" color="secondary" onClick={props.handleClose}>
                CLOSE
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
}
