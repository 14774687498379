import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import MissingTimeAttendance from "../components/MissingTimeAttendance";
import { Box } from "@mui/material";

export default function QueryMissingTimeAttendance() {


  async function fetchMissingTimeAttendance() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/hours/missing`
    );
    return response;
  }



  const { data, isLoading, isError } = useQuery(["missingTimeAttendance"], fetchMissingTimeAttendance);

  return (
    <Box sx={{margin: '0', padding: '0 150px'}}>
      <br />
      <MissingTimeAttendance isLoading={isLoading} isError={isError} data={data}  />
    </Box>
  );
}
