import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import DomainIcon from '@mui/icons-material/Domain';
import PaidIcon from '@mui/icons-material/Paid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ButtonWithIcon from './Button';

export default function BalanceElement(props) {
    const {selected, idElement, showStatsAction, showMovementsAction, isLoadingStats, isErrorStats, data, text, type, md=3, fontSize=40, minHeight, minWidth=275, headerTypograhy='h6'} = props;
    return (

        <Grid item xs={12} sm={6} md={md} p={0.5}>
          <Card sx={{ backgroundColor  : selected ? "white" : "whitesmoke" , minWidth: {minWidth}, minHeight:{minHeight}, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <CardContent>

              <Typography variant={headerTypograhy} color="text.secondary" sx={{fontWeight: selected ? "bold" : "normal"}}>
                <Box component="span" display="flex" alignItems="center">

                 {text}
                 <ButtonWithIcon 
                    onClick={() => showStatsAction(idElement)}
                    color="primary"
                    tooltip="Click to view more info of the balance"
                    icon="stats"
                    //record={params.row}  
                  />

                  <ButtonWithIcon 
                    onClick={() => showMovementsAction(idElement)}
                    color="primary"
                    tooltip="Click to view the movements of the balance"
                    icon="details"
                    //record={params.row}  
                  />

                </Box>
              </Typography>
              <Typography sx={{   fontSize: (type==="amount") ? {fontSize} : 60, mb: 0, textAlign: (type==="amount") ? 'right' : 'left'} } color="text.secondary">
                <Box component="span" display="flex" alignItems="center" justifyContent="flex-end">

                    {isLoadingStats
                      ? "..."
                      : !isErrorStats
                      ? ((type==="amount") ? '$ '+ Number(data).toLocaleString("en-US", { minimumFractionDigits: 2}) : data)
                      : "?"}
                </Box>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

    );
}