import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState, forwardRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import MuiAlert from "@mui/material/Alert";
import { hasValue } from "../util/string";



export default function CancelRequestCardsDialog(props) {

  const {infoToDiscard} = props;


  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
    return;
  }
  setFeedback({ open: false, type: "error", message: "" });
  };



  const validationSchema = yup.object({
    remark: yup
      .string("Enter the reason why you are discarding this request")
      .required("A description is required")
      .max(249,"Max length is 249"),
  });

  const handleSuccess = () => {
    setFeedback({
      open: true,
      type: "success",
      message: "The request was rejected",
      onClose: () => {  
        queryClient.invalidateQueries({ queryKey: ['bundles'] }); 
        setFeedback({open: false});   
        handleClose();      
      },
    });
  }


  const queryClient = useQueryClient()

  const updateBundleMutation = useMutation(
    (updatedBundle) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/paycards/bundle`,
        updatedBundle
      );
    },
    {
      onSuccess: () => {
        handleSuccess();

        //handleClose();
        
      },
      onError: (err) => {
        const msg = err?.response?.data
          ? err.response.data
          : err.message;
           setFeedback({
           open: true,
           type: "error",
           message: msg,
         });
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      remark: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateBundleMutation.mutate({ ...values, process: "CLOSE", id: infoToDiscard.id });
    },
  });

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
  };

  useEffect(() => {
    if (props.open === true) {
      formik.resetForm();
    }
  }, [props.open]);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        disableEscapeKeyDown={true}
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <DialogTitle>{props.dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{props.dialogContentText}</DialogContentText>
            <DialogContentText><Typography variant="subtitle2">{props.infoToDiscard.idEmployer}</Typography> </DialogContentText>
            <DialogContentText><Typography variant="subtitle2">{props.infoToDiscard.description}</Typography></DialogContentText>
            <DialogContentText><Typography variant="subtitle2">{props.infoToDiscard.idLocation}</Typography></DialogContentText>
            <DialogContentText><Typography variant="subtitle2">{props.infoToDiscard.quantity}</Typography></DialogContentText>
            <DialogContentText><Typography variant="subtitle2">{props.infoToDiscard.updateDate}</Typography></DialogContentText>


            <br />
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  id="remark"
                  label="Reason"
                  value={formik.values.remark}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.remark &&
                    Boolean(formik.errors.remark)
                  }
                  helperText={
                    formik.touched.remark && formik.errors.remark
                  }
                />
              </Grid>


            </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color={"error"}>
              Cancel
            </Button>
            <Button disabled={!hasValue(formik.values.remark)} type="submit">{props.action}</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
        <Alert
            onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
            severity={feedback.type}
            sx={{ width: "100%" }}
        >
            {feedback.message}
        </Alert>
      </Snackbar>
    </Fragment>

  );
}
