import React, { Fragment } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import { format } from 'date-fns';

export default function CardTransactions(props) {
    const {} = props;


    const {
            page, 
            pageSize, 
            setPage, 
            setPageSize, 
            isLoading, 
            isLoadingCount,
            isError, 
            data,
            statusFilter, 
            setStatusFilter,
            dataCount
        } = props;

    const columns = [
        { field: "id", headerName: "ID", width: 70,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: "0.9rem", mb: 0 }}   gutterBottom>
                        {params.row.transactionKey}
                    </Typography>)
                } 
        }, 
        { field: "transactionDate", headerName: "Transaction Date", width: 150 ,
            renderCell: (params) => {
                const formattedDate = format(new Date(params.row.transactionDate), 'yyyy-MM-dd HH:mm:ss');
                return (<Typography sx={{   fontSize: "0.9rem", mb: 0 }}   gutterBottom>
                    {formattedDate}
                </Typography>)
            } 
        },         
        { field: "amount", headerName: "Amount", width: 120, 
            renderCell: (params) => {
                const formattedAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.row.amount);
                return (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", textAlign: "right", width: "100%" }}>
                    <Typography sx={{ fontSize: "1rem", mb: 0 , textAlign: 'right', width: '100%' }}   gutterBottom>
                        {formattedAmount}
                    </Typography>
                    <Typography sx={{ display: params.row.originalAmount===0 ? "none": "block", fontSize: "0.7rem", mb: 0 , textAlign: 'right', width: '100%', color:"gray" }}   gutterBottom>
                        {params.row.originalAmount} {params.row.originalCurrency}
                    </Typography>
                </div>

                )
            } 
        },  
        { field: "runningBalance", headerName: "Available Balance", width: 150, 
            renderCell: (params) => {
                const formattedAvailableBalance = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.row.availableBalance);
                const formattedLedgerBalance = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.row.ledgerBalance);

                return (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", textAlign: "right", width: "100%" }}>
                    <Typography sx={{ fontSize: "1rem", mb: 0 , textAlign: 'right', width: '100%' }}   gutterBottom>
                        {formattedAvailableBalance}
                    </Typography>
                    <Typography sx={{  fontSize: "0.7rem", mb: 0 , textAlign: 'right', width: '100%', color:"gray" }}   gutterBottom>
                        Ledger: {formattedLedgerBalance}
                    </Typography>
                </div>

                )
            } 
        },  
        { field: "category", headerName: "Category", width: 220,
            renderCell: (params) => {

                return (                    
                <div style={{ minWidth:"4rem",   display:"flex", flexDirection:"column", alignContent:"left", alignItems:"left", textAlign:"left" }}>
                    <Typography sx={{ fontSize: "0.9rem", mb: 0 }} gutterBottom>
                        {params.row.category}
                    </Typography>
                    <Typography sx={{ fontSize: "0.7rem", mb: 0, fontWeight:"bold", color:"gray" }} gutterBottom>
                        {params.row.type}
                    </Typography>
                </div>)
            } 
        },  
        { field: "description", headerName: "Description", width: 320,
            renderCell: (params) => {

                return (
                
                    <div style={{ minWidth:"4rem",   display:"flex", flexDirection:"column", alignContent:"left", alignItems:"flex-start", textAlign:"left" }}>
                        <Typography sx={{ fontSize: "0.9rem", mb: 0 }} gutterBottom>
                            {params.row.concept}
                        </Typography>
                        <Typography sx={{ fontSize: "0.7rem", mb: 0, fontWeight:"bold", color:"gray" }} gutterBottom>
                            {params.row.merchantName}
                        </Typography>
                    </div>
                
                
            )



            } 
        },  
        { field: "observation", headerName: "Observation", width: 320, 
            renderCell: (params) => {

                return (<Typography sx={{   fontSize: "0.9rem", mb: 0 }}   gutterBottom>
                    {params.row.additionalInfo}
                </Typography>)
            } 
        }, 
      ];
    return (

        <Fragment>
            {isLoading || isLoadingCount ? (
            <h4>Loading...</h4>
            ) : !isError ? (
            <div style={{ height: 550 
            , width: "100%" }}>
                <DataGrid
                    columnVisibilityModel={{id: false}}
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rows={data?.data?.transactions}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    onPageSizeChange={setPageSize}
                    onPageChange={setPage}
                    components={{ Toolbar: GridToolbar }}
                    page={page}
                    componentsProps={{
                    toolbar: {
                        showQuickFilter: false, 
                        quickFilterProps: { debounceMs: 500 },
                    },
                    }}
                    rowCount={dataCount?.data}
                    onPageChange={(newPage) => { setPage(newPage);} }
                    paginationMode="server"
                    filterMode="server"
                    keepNonExistentRowsSelected
                    sx={{
                      "& .MuiButton-root": {
                        display: "none",
                      }
                    }}
                />
            </div>
            ) : (
            <h4>An error ocurred, please try again later...</h4>
            )}


        </Fragment>

    );
}