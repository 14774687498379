import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import {useState} from  'react';
import Members from "../components/Members";
import { Box } from "@mui/material";
import CompanyMembers from "../components/CompanyMembers";
import UserService from "../services/UserService";


export default function QueryCompanyMembers() {

  const [page, setPage] = useState(0);
  const [filterString, setFilterString] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [locationIds, setLocationIds] = useState({});


  async function fetchMembers(pageSize, page, filterString) {
    const locations = Array.from(locationIds).length > 0 ? Array.from(locationIds).join(",") : null;
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/member/all?pageSize=`+pageSize+`&page=`+page +`&search=`+filterString+(locations!==null ? `&locations=`+locations: "")
    );
    return response;
  }

  async function fetchMembersCount(filterString) {
    const locations = Array.from(locationIds).length > 0 ? Array.from(locationIds).join(",") : null;

    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/member/count?filter=`+filterString+(locations!==null ? `&locations=`+locations: "")
    );
    return response;
  }



  async function fetchLocations() {
    if (UserService.hasRole(['pc-query-locations'])){
      const response = await HttpService.getAxiosClient().get(
        window.API_URL + `/locations`
      );
      return response;
    }
    return [];
  }


  const {     
    data: dataLocations,
    isLoading: isLoadingLocations,
    isError: isErrorLocations, 
  } = useQuery(["locations"], fetchLocations,{refetchOnWindowFocus:false, keepPreviousData: true});

  const { 
    data, 
    isFetching, 
    isLoading, 
    isError, 
    refetch 
  } 
  = useQuery(
    ["members",pageSize, page, filterString,locationIds],   () => fetchMembers(pageSize, page, filterString, locationIds), {refetchOnWindowFocus:false, keepPreviousData: true } 
  );


  const { 
    data : dataCount, 
    isFetching : isFetchingDataCount, 
    isLoading : isLoadingDataCount, 
    isError: isErrorDataCount, 
    refetch: refetchDataCount 
  } = useQuery( ["membersCount", filterString, locationIds],  () => fetchMembersCount(filterString, locationIds), {refetchOnWindowFocus:false, keepPreviousData: true } 
  );

  return (
    <Box sx={{margin: '0', padding: '0 150px'}}>
      <br />
      <CompanyMembers 
        isFetching={isFetching} 
        isLoading={isLoading} 
        isError={isError} 
        data={data} 
        refetchFunction={refetch} 
        setPageFunction={setPage}
        setFilterStringFunction={setFilterString}
        dataCount={dataCount} 
        isFetchingDataCount={isFetchingDataCount}
        isLoadingDataCount={isLoadingDataCount}
        isErrorDataCount={isErrorDataCount}
        refetchDataCount={refetchDataCount} 
        title="Members/Workers"
        pageSize={pageSize}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        dataLocations={dataLocations} 
        isLoadingLocations={isLoadingLocations} 
        setLocationIdsFunction={setLocationIds}
      />
    </Box>
  );
}
