import { useState, forwardRef } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import HttpService from "../services/HttpService";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { CircularProgress, Container, Grid, Button, Snackbar, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from "@mui/material";
import cardImg from "./../images/paycard.lowres.svg";

import RequestCardsDialog from "../components/RequestCardsDialog";
import CancelRequestCardsDialog from "../components/CancelRequestCardsDialog";
import RenderOnRole from "../components/RenderOnRole";
import RenderIfNotOnRole from "../components/RenderIfNotOnRole";
import UserService from '../services/UserService';
import MuiAlert from "@mui/material/Alert";
import RefreshingIndicator from "../components/RefreshingIndicator"
import ButtonWithIcon from "../components/Button";
import AddCardIcon from '@mui/icons-material/AddCard'
import CloseIcon from "@mui/icons-material/Close";
import ConfirmOrderNumberDialog from "../components/ConfirmOrderNumberDialog";
import BundleDetail from "./BundlesDetail";


export default function Bundles() {

  const APPROVE_BUNDLE_ROLE =  'pc-approve-bundle';

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
    return;
  }
  setFeedback({ open: false, type: "error", message: "" });
  };

  const queryClient = useQueryClient()

  const updateBundleMutation = useMutation(
    (updatedBundle) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/paycards/bundle`,
        updatedBundle
      );
    },
    {
      onSuccess: () => {
        setFeedback({
          open: true,
          type: "success",
          message: "The request was verified succesfully",
          onClose: () => {  queryClient.invalidateQueries({ queryKey: ['bundles'] }); setFeedback({open: false}); },
        });
        
      },
      onError: (err) => {
        const msg = err?.response?.data
          ? err.response.data
          : err.message;
           setFeedback({
           open: true,
           type: "error",
           message: msg,
         });
      },
    }
  );

  const deleteBundleMutation = useMutation(
    (deleteBundleId) => {
      return HttpService.getAxiosClient().delete(
        window.API_URL + `/paycards/bundle/`+deleteBundleId,
      );
    },
    {
      onSuccess: () => {
        setFeedback({
          open: true,
          type: "success",
          message: "The order was deleted succesfully",
          onClose: () => {  queryClient.invalidateQueries({ queryKey: ['bundles'] }); setFeedback({open: false}); },
        });
        
      },
      onError: (err) => {
        const msg = err?.response?.data
          ? err.response.data
          : err.message;
           setFeedback({
           open: true,
           type: "error",
           message: msg,
         });
      },
    }
  );


  const downloadBundleMutation = useMutation(
    (params) => {
      return HttpService.getAxiosClient().get(
        window.API_URL + `/paycards/bundle/export/`+params.id,
        {responseType: 'blob'}
      );
    },
    {
      onSuccess: (response) => {
        const { headers, data } = response;
        const disposition = headers['content-disposition'];
        let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
        if (filename.toLowerCase().startsWith("utf-8''"))
           filename = decodeURIComponent(filename.replace("utf-8''", ''));
        else
           filename = filename.replace(/['"]/g, '');
        let url = window.URL.createObjectURL(data);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // append the element to the dom
        a.click();
        a.remove(); // afterwards, remove the element 
        setFeedback({
          open: true,
          type: "success",
          message: "The file was download succesfully",
          onClose: () => {  setFeedback({open: false}); },
        });
        
      },
      onError: (err) => {
        if (err?.response?.status===500) {
          setFeedback({
            open: true,
            type: "error",
            message: "There was an error trying to download the file",
          });
        }
        else {
          const msg = err?.response?.data
          ? err.response.data
          : err.message;
           setFeedback({
           open: true,
           type: "error",
           message: msg,
         });
        }

      },
    }
  );

  const onViewButtonClick = (e, params) => {
    setChosenBundle(params);
  }

  const columns = [
    { field: "id", headerName: "ID", width: 1, hide: true },
    { field: "idEmployer", headerName: "Employer", width: 80, hide: !UserService.hasRole([APPROVE_BUNDLE_ROLE]) },
    { field: "description", headerName: "Description", width: 350, 
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                {params.row.description}
            </Typography>)
        }
    },
    { field: "idLocation", 
      headerName: "Location", 
      width: 130 ,
      hide: UserService.hasRole([APPROVE_BUNDLE_ROLE]),
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                {params.row.idLocation}
            </Typography>)
        }
    },
    { field: "quantity", 
      headerName: "Count", 
      headerAlign: "right",
      width: 100, 
      align: "right"
    },
    { field: "totalAmount", 
      headerName: "Load Amount", 
      headerAlign: "right",
      width: 150,  
      hide: !UserService.hasRole([APPROVE_BUNDLE_ROLE]),
      align: "right",
      valueFormatter: (params) => {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(params.value);
      },
    },
    { field: "emboss", headerName: "Emboss Line 1", width: 140 , 
    hide: UserService.hasRole([APPROVE_BUNDLE_ROLE]),
        renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
            {`${params.row.embossPart1 || ''} ${params.row.embossPart2 || ''}`}
          </Typography>)
          }
    },
    { field: "embossPart3", headerName: "Emboss Line 2", width: 140 ,
    hide: UserService.hasRole([APPROVE_BUNDLE_ROLE]),
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                {params.row.embossPart3}
            </Typography>)
        }
    },
    { field: "updateDate", headerName: "Update Date", width: 130, 
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                {params.row.updateDate}
            </Typography>)
        }
    },
    { field: "status", headerName: "Status", width: 130,
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0  , fontWeight: "600" }}   gutterBottom>
                {params.row.status}
            </Typography>)
        }
    },
    {
      field: "action",
      headerName: "Action",
      width: 240,
      hide: !UserService.hasRole([APPROVE_BUNDLE_ROLE]),
      renderCell: (params) => {
        return (
          // you will find row info in params
          <div>
              <ButtonWithIcon 
                onClick={onViewButtonClick}
                color="blue"
                tooltip="Click to view more info of the request"
                icon="view"
                record={params.row}  
              />
              <ButtonWithIcon 
                onClick={onVerifyButtonClick}
                hidden={params.row.status!=="REQUESTED"}
                color="green"
                tooltip="Click to verify and accept the request"
                icon="approve-card"
                record={params.row}  
              />
              <ButtonWithIcon 
                onClick={onCloseButtonClick}
                hidden={params.row.status!=="REQUESTED"}
                color="red"
                tooltip="Click to cancel and close the request"
                icon="deny"
                record={params.row}
              />
              <ButtonWithIcon 
                onClick={onDeleteButtonClick}
                hidden={params.row.status!=="VALIDATION_ERROR"}
                color="red"
                tooltip="Click to delete the request"
                icon="delete"
                record={params.row}
              />
              <RenderOnRole roles={['pc-program-super-admin']}>
                <ButtonWithIcon 
                  onClick={onDownloadButtonClick}
                  hidden={params.row.status!=="PENDING_BULK"}
                  color="blue"
                  tooltip="Click to download the CSV file from the order"
                  icon="download"
                  record={params.row}
                />
                <ButtonWithIcon 
                  onClick={onConfirmButtonClick}
                  hidden={params.row.status!=="PENDING_BULK"}
                  color="green"
                  tooltip="Click to enter the confirmation number"
                  icon="confirm"
                  record={params.row}
                />
              </RenderOnRole>
          </div>
        );
      },
    },
    { field: "remark", headerName: "Notes", width: 180 , 
    renderCell: (params) => {
    return (<Tooltip title={params.row.remark}><span>{params.row.remark}</span></Tooltip>)}
    },
  ];

  const onAddButtonClick = (e, params) => {
    setOpenNewDlg(true);
  };

  const onDownloadButtonClick = (e, params) => {    
    downloadBundleMutation.mutate(params);
  };

  const onConfirmButtonClick = (e, params) => {
    setInfoToConfirm(params);
    setOpenConfirmDlg(true);
  };
  

  const onVerifyButtonClick = (e, params) => {
    const updateRequest = {
      id: params.id,
      process: "VERIFY",
    }
    updateBundleMutation.mutate(updateRequest);
  };

  const onDeleteButtonClick = (e, params) => {
    deleteBundleMutation.mutate(params.id);
  };

  
  const onCloseButtonClick = (e, params) => {
    setInfoToDiscard(params);
    setOpenDiscardDlg(true);
  };


  async function fetchBundles() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/bundle`
    );
    return response;
  }

  const { data, isLoading, isFetching, isError } = useQuery(["bundles"], fetchBundles);

  const [openNewDlg, setOpenNewDlg] = useState(false);
  
  const [chosenBundle, setChosenBundle] = useState();

  const [openDiscardDlg, setOpenDiscardDlg] = useState(false);

  const [infoToDiscard, setInfoToDiscard] = useState({});

  const [infoToConfirm, setInfoToConfirm] = useState({});

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);

  return (
    <Container maxWidth="xlg">
      {chosenBundle ? (
        <Dialog open={chosenBundle!==undefined} onClose={() => setChosenBundle()} maxWidth="xl" fullWidth>
           <DialogTitle>
            View Card Order Request
            <IconButton style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={() => setChosenBundle()}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <BundleDetail bundle={chosenBundle}/>
          </DialogContent>
        </Dialog>
      ) : ""}    
      <Grid container spacing={1.5}>
        <RenderIfNotOnRole roles={[APPROVE_BUNDLE_ROLE]}>
          <Grid item xs={12} md={4}>
            <br />
            <br />
            <img src={cardImg} alt="cards" width="90%" loading="lazy" />
          </Grid>
        </RenderIfNotOnRole>
        <Grid item xs={12} md={UserService.hasRole([APPROVE_BUNDLE_ROLE]) ? 12 : 8}>
          <div style={{display : "flex"}}>
                <div style={{marginRight:"20px"}}><h1>Cards Requested</h1></div> 
                {isFetching? (<RefreshingIndicator/>) :""}
            </div>
          {isLoading || updateBundleMutation.isLoading ? (
            <CircularProgress color="inherit" />
          ) : !isError ? (
            <div style={{ height: 550, width: "100%" }}>
              <RenderOnRole  roles={['pc-create-bundle']}>
                <Button
                  style={{ margin: "10px" }}
                  onClick={(e) => onAddButtonClick(e)}
                  variant="contained"
                  
                >
                  <AddCardIcon style={{ marginRight: "10px" }} ></AddCardIcon>
                  Request Cards
                </Button>
                </RenderOnRole>
              
              <DataGrid
                rows={data?.data}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10,25,50,100]}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                toolbar: {
                    showQuickFilter: true, 
                    quickFilterProps: { debounceMs: 500 },
                },
                }}
                /*sx={{
                      "& .MuiButton-root": {
                        display: "none",
                      }
                    }}*/
              />
              <Snackbar
                    open={feedback.open}
                    autoHideDuration={6000}
                    onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                    <Alert
                        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                        severity={feedback.type}
                        sx={{ width: "100%" }}
                    >
                        {feedback.message}
                    </Alert>
                </Snackbar>
            </div>
          ) : (
            <h4>An error ocurred, please try again later...</h4>
          )}
        </Grid>
      </Grid>
      {openNewDlg ? (
        <RequestCardsDialog
          action="Request"
          open={openNewDlg}
          setOpen={setOpenNewDlg}
          dialogTitle="Request Cards"
          dialogContentText="To request cards, input the information and click request button."
        />
      ) : ""}    
      {openDiscardDlg ? (
        <CancelRequestCardsDialog
          action="DISCARD"
          open={openDiscardDlg}
          setOpen={setOpenDiscardDlg}
          dialogTitle="Discard Request"
          dialogContentText="To discard this request, type the reason and click DISCARD button."
          infoToDiscard={infoToDiscard}
        />
      ) : ""}    
      {openConfirmDlg ? (
        <ConfirmOrderNumberDialog
          action="CONFIRM"
          open={openConfirmDlg}
          setOpen={setOpenConfirmDlg}
          dialogTitle="Confirm Order"
          dialogContentText="To confirm the order, type order confirmation number and click CONFIRM button."
          infoToConfirm={infoToConfirm}
        />
      ) : ""}  



    </Container>
  );
}
