import byDefault from "./default";

let labels;
let myModule;

async function setLabels() {
  let returnValue;
  try {
    if (!myModule) {
      myModule = await import(`./${window.subdomain}`);
    }
    returnValue = myModule.default;
    return returnValue;
  } catch (error) {
    returnValue = byDefault;
  }
  return returnValue;
}


const getLabel = (key, defaultValue = "") => {
  let returnValue;
  if (labels === null || labels === undefined)
    setLabels().then((data) => {
    labels = data;
    returnValue = getLabel(key, defaultValue);
    });
  else {
    const value = labels['en'][key];
    returnValue = value !== undefined ? value : defaultValue;
  }
  return returnValue !== "" ? returnValue : key;
};

const Labels = {
  getLabel
};

export default Labels;