import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid } from "@mui/material";
import AvailableCards from "../components/AvailableCards";
import CardStatsElement from "../components/CardStatsElement";
import UserService from "../services/UserService";
import {useEffect, useState} from  'react';

export default function QueryAssignCard() {
  const [page, setPage] = useState(0);
  const [filterString, setFilterString] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [locationIds,setLocationIds] = useState({});

  async function fetchPaycards(pageSize, page, filterString, locationIds) {
    const locations = Array.from(locationIds).length > 0 ? Array.from(locationIds).join(",") : null;
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/available?pageSize=`+pageSize+`&page=`+page +`&filter=`+filterString+(locations!==null ? `&locations=`+locations: "")
    );
    return response;
  }

  async function fetchPaycardsCount(filterString, locationIds) {
    const locations = Array.from(locationIds).length > 0 ? Array.from(locationIds).join(",") : null;
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/available/count?filter=`+filterString+(locations!==null ? `&locations=`+locations: "")
    );
    return response;
  }

  async function fetchPaycardsStats() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/stats`
    );
    return response;
  }

  async function fetchLocations() {
    if (UserService.hasRole(['pc-query-locations'])){
      const response = await HttpService.getAxiosClient().get(
        window.API_URL + `/locations`
      );
      return response;
    }
    return [];
  }

  async function fetchPaycardAsReplacementAttribute() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer/attributes/PAYCARD_AS_REPLACEMENT`
    );
    return response;
  }

  const {
    data: dataStats,
    isLoading: isLoadingStats,
    isError: isErrorStats,
    refetch: refetchStats,
  } = useQuery(["paycardsStats"], fetchPaycardsStats);

  /*useEffect(() => {
    refetch();
  }, [locationIds]);
*/

  const { 
    data, 
    isFetching, 
    isLoading, 
    isError, 
    refetch 
  } = useQuery(["paycardsAvailable", pageSize, page, filterString, locationIds], () => fetchPaycards(pageSize, page, filterString, locationIds), {
    keepPreviousData: true
  });

  const { 
    data : dataCount, 
    isFetching : isFetchingDataCount, 
    isLoading : isLoadingDataCount, 
    isError: isErrorDataCount, 
    refetch: refetchDataCount 
  } = useQuery(["paycardsAvailableCount", filterString, locationIds], () => fetchPaycardsCount(filterString, locationIds), {
    keepPreviousData: true
  });


  const {     
    data: dataLocations,
    isLoading: isLoadingLocations,
    isError: isErrorLocations, 
  } = useQuery(["locations"], fetchLocations);

  const { 
    data : dataPayCardAsReplacement, 
    isFetching : isFetchingDataPayCardAsReplacement, 
    isLoading : isLoadingDataPayCardAsReplacement, 
    isError: isErrorDataPayCardAsReplacement, 
    //refetch: refetchDataPayCardAsReplacement 
  } = useQuery(["dataPayCardAsReplacement"], () => fetchPaycardAsReplacementAttribute(), {
    keepPreviousData: true
  });

  return (
    <Box sx={{margin: '0', padding: '0 150px'}}>
      <br />
      <Grid container spacing={2}>
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Cards available to assign" data={dataStats && dataStats.data.availableToAssign} />
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Total Cards assigned" data={dataStats && dataStats.data.assignedAll} />
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Cards assigned this week" data={dataStats && dataStats.data.assignedWeek} />
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Cards assigned today" data={dataStats && dataStats.data.assignedToday} />
      </Grid>
      <br />
      <br />
      <AvailableCards 
        isFetching={isFetching} 
        isLoading={isLoading} 
        isError={isError} 
        data={data} 
        dataStats={dataStats} 
        dataLocations={dataLocations} 
        isLoadingLocations={isLoadingLocations} 
        isErrorLocations={isErrorLocations} 
        refetchFunction={refetch} 
        refetchStatsFunction={refetchStats} 
        setPageFunction={setPage}
        setFilterStringFunction={setFilterString}
        setLocationIdsFunction={setLocationIds}
        dataCount={dataCount} 
        isFetchingDataCount={isFetchingDataCount}
        isLoadingDataCount={isLoadingDataCount}
        isErrorDataCount={isErrorDataCount}
        refetchDataCount={refetchDataCount} 
        dataPayCardAsReplacement={dataPayCardAsReplacement}
        isFetchingDataPayCardAsReplacement={isFetchingDataPayCardAsReplacement}
        isLoadingDataPayCardAsReplacement={isLoadingDataPayCardAsReplacement}
        isErrorDataPayCardAsReplacement={isErrorDataPayCardAsReplacement}
        pageSize={pageSize}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
      />
    </Box>
  );
}
