import { createTheme } from '@mui/material/styles';

const palette= {
  primary: {
    main: '#0d7f81',
  },
  secondary: {
    main: '#F7971C',
  },
};

const theme = createTheme({
  typography: {
    fontFamily: 'Raleway, sans-serif',
    fontSize: 16,
  },
  palette: palette,
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.60)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '18px',
          '&.MuiButton-containedPrimary': {
            color: 'white', 
            backgroundColor: palette.primary.main, 
            '&:hover': {
              color: palette.primary.main,
              backgroundColor: 'white',
            },
          },
          '&.MuiButton-containedSecondary': {
            color: 'white',
            backgroundColor: palette.secondary.main,
            '&:hover': {
              color: palette.secondary.main,
              backgroundColor: 'white',
            },
          },
        },
      },
    },
  },
});

export default theme;
