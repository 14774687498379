import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import { useFormik } from "formik";
import * as yup from "yup";

async function fetchMembersData(inputValue) {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/member/all?search=${inputValue}`
  );
  return response;
}

export default function AddMemberToGroup(props) {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { data, isLoading } = useQuery(["members", inputValue], () =>
    fetchMembersData(inputValue)
  );

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const debouncedSetInputValue = useMemo(() => {
    let timerId;
    return (value) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setInputValue(value);
      }, 400);
    };
  }, []);

  const SaveButtonOnClick = (event) => {
    event.preventDefault();
    if (selectedMembers.length > 0) {
      selectedMembers.forEach((member) => {
        HttpService.getAxiosClient()
          .post(window.API_URL + `/companyGroups/${props?.groupInfo?.id}/addMember`, {
            memberId: member.memberId,
            companyId: member.companyId,
          })
          .then((response) => {
            setFeedback({
              open: true,
              type: "success",
              message: "Member added to group",
            });
            setSelectedMembers([]);
          })
          .catch((error) => {
            setFeedback({
              open: true,
              type: "error",
              message: "Error adding member to group",
            });
          });
      });
    } else {
      setFeedback({
        open: true,
        type: "error",
        message: "Please select at least one member",
      });
    }
  };


  return (
    <div>
      <Box component="form" noValidate autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              multiple
              filterOptions={(x) => x}
              isOptionEqualToValue={(option, value) =>
                option.memberId === value.memberId
              }
              id="companieList"
              options={!isLoading ? data.data : []}
              getOptionLabel={(option) =>
                `${option.memberId} - ${option.email} - ${option.name} `
              }
              filterSelectedOptions
              value={selectedMembers}
              onChange={(event, selectedOptions) => {
                setSelectedMembers(selectedOptions);
              }}
              onInputChange={(event, newInputValue) => {
                debouncedSetInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Member list"
                  placeholder="Members"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <div key={index} style={{ marginTop: 8 }}>
                    <Chip
                      label={`${option.memberId} - ${option.email} - ${option.name}`}
                      {...getTagProps({ index })}
                    />
                  </div>
                ))
              }
            />
          </Grid>
          <Grid item xs={12} pt={3}>
            <Button fullWidth variant="contained" type="submit" onClick={(event)=> SaveButtonOnClick(event) }>
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
