import {
  Box,
  Button,
  Grid,
  TextField,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HttpService from "../services/HttpService";
import dayjs from "dayjs";

export default function AddWhitelist(props) {
  const queryClient = useQueryClient();
  const [senderEmail, setSenderEmail] = React.useState("");
  const [selectedMembers, setSelectedMembers] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [emailValidation, setEmailValidation] = React.useState("");
  const [formValidation, setFormValidation] = React.useState("");

  const addWhitelistMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/whitelist`,
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["whitelist"]);
        props.handleClose();
      },
      onError: (err) => {
        setFormValidation("An error occurred. " + err.response.data);
      },
    }
  );

  async function fetchMembersData(inputValue) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/member/all?search=${inputValue}`
    );
    return response;
  }

  const { data, isLoading } = useQuery(["whitelist", inputValue], () =>
    fetchMembersData(inputValue)
  );

  const debouncedSetInputValue = React.useMemo(() => {
    let timerId;
    return (value) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setInputValue(value);
      }, 400);
    };
  }, []);

  const handleSaveClick = () => {
    if (emailValidation === "" && selectedMembers.length > 0) {
      const data = {
        senderEmail: senderEmail,
        recipientList: selectedMembers.map((member) => member.idCustomer),
      };
      addWhitelistMutation.mutate(data);
    } else {
      setFormValidation("An error occurred. Please check the form.");
    }
  };

  return (
    <div>
      <Box component="form" noValidate autoComplete="off">
        <Grid container p={1}>
          <Grid item xs={12} p={2}>
            <TextField
              fullWidth
              id="email"
              label="Sender email"
              variant="outlined"
              value={senderEmail}
              error={emailValidation !== ""}
              helperText={emailValidation}
              onChange={(e) => {
                setSenderEmail(e.target.value);
                if (e.target.value === "") {
                  setEmailValidation("Email is required");
                  return;
                } else {
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  !emailRegex.test(e.target.value)
                    ? setEmailValidation("Invalid email address")
                    : setEmailValidation("");
                }
              }}
            />
          </Grid>
          <Grid item xs={12} p={2}>
            <Autocomplete
              fullWidth
              multiple
              filterOptions={(x) => x}
              isOptionEqualToValue={(option, value) =>
                option.memberId === value.memberId
              }
              id="memberList"
              options={!isLoading ? data.data.filter(option => option.idCustomer !== null) : []}
              getOptionLabel={(option) =>
                `${option.memberId} - ${option.email} - ${option.name} `
              }
              filterSelectedOptions
              value={selectedMembers}
              onChange={(event, selectedOptions) => {
                setSelectedMembers(selectedOptions);
              }}
              onInputChange={(event, newInputValue) => {
                debouncedSetInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Recipient list"
                  placeholder="Members"
                  helperText={
                    selectedMembers.length === 0 ? "Recipient is required" : ""
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <div key={index} style={{ marginTop: 8 }}>
                    <Chip
                      label={`${option.memberId} - ${option.email} - ${option.name}`}
                      {...getTagProps({ index })}
                    />
                  </div>
                ))
              }
            />
          </Grid>
          <Grid item xs={12} p={2}>
            <Accordion sx={{ fontSize: "0.95rem" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Advanced options
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      disabled
                      fullWidth
                      label="Minimum amount per transaction"
                      variant="outlined"
                      defaultValue={"0"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled
                      fullWidth
                      label="Maximun amount per transaction"
                      variant="outlined"
                      defaultValue={"5000"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled
                        label="Allow transfer from:"
                        defaultValue={dayjs()}
                        slotProps={{
                          field: {
                            clearable: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disabled
                        label="Allow transfer to:"
                        defaultValue={dayjs().add(10, "year")}
                        slotProps={{
                          field: {
                            clearable: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} pt={2}>
            <Button fullWidth variant="contained" onClick={handleSaveClick}>
              SAVE
            </Button>
            <Box
              pt={2}
              sx={{
                display: formValidation === "" ? "none" : "display",
                textAlign: "center",
              }}
            >
              <Typography variant="caption" color="error">
                {formValidation}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
