import React from "react";
import { AppBar, Typography } from "@mui/material";

export default function Footer() {
  return (
  <AppBar position="fixed" color="primary" sx={{ top: "auto", bottom: 0, padding:1, margin:0  }}>
    <Typography variant="body1" align="center" sx={{ fontSize: "15px", }}>
      &copy; Copyright {new Date().getFullYear()} {" | "} Rellevate. Inc.
    </Typography>
</AppBar>

  );
}
