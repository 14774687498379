import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid } from "@mui/material";
import AssignedCards from "../components/AssignedCards";
import CardStatsElement from "../components/CardStatsElement";
import {useState} from  'react';
import UserService from "../services/UserService";

export default function QueryAssignCard() {


  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filterString, setFilterString] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [locationIds, setLocationIds] = useState({});



  async function fetchAssignedPaycards(pageSize, page, filterString, dateFrom, dateTo, locationIds) {
    const locations = Array.from(locationIds).length > 0 ? Array.from(locationIds).join(",") : null;

    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/assigned?pageSize=`+pageSize+`&page=`+page +`&filter=`+filterString+`&from=`+dateFrom+`&to=`+dateTo+(locations!==null ? `&locations=`+locations: "")
    );
    return response;
  }

  async function fetchAssignedPaycardsCount(filterString, dateFrom, dateTo, locationIds) {
    const locations = Array.from(locationIds).length > 0 ? Array.from(locationIds).join(",") : null;

    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/assigned/count?filter=`+filterString+`&from=`+dateFrom+`&to=`+dateTo+(locations!==null ? `&locations=`+locations: "")
    );
    return response;
  }

  async function fetchLocations() {
    if (UserService.hasRole(['pc-query-locations'])){
      const response = await HttpService.getAxiosClient().get(
        window.API_URL + `/locations`
      );
      return response;
    }
    return [];
  }

  async function fetchPaycardsStats() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/stats`
    );
    return response;
  }


  const {     
    data: dataLocations,
    isLoading: isLoadingLocations,
    isError: isErrorLocations, 
  } = useQuery(["locations"], fetchLocations);

  const { 
    data, 
    isFetching, 
    isLoading, 
    isError, 
    refetch 
  } = useQuery(["paycardsAssigned", pageSize, page, filterString, dateFrom, dateTo, locationIds], () => fetchAssignedPaycards(pageSize, page, filterString, dateFrom, dateTo, locationIds), {
    keepPreviousData: true
  });

  const { 
    data : dataCount, 
    isFetching : isFetchingDataCount, 
    isLoading : isLoadingDataCount, 
    isError: isErrorDataCount, 
    refetch: refetchDataCount 
  } = useQuery(["paycardsAssignedCount", filterString, dateFrom, dateTo, locationIds], () => fetchAssignedPaycardsCount(filterString, dateFrom, dateTo, locationIds), {
    keepPreviousData: true
  });

  const {
    data: dataStats,
    isLoading: isLoadingStats,
    isError: isErrorStats,
    refetch: refetchStats,
  } = useQuery(["paycardsStats"], fetchPaycardsStats);


  return (
    <Box sx={{margin: '0', padding: '0 150px'}}>
      <br />
      <Grid container spacing={2}>
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Cards available to assign" data={dataStats && dataStats.data.availableToAssign} />
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Total Cards assigned" data={dataStats && dataStats.data.assignedAll} />
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Cards assigned this week" data={dataStats && dataStats.data.assignedWeek} />
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Cards assigned today" data={dataStats && dataStats.data.assignedToday} />
      </Grid>
      <br />
      <br />
      <AssignedCards 
        isFetching={isFetching} 
        isLoading={isLoading} 
        isError={isError} 
        data={data}  
        dataStats={dataStats} 
        dataLocations={dataLocations} 
        isLoadingLocations={isLoadingLocations} 
        refetchFunction={refetch} 
        refetchStatsFunction={refetchStats} 
        setPageFunction={setPage}
        setFilterStringFunction={setFilterString}
        setLocationIdsFunction={setLocationIds}
        setDateFromFunction={setDateFrom}
        setDateToFunction={setDateTo}
        dataCount={dataCount} 
        isFetchingDataCount={isFetchingDataCount}
        isLoadingDataCount={isLoadingDataCount}
        isErrorDataCount={isErrorDataCount}
        refetchDataCount={refetchDataCount} 
        pageSize={pageSize}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
      />
    </Box>
  );
}
