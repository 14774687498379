import { useState, forwardRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { Container, Grid, Button, Snackbar, Typography } from "@mui/material";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
// import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import MuiAlert from "@mui/material/Alert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import OnboardingDetail from "../components/OnboardingDetail";

export default function Onboardings() {
  const columns = [
    { field: "id", headerName: "ID", width: 1, hide: true },

    { field: "firstName", headerName: "First Name", width: 100 ,
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                  {params.row.firstName}
                </Typography>)
      }  
    },
    { field: "lastName", headerName: "Last Name", width: 100 ,
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                  {params.row.lastName}
                </Typography>)
      }  
    },
    { field: "email", headerName: "Email", width: 180,
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                  {params.row.email}
                </Typography>)
      }  
    },
    { field: "dob", headerName: "DOB", width: 80,
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                  {params.row.dob}
                </Typography>)
      }  
    },
    { field: "address", headerName: "Address", width: 300 ,
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                  {params.row.address}
                </Typography>)
      }  
    },
    { field: "phone", headerName: "Phone", width: 100,
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                  {params.row.phone}
                </Typography>)
      }  
    },
    { field: "ssn", headerName: "SSN", width: 90,
      renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                  {params.row.ssn}
                </Typography>)
      }  
    },
    { field: "duration", headerName: "Duration", width: 80 },

     {
       field: "action",
       width: 180,
       headerName: "Action",
       renderCell: (params) => {
         return (
           // you will find row info in params
           <div style={{backgroundColor: params.row.status==="REVIEW_IN_PROGRESS" ? "antiquewhite" : "white"}}>
            { <Button style={{ minWidth: "10px" }}>
               <ModeEditIcon style={{color: "darkgoldenrod"}}
                 onClick={(e) => onEditButtonClick(e, params.row)}
                 variant="contained"
               />
             </Button>}
             <Button style={{ minWidth: "10px" }}>
               <DonutSmallIcon style={{color: params.row.status==="REVIEW_IN_PROGRESS" ? "white" : "orange"}}
                 onClick={(e) => onMarkInProgressButtonClick(e, params.row)}
                 variant="contained"
               />
             </Button>
             <Button style={{ minWidth: "10px" }}>
               <ThumbUpAltIcon style={{color: "green"}}
                 onClick={(e) => onConfirmButtonClick(e, params.row)}
                 variant="contained"
               />
             </Button>
             <Button style={{ minWidth: "10px" }}>
               <DeleteIcon style={{color: "red"}}
                 onClick={(e) => onDiscardButtonClick(e, params.row)}
                 variant="contained"
               />
             </Button>
           </div>
         );
       },
     },
     { field: "creationDate", headerName: "Creation Date", width: 140,
     renderCell: (params) => {
       return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                 {params.row.creationDate}
               </Typography>)
     }  
   },
   { field: "updateDate", headerName: "Update Date", width: 140,
     renderCell: (params) => {
       return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                 {params.row.updateDate}
               </Typography>)
     }  
   },
  ];

  const [customerIdentifier, setCustomerIdentifier] = useState();
  const [openEditDlg, setOpenEditDlg] = useState(false);

  const onEditButtonClick = (e,params) => {
    setCustomerIdentifier(params.email);
    setOpenEditDlg(true);

  }

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
    return;
  }
  setFeedback({ open: false, type: "error", message: "" });
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    //props.setOpen(false);
  };

  const queryClient = useQueryClient();

  const handleSuccess = () => {
    queryClient.invalidateQueries(['onboardings','onboardings-started']);
    setFeedback({
      open: true,
      type: "success",
      message: "The request was sent",
      onClose: () => {  
        //clearFunction();
        setFeedback({open: false});   
        handleClose();      
      },
    });
  }


  const confirmOnboardingMutation = useMutation(
    (request) => {
      return (
      HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/confirm`,
        {email: request.email}
      ))
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );


  const discardOnboardingMutation = useMutation(
    (request) => {
      return (
      HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/discard`,
        {email: request.email}
      ))
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );


  const markInProgressOnboardingMutation = useMutation(
    (request) => {
      return (
      HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/progress`,
        {email: request.email}
      ))
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const onConfirmButtonClick = (e,params) => {
   confirmOnboardingMutation.mutate({ email: params.email });
  }

  const onDiscardButtonClick = (e,params) => {
    discardOnboardingMutation.mutate({ email: params.email });
  }

  const onMarkInProgressButtonClick = (e,params) => {
    markInProgressOnboardingMutation.mutate({ email: params.email });
  }




  async function fetchOnboardings() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/onboarding/pending`
    );
    return response;
  }

  const { data, isLoading, isError } = useQuery(["onboardings"], fetchOnboardings);


  return (
    <Container maxWidth="xlg">
      <br />
      <br />
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={12}>
          <h1>OnBoarding Applications</h1>

          {isLoading ? (
            <h4>Loading...</h4>
          ) : !isError ? (
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data?.data}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
              />
            </div>
          ) : (
            <h4>An error ocurred, please try again later...</h4>
          )}
        </Grid>
      </Grid>
      {openEditDlg ?
        <OnboardingDetail
            action="SAVE"
            open={openEditDlg}
            setOpen={setOpenEditDlg}
            dialogTitle="Edit Onboarding"
            customerIdentifier={customerIdentifier}
        /> :""
      }
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
        <Alert
            onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
            severity={feedback.type}
            sx={{ width: "100%" }}
        >
            {feedback.message}
        </Alert>
      </Snackbar>


    </Container>
  );
}
