import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Divider
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useRef, useState, forwardRef } from "react";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useFormik } from "formik";
import * as yup from "yup";
import throttle from "lodash/throttle";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import {hasValue} from "../util/string";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CircularProgress from '@mui/material/CircularProgress';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import RenderOnRole from "./RenderOnRole";


const GOOGLE_MAPS_API_KEY = "AIzaSyBbDOjR9D0llQjIpTNV6BwxgivcmSje8hc"; //GSO_apikey

const autocompleteService = { current: null };

export default function OnboardingDetail(props) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [editMode, setEditMode] = useState(true);
  const [createdOnSystem, setCreatedOnSystem] = useState(false);

  const {customerIdentifier, rds} = props;

  async function fetchOnboardingDetail() {
    const response = await HttpService.getAxiosClient().get(
      rds ? `${window.API_URL}/onboarding?customerIdentifier=${customerIdentifier}&type=rds` : `${window.API_URL}/onboarding?customerIdentifier=${customerIdentifier}`
    );
    return response;
  }

  const {
    data: dataOnboardingDetail,
    isLoading: isLoadingOnboardingDetail,    
    isError: isErrorOnboardingDetail,
    isFetching: isFetchingOnboardingDetail,

  } = useQuery({ queryKey: ["onboardingDetail"], queryFn: fetchOnboardingDetail, refetchOnWindowFocus:false } );


  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  const fetchPlaceDetail = useMemo(
    () => (request, callback) => {
      if (request && request.place_id) {
        const getInfo = (address_components, key) => {
          for (let i = 0; i < address_components.length; i++) {
            if (address_components[i].types.includes(key))
              return address_components[i].short_name
                ? address_components[i].short_name
                : "";
          }
          return "";
        };

        const service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails({ placeId: request.place_id }, syncWithForm);

        function syncWithForm(place, status) {
          setFormikSynced(
            "addressLine1",
            getInfo(place.address_components, "street_number") +
              " " +
              getInfo(place.address_components, "route"),
            setAddressLine1
          );
          setFormikSynced(
            "addressLine2",
            getInfo(place.address_components, "subpremise"),
            setAddressLine2
          );
          setFormikSynced(
            "city",
            getInfo(place.address_components, "locality"),
            setCity
          );
          setFormikSynced(
            "state",
            getInfo(place.address_components, "administrative_area_level_1"),
            setState
          );
          setFormikSynced(
            "zipCode",
            getInfo(place.address_components, "postal_code"),
            setZipCode
          );
        }
      }
    },
    []
  );

  const queryClient = useQueryClient();

  
  useEffect(() => {
    fetchPlaceDetail(value);
  }, [value, fetchPlaceDetail]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: {
          country: ["us"],
        },
        fields: ["description", "place_id"],
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const loaded = useRef(false);

  function loadScript(src, position, id) {
    if (!position) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
  }

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const validationSchema = yup.object({
    email: yup
      .string("Enter the member email")
      .email("Enter a valid email")
      .required("Email is required"),
    memberId: yup
      .string("Enter the member id")
      .required("Member id is required"),
    companyId: yup
      .string("Enter the company id")
      .required("Company id is required"),
    firstName: yup
      .string("Enter the member First name")
      .matches(/^\S*$/, "First name should not contain spaces")
      .required("First name is required"),
    middle: yup.string("Enter the member middle"),
    lastName: yup
      .string("Enter the member Last name")
      .matches(/^\S*$/, "Last name should not contain spaces")
      .required("Last name is required"),
    mobilePhone: yup
      .string("Enter the member mobile phone")
      .matches(
        /^^\(?[2-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/,
        "Enter a valid phone number"
      )
      .required("Mobile phone is required"),
    homePhone: yup.string("Enter the member home phone"),
    dob: yup.date().required("Birthday is required"),
    ssn: yup
      .string()
      .matches(
        /^((?!666|000)[0-8][0-9_]{2}(-)?(?!00)[0-9_]{2}(-)?(?!0000)[0-9_]{4})|(9\d{2})([ -]?)(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])([ -]?)(\d{4})*$/,
        "Must be a valid SSN or ITIN"
      )
      .required("SSN is required"),
    addressLine1: yup
      .string("Enter the member Address Line 1")
      .required("Address line 1 is required"),
    addressLine2: yup.string("Enter the member Address Line 2"),
    city: yup.string("Enter the member city").required("City is required"),
    state: yup.string("Enter the member state").required("State is required"),
    zipCode: yup
      .string("Enter the member zip code")
      .required("zip is required")
  });


  const updateOnboardingMutation = useMutation(
    (onboardingPayload) => {
      onboardingPayload.dob = new Date(onboardingPayload.dob).toISOString().split("T")[0];
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding`,
        onboardingPayload
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['onboardings','onboardings-started']);
        setFeedback({
          open: true,
          type: "success",
          message: "onboarding updated succesfully",
          onClose: () => handleClose(),
        });
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
          setFeedback({
            open: true,
            type: "error",
            message: msg,
          });
      },
    }
  );

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      memberId: "",
      companyId: "",
      firstName: "",
      middle: "",
      lastName: "",
      mobilePhone: "",
      homePhone: "",
      dob: "",
      ssn: "",      
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      credential: "",
      idSeal: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateOnboardingMutation.mutate({ ...values, previousEmail: customerIdentifier, type: rds ? "rds" : null});
    },
  });

  const setFormikSynced = (key, value, callback) => {
    formik.setFieldValue(key, value, false);
    callback(value);
  };

  const wrapperFormikHandleChanged = (event) => {
    formik.handleChange(event);
    let callback = () => {};
    if (event.target.id === "addressLine1") callback = setAddressLine1;
    if (event.target.id === "addressLine2") callback = setAddressLine2;
    if (event.target.id === "city") callback = setCity;
    if (event.target.id === "state") callback = setState;
    if (event.target.id === "zipCode") callback = setZipCode;
    setFormikSynced(event.target.id, event.target.value, callback);
  };



  useEffect(() => {
    formik.resetForm();
    const { data } = dataOnboardingDetail || { };
    const { email, 
            firstName, 
            lastName, 
            memberId: employeeNumber, 
            ssn, 
            dob, 
            phone: mobilePhone, 
            addressLine1: addressLine1FromServer, 
            addressLine2: addressLine2FromServer, 
            city : cityFromServer, 
            state: stateFromServer, 
            zipCode: zipCodeFromServer, 
            createdOnSystem: createdOnSystemFromServer,
            companyId: employerNumber,
            _password: credential,
            idSeal,
          } = data || {};
    if (hasValue(email)) {
      formik.values.email = email;
    }
  
    if (hasValue(firstName)) {
      formik.values.firstName = firstName;
    }
  
    if (hasValue(lastName)) {
      formik.values.lastName = lastName;
    }
  
    if (employeeNumber!==undefined) {
      formik.values.memberId = employeeNumber;
    }

    if (employerNumber!==undefined) {
      formik.values.companyId = employerNumber;
    }
  
    if (ssn!==undefined) {
      formik.values.ssn = ssn;
    }
  
    if (mobilePhone!==undefined) {
      formik.values.mobilePhone = mobilePhone;
    }
  
    if (dob!==undefined) {
      formik.values.dob = dob;
    }

    if (credential!==undefined) {
      formik.values.credential = credential;
    }

    if (idSeal!==undefined) {
      formik.values.idSeal = idSeal;
    }
  

    if (hasValue(addressLine1FromServer)) {
      setAddressLine1(addressLine1FromServer);
      setFormikSynced(
        "addressLine1",
        addressLine1FromServer,
        setAddressLine1,
      );
    }

    if (hasValue(addressLine2FromServer)) {
      setAddressLine2(addressLine2FromServer);
      setFormikSynced(
        "addressLine2",
        addressLine2FromServer,
        setAddressLine2,
      );
    }

    if (hasValue(cityFromServer)) {
      setCity(cityFromServer);
      setFormikSynced(
        "city",
        cityFromServer,
        setCity,
      );
    }

    if (hasValue(stateFromServer)) {
      setState(stateFromServer);
      setFormikSynced(
        "state",
        stateFromServer,
        setState,
      );
    }

    if (hasValue(zipCodeFromServer)) {
      setZipCode(zipCodeFromServer);
      setFormikSynced(
        "zipCode",
        zipCodeFromServer,
        setZipCode,
      );
    }

    if (hasValue(createdOnSystemFromServer)) {
      setCreatedOnSystem(createdOnSystemFromServer==="true");
    }


  },[ dataOnboardingDetail ])

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
    formik.resetForm();
    queryClient.invalidateQueries(["onboardingDetail"]);
  };

  useEffect(() => {
    if (props.open === true) {
      formik.resetForm();
      setValue(null);
      setInputValue("");
      setAddressLine1("");
      setAddressLine2("");
      setCity("");
      setState("");
      setZipCode("");
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown={true}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <DialogTitle ><ModeEditIcon 
                  variant="contained"
                />{props.dialogTitle}<AccountCircleIcon  style={{marginLeft:"20px", marginRight:"10px"}}/><span style={{fontSize:"1rem"}} >{customerIdentifier}</span>                </DialogTitle>
        <DialogContent>

          <br />
          {isFetchingOnboardingDetail || isLoadingOnboardingDetail ?
            <CircularProgress color="inherit" />
          : isErrorOnboardingDetail ? 
            <h4>There was an error getting the info</h4> 
            :
            (<Grid container spacing={1.5}>
              <Grid container spacing={1.5}>
              <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="companyId"
                    type="text"
                    label="Company id"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.companyId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.companyId &&
                      Boolean(formik.errors.companyId)
                    }
                    helperText={
                      formik.touched.companyId && formik.errors.companyId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="memberId"
                    type="text"
                    label="Member id"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.memberId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.memberId &&
                      Boolean(formik.errors.memberId)
                    }
                    helperText={
                      formik.touched.memberId && formik.errors.memberId
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    id="email"
                    type="email"
                    label="Verified email"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <RenderOnRole roles={['onboarding-super-admin']}>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="credential"
                      type="text"
                      label="Credential"
                      variant="outlined"
                      required
                      disabled={!editMode}
                      value={formik.values.credential}
                      onChange={formik.handleChange}
                      error={formik.touched.credential && Boolean(formik.errors.credential)}
                      helperText={formik.touched.credential && formik.errors.credential}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      id="idSeal"
                      type="text"
                      label="Id Seal"
                      variant="outlined"
                      required
                      disabled={!editMode}
                      value={formik.values.idSeal}
                      onChange={formik.handleChange}
                      error={formik.touched.idSeal && Boolean(formik.errors.idSeal)}
                      helperText={formik.touched.idSeal && formik.errors.idSeal}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "slategray",
                        },
                      }}
                    />
                  </Grid>
                </RenderOnRole>

                <Grid item xs={5}>
                  <TextField
                    size="small"
                    fullWidth
                    id="firstName"
                    type="text"
                    label="First Name"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    fullWidth
                    id="middle"
                    type="text"
                    disabled={!editMode}
                    label="Middle Initial"
                    variant="outlined"
                    value={formik.values.middle}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.middle && Boolean(formik.errors.middle)
                    }
                    helperText={formik.touched.middle && formik.errors.middle}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    fullWidth
                    id="lastName"
                    type="text"
                    label="Last Name"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="mobilePhone"
                    type="text"
                    label="Mobile Phone"
                    variant="outlined"
                    required
                    disabled={!editMode}
                    value={formik.values.mobilePhone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mobilePhone &&
                      Boolean(formik.errors.mobilePhone)
                    }
                    helperText={
                      formik.touched.mobilePhone && formik.errors.mobilePhone
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="homePhone"
                    type="text"
                    label="Home Phone"
                    variant="outlined"
                    disabled={!editMode}
                    value={formik.values.homePhone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.homePhone &&
                      Boolean(formik.errors.homePhone)
                    }
                    helperText={
                      formik.touched.homePhone && formik.errors.homePhone
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="dob"
                    type="date"
                    label="DOB"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    disabled={!editMode}
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    helperText={formik.touched.dob && formik.errors.dob}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="ssn"
                    type="text"
                    label="SSN or ITIN"
                    variant="outlined"
                    disabled={!editMode}
                    value={formik.values.ssn}
                    onChange={formik.handleChange}
                    error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                    helperText={formik.touched.ssn && formik.errors.ssn}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <Divider style={{margin:"20px"}} />

              <Grid container spacing={1.5}>
              <br />

                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    id="google-control"
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    hidden={!editMode}//works
                    disabled={!editMode}
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    onChange={(event, newValue) => {
                      setOptions(newValue ? [newValue, ...options] : options);
                      setValue(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Enter a location" fullWidth />
                    )}
                    renderOption={(props, option) => {
                      const matches =
                        option.structured_formatting.main_text_matched_substrings;
                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [
                          match.offset,
                          match.offset + match.length,
                        ])
                      );

                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid item>
                              <Box
                                component={LocationOnIcon}
                                sx={{ color: "text.secondary", mr: 2 }}
                              />
                            </Grid>
                            <Grid item xs>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}

                              <Typography variant="body2" color="text.secondary">
                                {option.structured_formatting.secondary_text}
                              </Typography>
                            </Grid>
                          </Grid>
                          <div id="map"></div>
                        </li>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="addressLine1"
                    label="Address line 1"
                    value={addressLine1}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.addressLine1 &&
                      Boolean(formik.errors.addressLine1)
                    }
                    helperText={
                      formik.touched.addressLine1 && formik.errors.addressLine1
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    fullWidth
                    id="addressLine2"
                    label="Address line 2"
                    value={addressLine2}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={
                      formik.touched.addressLine2 &&
                      Boolean(formik.errors.addressLine2)
                    }
                    helperText={
                      formik.touched.addressLine2 && formik.errors.addressLine2
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="city"
                    label="City"
                    value={city}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="state"
                    label="State"
                    value={state}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    fullWidth
                    id="zipCode"
                    label="Zip Code"
                    value={zipCode}
                    disabled={!editMode}
                    onChange={wrapperFormikHandleChanged}
                    error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                                        sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "slategray",
                      },
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>)
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={"error"}>
            {updateOnboardingMutation.isLoading ? <CircularProgress color="inherit" /> : "Close"}
          </Button>
          <Button  disabled={!editMode || updateOnboardingMutation.isLoading} type="submit">{props.action}</Button>
        </DialogActions>
      </Box>
      <Snackbar
          open={feedback.open}
          autoHideDuration={6000}
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
            severity={feedback.type}
            sx={{ width: "100%" }}
          >
            {feedback.message}
          </Alert>
        </Snackbar>
    </Dialog>
  );
}
