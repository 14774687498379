import React, { Fragment, useEffect, useState, forwardRef} from 'react';
import { useMutation } from "@tanstack/react-query";
import { DataGrid,  GridToolbar, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { Button, Snackbar, Tooltip, Typography  } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import RefreshingIndicator from "./RefreshingIndicator"
import DownloadIcon from '@mui/icons-material/Download';
import HttpService from "../services/HttpService";

export default function Payments(props) {

    const {
            isLoading, 
            isFetching, 
            isError, 
            data, 
            setPageFunction, 
            setFilterStringFunction,
            dataCount,
            isLoadingDataCount,
            title,
            type,
        } = props;

    const [select, setSelection] = useState([]);
    const [filter, setFilter] = useState("");
    

    const onFilterChange= (filter,params) => {
        const filterString = filter.quickFilterValues.join(" ");
        if (filterString===undefined) {
            setFilterStringFunction("");
            setFilter("");
        }else {
            setFilterStringFunction(filterString);
            setFilter(filterString);
        }
        return filter;
    }

    const downloadDetailsMutation = useMutation(
        (params) => {
          return HttpService.getAxiosClient().get(
            window.API_URL + `/payfile/details/export?filter=`+filter+`&type=`+type,
            {responseType: 'blob'}
          );
        },
        {
          onSuccess: (response) => {
            const { headers, data } = response;
            const disposition = headers['content-disposition'];
            let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
            if (filename.toLowerCase().startsWith("utf-8''"))
               filename = decodeURIComponent(filename.replace("utf-8''", ''));
            else
               filename = filename.replace(/['"]/g, '');
            let url = window.URL.createObjectURL(data);
            let a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a); // append the element to the dom
            a.click();
            a.remove(); // afterwards, remove the element 
            setFeedback({
              open: true,
              type: "success",
              message: "The file was download succesfully",
              onClose: () => {  setFeedback({open: false}); },
            });
            
          },
          onError: (err) => {
            if (err?.response?.status===500) {
              setFeedback({
                open: true,
                type: "error",
                message: "There was an error trying to download the file",
              });
            }
            else {
              const msg = err?.response?.data
              ? err.response.data
              : err.message;
               setFeedback({
               open: true,
               type: "error",
               message: msg,
             });
            }
    
          },
        }
    );
    
    const onDownloadDetailsButtonClick = (e, params) => { 
        downloadDetailsMutation.mutate(null);
    };

    const [feedback, setFeedback] = useState({
        open: false,
        type: "error",
        message: "",
      });

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
    });

    const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }
    setFeedback({ open: false, type: "error", message: "" });
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataColumns = [
        { field: "id", headerName: "ID", width: 90 , hidden: true  },
        { field: "memberId", headerName: "Member Id", width: 110,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 11, mb: 0 }}   gutterBottom>
                        {params.row.memberId}
                    </Typography>)
                }
        
        },
        { field: "email", headerName: "Email", width: 180,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 11, mb: 0 }}   gutterBottom>
                        {params.row.email}
                    </Typography>)
                }
        }, 
        { field: "amount", headerName: "Amount", width: 90, align:'right',
            valueFormatter: (params) => {
                if (params.value == null) {
                return '';
                }

                const valueFormatted = Number(params.value).toLocaleString("en-US", { minimumFractionDigits: 2});
                return `${valueFormatted}`;
            },
        },
        { field: "fullName", headerName: "Name", width: 130,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 11, mb: 0 }}   gutterBottom>
                        {params.row.fullName}
                    </Typography>)
                }
        },
        { field: "status", headerName: "Status", width: 160,             
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 11, mb: 0 }}   gutterBottom>
                        {params.row.status}
                     </Typography>)
                } 
        },
        { field: "updateDate", headerName: "Update Date", width: 130, 
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 11, mb: 0 }}   gutterBottom>
                        {params.row.updateDate}
                    </Typography>)
                } 
        },
        { field: "idDescriptor", headerName: "Descriptor", width: 180,             
        renderCell: (params) => {
            return (<Typography sx={{   fontSize: 11, mb: 0 }}   gutterBottom>
                    {params.row.idDescriptor}
                 </Typography>)
            } 
        }, 
        { field: "filename", headerName: "Batch Id", width: 170,
            renderCell: (params) => {
                return (<Tooltip title={params.row.fileName}><span><Typography sx={{   fontSize: 11, mb: 0 }}   gutterBottom>
                    {params.row.fileName}
                    </Typography></span></Tooltip>)
            }
        },
        { field: "error", headerName: "Error", width: 150,
            renderCell: (params) => {
                return (<Tooltip title={params.row.error}><span><Typography sx={{   fontSize: 11, mb: 0 }}   gutterBottom>
                    {params.row.error}
                    </Typography></span></Tooltip>)
            }
        },
      ];

    const columns = React.useMemo(
    () => [
        { 
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        headerName: "Change Location",
        width: 100,
        },
        ...dataColumns,
    ],
    [dataColumns],
    );


    const handleRowSelection = (rows) => {
            setSelection(rows);
    }


    useEffect(() => {
    }, [select]);
    return (

        <Fragment>

            <div style={{display : "flex", justifyContent: "space-between"}}>
                <div style={{marginRight:"20px"}}><h2>{title}</h2></div> 
                {isFetching ? (<RefreshingIndicator/>) :""}
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems:"center"}}>
                    <Button
                            style={{ margin: "10px", height: "40px" }}
                            onClick={onDownloadDetailsButtonClick}
                            variant="contained"
                            disabled={downloadDetailsMutation.isLoading}
                            color={downloadDetailsMutation.isLoading ? "secondary" : "primary"}
                        >
                            <DownloadIcon style={{paddingRight:"5px"}}/>
                            {downloadDetailsMutation.isLoading ? "Downloading..." : "Export Details"}
                    </Button>
                </div>
            </div>

            
            {isLoading || isLoadingDataCount ? (
                <CircularProgress color="inherit" />
            ) : !isError ? (
            <div style={{ height: 450, width: "100%" }}>

                <DataGrid
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    columnVisibilityModel={{id: false}}
                    onSelectionModelChange={handleRowSelection}
                    selectionModel={select}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                    toolbar: {
                        showQuickFilter: true, 
                        quickFilterProps: { debounceMs: 800 },
                    },
                    }}
                    rows={data?.data}
                    columns={columns}
                    rowCount={dataCount?.data}
                    onPageChange={(newPage) => { setPageFunction(newPage);} }
                    paginationMode="server"
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    sx={{
                      "& .MuiButton-root": {
                        display: "none",
                      }
                    }}
                    keepNonExistentRowsSelected
                />


                <Snackbar
                    open={feedback.open}
                    autoHideDuration={6000}
                    onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                    <Alert
                        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                        severity={feedback.type}
                        sx={{ width: "100%" }}
                    >
                        {feedback.message}
                    </Alert>
                </Snackbar>
            </div>
            ) : (
            <h4>An error ocurred, please try again later...</h4>
            )}
        </Fragment>

    );
}