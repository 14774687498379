import React, { Fragment, useState, forwardRef} from 'react';
import { DataGrid,  GridToolbar } from "@mui/x-data-grid";
import { InputLabel, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Typography, OutlinedInput, FormControl, Select, MenuItem  } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import RefreshingIndicator from "./RefreshingIndicator"
import VisibilityIcon from '@mui/icons-material/Visibility';
import PaymentsDetail from './PaymentsDetail';
import ButtonWithIcon from './Button';
import HttpService from "../services/HttpService";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import RenderOnRole from './RenderOnRole';
import RenderIfNotOnRole from './RenderIfNotOnRole';
import PaymentUpload from './PaymentUpload';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CreatePayFile from './CreatePayFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import GroupMultiSelect from './GroupMultiSelect';
import UserService from "../services/UserService";
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function PaymentsSummary(props) {
    const [selectedElement, setSelectedElement] = React.useState('');

    const handleChangeSingle = (event) => {
      setSelectedElement(event.target.value);
      props.setEmployerIdFunction(event.target.value);
    };

    const theme = useTheme();

    const {
            isLoading, 
            isFetching, 
            isError, 
            data, 
            title,
        } = props;

    const [select, setSelection] = useState([]);
    
    const queryClient = useQueryClient();

    async function fetchMultiBalanceStats() {
      const response = await HttpService.getAxiosClient().get(
        window.API_URL + `/balance/multistats`
      );
      return response;
    }
  
    const {
      data: dataBalances,
      isLoading: isLoadingBalances,
      isError: isErrorBalances,
    } = useQuery(["multiBalanceStats"], fetchMultiBalanceStats);
  

    const confirmPaymentMutation = useMutation(
      (idPayfile) => {
        return HttpService.getAxiosClient().post(
          window.API_URL + `/payfile/${idPayfile}/confirm`,
        );
      },
      {
        onSuccess: () => {
          setFeedback({
            open: true,
            type: "success",
            message: "The request was verified succesfully",
            onClose: () => {  queryClient.invalidateQueries({ queryKey: ['paymentsSummary'] }); setFeedback({open: false}); },
          });
          
        },
        onError: (err) => {
          const msg = err?.response?.data
            ? err.response.data
            : err.message;
             setFeedback({
             open: true,
             type: "error",
             message: msg,
           });
        },
      }
    );

    const downloadTemplateMutation = useMutation(
      (params) => {
        return HttpService.getAxiosClient().post(
          window.API_URL + `/payfile/template/export?idEmployer=${params.employerId}`,
          {
            groups: params.elements,
            idDescriptor: params.selectedElement,
          } , 
          {responseType: 'blob'}
        );
      },
      {
        onSuccess: (response) => {
          const { headers, data } = response;
          const disposition = headers['content-disposition'];
          let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
          if (filename.toLowerCase().startsWith("utf-8''"))
             filename = decodeURIComponent(filename.replace("utf-8''", ''));
          else
             filename = filename.replace(/['"]/g, '');
          let url = window.URL.createObjectURL(data);
          let a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a); // append the element to the dom
          a.click();
          a.remove(); // afterwards, remove the element 
          setFeedback({
            open: true,
            type: "success",
            message: "The file was download succesfully",
            onClose: () => {  setFeedback({open: false}); },
          });
          
        },
        onError: (err) => {
          if (err?.response?.status===500) {
            setFeedback({
              open: true,
              type: "error",
              message: "There was an error trying to download the file",
            });
          }
          else {
            const msg = err?.response?.data
            ? err.response.data
            : err.message;
             setFeedback({
             open: true,
             type: "error",
             message: msg,
           });
          }
  
        },
      }
    );

    const cancelPaymentMutation = useMutation(
        (idPayfile) => {
          return HttpService.getAxiosClient().post(
            window.API_URL + `/payfile/${idPayfile}/cancel`,

          );
        },
        {
          onSuccess: () => {
            setFeedback({
              open: true,
              type: "success",
              message: "The request was verified succesfully",
              onClose: () => {  queryClient.invalidateQueries({ queryKey: ['paymentsSummary'] }); setFeedback({open: false}); },
            });
            
          },
          onError: (err) => {
            const msg = err?.response?.data
              ? err.response.data
              : err.message;
               setFeedback({
               open: true,
               type: "error",
               message: msg,
             });
          },
        }
      );



    const [feedback, setFeedback] = useState({
        open: false,
        type: "error",
        message: "",
      });

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
    });

    const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }
    setFeedback({ open: false, type: "error", message: "" });
    };

    const [openNewDlg, setOpenNewDlg] = useState(false);
    const [openUploadDlg, setOpenUploadDlg] = useState(false);
    const [openCreateDlg, setCreateNewDlg] = useState(false);
    const [openTemplateDlg, setOpenTemplateDlg] = useState(false);

    const [paymentInfo, setPaymentInfo] = useState({});

    const onViewButtonClick = (e,params) => {
        setPaymentInfo(params);
        setOpenNewDlg(true);
     }      
    
    const onVerifyButtonClick = (e, params) => {
        confirmPaymentMutation.mutate(params.idPayFile);
    };

    const onAddButtonClick = (e, params) => {
        setOpenUploadDlg(true);
    };

    const onCreateButtonClick = (e, params) => {
      setCreateNewDlg(true);
    };

    const createDlg = (idEmployer) => (
        <CreatePayFile idEmployer={idEmployer} openCreateDlg={openCreateDlg} setCreateNewDlg={setCreateNewDlg} />
    )
    const onGenerateTemplateButtonClick = (e, params) => { 
      if (UserService.hasRole(['group-crud','group-member-crud'])) {
        setOpenTemplateDlg(true);
      } else {
        downloadTemplateMutation.mutate(null);
      }
  };
    
    
      
    const onCancelButtonClick = (e, params) => {
      cancelPaymentMutation.mutate(params.idPayFile);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataColumns = [
        { field: "id", headerName: "ID", width: 90 , hidden: true  },
        { field: "idPayFile", headerName: "Id", width: 60,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.idPayFile}
                    </Typography>)
                }
        
        },
        { field: "description", headerName: "Description", width: 300,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.description}
                    </Typography>)
                }
        
        },
        { field: "idEmployer", headerName: "Employer", width: 100,
        renderCell: (params) => {
            return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                    {params.row.idEmployer}
                </Typography>)
            }
        },
        { field: "numberOfRecords", headerName: "Records #", width: 80,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.numberOfRecords}
                    </Typography>)
                }
        },


        { field: "totalAmount", headerName: "Total", width: 100,             
            renderCell: (params) => {
                return (<Typography sx={{ fontSize: 12, mb: 0, color: params.row.type === 'UNLOAD' ? 'red' : 'inherit' }} gutterBottom>
                  {params.row.type === 'UNLOAD' ? `-${params.row.totalAmount}` : params.row.totalAmount}
                </Typography>)
                } 
        },
        { field: "type", headerName: "Type", width: 100,             
          renderCell: (params) => {
              return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                      {params.row.type}
                   </Typography>)
              } 
        },
        {
            field: "processStatus",
            headerName: "Status/Action",
            width: 165,
            renderCell: (params) => {
              return (
                // you will find row info in params
                <div>
                  <RenderOnRole roles={['pf-confirm']}>
                    <ButtonWithIcon 
                        hidden={!(params.row.processStatus==="PENDING_CONFIRMATION")} 
                        onClick={onVerifyButtonClick}
                        color="green"
                        tooltip="Click to verify and accept the request"
                        icon="confirm"
                        record={params.row} 
                        isLoading={confirmPaymentMutation.isLoading} 
                        disabled={cancelPaymentMutation.isLoading}
                        />
                    <ButtonWithIcon 
                        hidden={!(params.row.processStatus==="PENDING_CONFIRMATION")} 
                        onClick={onCancelButtonClick}
                        color="red"
                        tooltip="Click to cancel and close the request"
                        icon="deny"
                        record={params.row}
                        isLoading={cancelPaymentMutation.isLoading} 
                        disabled={confirmPaymentMutation.isLoading}
                    />
                    <Typography sx={{ display: params.row.processStatus==="PENDING_CONFIRMATION"?  "none":"block", fontWeight: "bold", fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.processStatus}
                    </Typography>
                  </RenderOnRole>
                  <RenderIfNotOnRole roles={['pf-confirm']}>
                    <Typography sx={{ display: "block", fontWeight: "bold", fontSize: 12, mb: 0 }}   gutterBottom>
                            {params.row.processStatus}
                    </Typography>

                  </RenderIfNotOnRole>


                </div>
              );
            },
          },
        { field: "paymentDate", headerName: "Created At", width: 130,             
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.paymentDate}
                     </Typography>)
                } 
        },
        { field: "details", headerName: "Details", width: 80, renderCell: (params) => {
            return (
                <Button style={{ minWidth: "10px" }}
                    ><VisibilityIcon
                        onClick={(e) => onViewButtonClick(e, params.row)}
                        variant="contained"
                    />
                </Button> 
            )       
    }}
      ];




    const handleRowSelection = (rows) => {
            setSelection(rows);
    }


/*    useEffect(() => {
    }, [select]);
  */
    return (

        <Fragment>
            <div style={{display : "flex"}}>
                <div style={{marginRight:"20px"}}><h1>{title}</h1></div> 
                {isFetching ? (<RefreshingIndicator/>) :""}
            </div>

            {isLoading  ? (
                <CircularProgress color="inherit" />
            ) : !isError ? (
            <div style={{ height: 450, width: "100%" }}>
              <div style={{display : "flex" }}>
              <div style={{ textAlign: "center" , display: "flex", justifyContent: "flex-end"}}>
                <RenderOnRole roles={['pf-multibalance']}>

                  <FormControl key="departmentsSingleSelect" sx={{ m: 1, width: 350, display: "flex", justifyContent: "space-around" }}>
                      <InputLabel id="demo-multiple-chip-label">Department</InputLabel>
                      <Select
                          labelId="demo-single-select-label"
                          id="demo-single-select"
                          value={selectedElement}
                          onChange={handleChangeSingle}
                          input={<OutlinedInput id="select-single" label="Department" />}
                          MenuProps={MenuProps}
                      >
                      {
                        dataBalances?.data?.map((item, index) =>  {
                              return (
                              <MenuItem
                                  key={index}
                                  name={item.employerDescription}
                                  value={item.idEmployer}
                                  style={getStyles(item.employerDescription, selectedElement, theme)}
                              >
                                  {item.employerDescription}
                              </MenuItem>
                          )})
                      }
                      </Select>

                  </FormControl>
                </RenderOnRole>
              </div>
              <div style={{display : "flex" }}>
                  <RenderOnRole roles={['pf-creator']}>
                      <Button
                          style={{ margin: "10px" }}
                          onClick={onCreateButtonClick}
                          variant="contained"
                      >
                          <AutoFixHighIcon style={{paddingRight:"5px"}}/>
                          Create Pay File
                      </Button>
                  </RenderOnRole>
                  <RenderOnRole roles={['pf-uploader']}>
                      <Button
                          style={{ margin: "10px" }}
                          onClick={onAddButtonClick}
                          variant="contained"
                      >
                          <PostAddIcon style={{paddingRight:"5px"}}/>
                          Upload Pay File
                      </Button>
                  </RenderOnRole>
                  <RenderOnRole roles={['pf-template']}>
                      <Button
                          style={{ margin: "10px" }}
                          onClick={onGenerateTemplateButtonClick}
                          variant="contained"
                      >
                          <FileCopyIcon style={{paddingRight:"5px"}}/>
                          Generate Template
                      </Button>
                  </RenderOnRole>
              </div>

              </div>




                <DataGrid
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    columnVisibilityModel={{id: false}}
                    onSelectionModelChange={handleRowSelection}
                    selectionModel={select}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                    toolbar: {
                        showQuickFilter: true, 
                        quickFilterProps: { debounceMs: 800 },
                    },
                    }}
                    rows={data?.data}
                    columns={dataColumns}
                    /*sx={{
                      "& .MuiButton-root": {
                        display: "none",
                      }
                    }}*/
                    keepNonExistentRowsSelected
                />
                <Snackbar
                    open={feedback.open}
                    autoHideDuration={6000}
                    onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                    <Alert
                        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                        severity={feedback.type}
                        sx={{ width: "100%" }}
                    >
                        {feedback.message}
                    </Alert>
                </Snackbar>
            </div>
            ) : (
            <h4>An error ocurred, please try again later...</h4>
            )}
            {openNewDlg ?
                <PaymentsDetail
                    open={openNewDlg}
                    setOpen={setOpenNewDlg}
                    dialogTitle="Payment Batch Details"
                    paymentInfo={paymentInfo}
                /> :""
            }
            {openUploadDlg ?
                <PaymentUpload
                    open={openUploadDlg}
                    setOpen={setOpenUploadDlg}
                    dialogTitle="UploadFile"
                    paymentInfo={paymentInfo}
                    employerId={selectedElement || props.employerIdentifier}
                /> :""
            }
            {openCreateDlg ? createDlg(selectedElement || props.employerIdentifier) :""}
            {openTemplateDlg ?
             <GroupMultiSelect 
                open={openTemplateDlg} 
                setOpen={setOpenTemplateDlg} 
                action={downloadTemplateMutation} 
                employerId={selectedElement || props.employerIdentifier}
              /> : ""
            }
        </Fragment>

    );
}