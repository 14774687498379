import {
  Alert as MuiAlert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Snackbar,
  Divider} from "@mui/material";

import { Box } from "@mui/system";
import React, { Fragment, forwardRef, useState } from "react";
import HttpService from "../services/HttpService";
import CircularProgress from '@mui/material/CircularProgress';
import uploadImg from "./../images/upload.svg";
import FileUpload from "react-mui-fileuploader"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useMutation, useQueryClient  } from "@tanstack/react-query";

export default function PaymentUpload(props) {
  
  
 const queryClient = useQueryClient()


 const uploadFileMutation = useMutation(
    (formData) => {
      const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      };
      return (
      HttpService.getAxiosClient().post(
        window.API_URL + `/payfile/upload?idEmployer=`+props.employerId,
        formData,
        config
      ))
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['paymentsSummary'] });
        setFeedback({
          open: true,
          type: "success",
          message: "file uploaded succesfully",
          onClose: () => { 
            props.setOpen(false);
            setFeedback({open: false});
          },
        });
      },
      onError: (err) => {
        const msg = err?.response?.data
          ? err.response.data
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );
  
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

  const [filesToUpload, setFilesToUpload] = useState([])
  const [buttonToUploadEnabled, setButtonToUploadEnabled] = useState(false);
  const handleFilesChange = (files) => {
    setFilesToUpload([ ...files ])
    if (files.length===1) {
      setButtonToUploadEnabled(true);
    } else {
      setButtonToUploadEnabled(false);
    }
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }
    setFeedback({ open: false, type: "error", message: "" });
    };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
  };

  const uploadFiles = () => {
    let formData = new FormData()
    filesToUpload.forEach((file) => formData.append("file", file))
    uploadFileMutation.mutateAsync(formData);
  }

  return (
    <Dialog  
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown={true}
      sx={{
          "& .MuiPaper-root": {
            maxWidth: 1000,
          }
        }}    
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <DialogActions>
          <Button onClick={handleClose} color={"error"}>
            {false ? <CircularProgress color="inherit" /> : "Close"}
          </Button>
        </DialogActions>

        <DialogContent sx={{  width: 1000 ,flexShrink: 0 }}>
        <Fragment>
            {false ? (
            <h4>Loading...</h4>
            ) : !false ? (
            <Fragment>
              <Grid style={{marginLeft:"20px", marginRight:"10px", marginTop:0}} container spacing={1.5}>
                <Container maxWidth="xlg">

                  <Grid container spacing={1.5} style={{maxWidth:"80%"}}>
                    <Grid item xs={12} md={4}>
                      <br />
                      <br />
                      <img color="primary" src={uploadImg} alt="upload" width="50%" loading="lazy" />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <h1>Upload Payment File</h1>

                      <Fragment>
                        <FileUpload
                          multiFile={false}
                          onFilesChange={handleFilesChange}
                          title="Please upload the CSV file"
                          showPlaceholderImage={false}
                          allowedExtensions={['csv']}
                          maxUploadFiles={1}
                          header="> DRAG AND DROP HERE <"
                          BannerProps={{ 
                            elevation: 0, 
                            variant: "outlined",             
                            sx: {
                              "& .MuiGrid-item": {
                                "color": "#0d7f81",
                                "backgroundColor": "aliceblue",
                                "borderColor": "#0d7f81"
                              },
                              "& .MuiButtonBase-root": {
                                "color": "#0d7f81",
                                "backgroundColor": "white",
                                "borderColor": "#0d7f81"
                              }
                            }  
                          }}
                          ContainerProps={{
                            elevation: 0,
                            variant: "outlined",
                            sx: { p: 1,                              
                                "& .MuiGrid-item": {
                                "color": "#1976d2",
                                "backgroundColor": "aliceblue",
                                "borderColor": "#1976d2"
                              },
                              "& .MuiGrid-root": {
                                "color": "#1976d2",
                                "backgroundColor": "aliceblue",
                                "borderColor": "#1976d2"
                              } }
                          }}
 
                          onContextReady={(context) => {}}
                        />
                          <Button
                              
                              disabled={!buttonToUploadEnabled}
                              style={{ margin: "10px" }}
                              onClick={(e) => uploadFiles(e)}
                              variant="contained"
                            >
                              { uploadFileMutation.isLoading ? <CircularProgress size="1.5rem" style={{paddingRight:"1px",  color:"white"}} /> :<CloudUploadIcon style={{paddingRight:"5px"}}/>}
                              Upload
                          </Button>
                      </Fragment>
                    </Grid>
                  </Grid>


                  <Snackbar
                      open={feedback.open}
                      autoHideDuration={6000}
                      onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      >
                      <Alert
                          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                          severity={feedback.type}
                          sx={{ width: "100%" }}
                      >
                          {feedback.message}
                      </Alert>
                  </Snackbar>
                  <Divider style={{margin:"15px"}} />
                </Container>
              </Grid>

            </Fragment>

            ) : (
            <h4>An error ocurred, please try again later...</h4>
            )}


        </Fragment>
        </DialogContent>

      </Box>
    </Dialog>
  );
}
