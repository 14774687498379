import PropTypes from 'prop-types'
import UserService from "../services/UserService";

const RenderIfNotOnRole = ({ roles, children }) => (UserService.hasRole(roles))  ?  null :children ;

RenderIfNotOnRole.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RenderIfNotOnRole
