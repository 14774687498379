import HttpService from "../services/HttpService";
import React, { useState} from 'react';

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Movements from "../components/Movements";
import CardStatsElement from "../components/CardStatsElement";
import BalanceElement from "../components/BalanceElement";

export default function QueryMultiBalance() {

  async function fetchMultiBalanceStats() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/balance/multistats`
    );
    return response;
  }

  const {
    data: dataStats,
    isLoading: isLoadingStats,
    isError: isErrorStats,
  } = useQuery(["multiBalanceStats"], fetchMultiBalanceStats);

  const queryClient = useQueryClient();


  const [singleBalanceEmployerId, setSingleBalanceEmployerId] = useState(null);
  const [showType, setShowType] = useState(null);
  async function fetchBalanceStats() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/balance/stats?idEmployer=`+singleBalanceEmployerId
    );
    return response;
  }

  const {
    data: dataSingleStats,
    isLoading: isLoadingSingleStats,
    isError: isErrorSingleStats,
  } = useQuery(["balanceStats", singleBalanceEmployerId], fetchBalanceStats, { enabled: singleBalanceEmployerId !== null });


  const showBalanceDetails= (idEmployer) => {
    setSingleBalanceEmployerId(idEmployer);
    setShowType("stats");
  }

  const showMovements= (idEmployer) => {
    setSingleBalanceEmployerId(idEmployer);
    setShowType("movements");
    queryClient.invalidateQueries(['movements','movementsCount']);
  }


  return (
    <>

    <Typography variant="h4" color="text.secondary" sx={{fontWeight:"bold", textAlign: 'center', marginTop: '20px'}}>
      Department Balances
    </Typography>
    <Box sx={{margin: '0', padding: '0 150px'}}>
      <br />
      <Grid container  spacing={2}>
            {dataStats && dataStats?.data?.map((item, index) => {
              return (
                <BalanceElement 
                  headerTypograhy='h7' 
                  minHeight={120} 
                  fontSize={30} 
                  md={4} 
                  isErrorStats={isErrorStats} 
                  isLoadingStats={isLoadingStats} 
                  text={item.employerDescription} 
                  data={item && item.currentBalance} 
                  type="amount"
                  idElement={item.idEmployer}
                  showStatsAction={(id) => showBalanceDetails(id) }
                  showMovementsAction={(id) => showMovements(id) }
                  selected={singleBalanceEmployerId===item.idEmployer}
                />
              );
            }
            )}
      </Grid>
      {(singleBalanceEmployerId!==null && singleBalanceEmployerId!==undefined && showType==="stats") ? 
       (
        <Grid item xs>
        <Divider sx={{ marginTop: "2rem" }}/>
          <Grid container spacing={1} >
            <CardStatsElement key={`cTM-${singleBalanceEmployerId}`} headerTypograhy='h8' minWidth={10} fontSize={20} md={4} isErrorStats={isErrorSingleStats} isLoadingStats={isLoadingSingleStats} text="Credits this month" data={dataSingleStats && dataSingleStats.data.creditsThisMonth} type="amount" />
            <CardStatsElement key={`cTW-${singleBalanceEmployerId}`} headerTypograhy='h8' minWidth={10} fontSize={20} md={4} isErrorStats={isErrorSingleStats} isLoadingStats={isLoadingSingleStats} text="Credits this week" data={dataSingleStats && dataSingleStats.data.creditsThisWeek} type="amount" />
            <CardStatsElement key={`cT-${singleBalanceEmployerId}`} headerTypograhy='h8' minWidth={10} fontSize={20} md={4} isErrorStats={isErrorSingleStats} isLoadingStats={isLoadingSingleStats} text="Credits today" data={dataSingleStats && dataSingleStats.data.creditsToday} type="amount" />
          </Grid>
         <Grid container spacing={1} >
            <CardStatsElement key={`dTM-${singleBalanceEmployerId}`} headerTypograhy='h8'  minWidth={10} fontSize={20} md={4} isErrorStats={isErrorSingleStats} isLoadingStats={isLoadingSingleStats} text="Debits this month" data={dataSingleStats && dataSingleStats.data.debitsThisMonth} type="amount" />
            <CardStatsElement key={`dTW-${singleBalanceEmployerId}`} headerTypograhy='h8' minWidth={10}  fontSize={20} md={4} isErrorStats={isErrorSingleStats} isLoadingStats={isLoadingSingleStats} text="Debits this week" data={dataSingleStats && dataSingleStats.data.debitsThisWeek} type="amount" />
            <CardStatsElement key={`dT-${singleBalanceEmployerId}`} headerTypograhy='h8' minWidth={10}  fontSize={20} md={4} isErrorStats={isErrorSingleStats} isLoadingStats={isLoadingSingleStats} text="Debits today" data={dataSingleStats && dataSingleStats.data.debitsToday} type="amount" />
          </Grid>
      </Grid>
       ) : (isLoadingSingleStats) ? "" : (isErrorSingleStats) ? "Error" : ""
      }
      {(singleBalanceEmployerId!==null && singleBalanceEmployerId!==undefined && showType==="movements") ? 
       (
        <Movements title="Movements" key={singleBalanceEmployerId} idEmployer={singleBalanceEmployerId} />
       ) : (isLoadingSingleStats) ? "" : (isErrorSingleStats) ? "Error" : ""
      }

    </Box>
    </>

  );
}
