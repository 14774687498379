import { Alert, Autocomplete, Box, Button, Grid, Snackbar, TextField } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import { useFormik } from "formik";
import * as yup from "yup";

export default function AddEmployerAttribute(props) {
  const createEmployeeAttributeMutation = useMutation(
    (values) => {
      values.companieList = values.companieList.map((companie) => companie.idEmployer );
      return HttpService.getAxiosClient().post(
        window.API_URL + `/employer/attributes`,
        values
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("employerAttributes");
        props.handleClose();
      },
      onError: (error) => {
        setFeedback({
          open: true,
          type: "error",
          message: "Error creating the attributes, constraint violated.",
        });
      },
    }
  );

  const validationSchema = yup.object({
    companieList: yup.array().min(1, "At least one company is required"),
    attribute: yup
      .string("Enter the attribute")
      .required("Attribute is required"),
    value: yup.string("Enter the value").required("Value is required"),
  });

  const formik = useFormik({
    initialValues: {
      companieList: [],
      attribute: "",
      value: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createEmployeeAttributeMutation.mutate({ ...values });
    },
  });

  const queryClient = useQueryClient();

  async function fetchCompanyData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer/all`
    );
    return response;
  }

  async function fetchAttributesData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer/attributes/list`
    );
    return response;
  }

  const { data, isLoading } = useQuery(["companies"], () => fetchCompanyData());
  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const { data: dataAttributes, isLoading: isLoadingAttributes } = useQuery(
    ["attributes"],
    () => fetchAttributesData()
  );

  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const handleSelectionChange = (event, values) => {
    setSelectedCompanies(values);
  };

  return (
    <div>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        {isLoading || isLoadingAttributes ? (
          ""
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                multiple
                id="companieList"
                options={data.data}
                getOptionLabel={(option) =>
                  `${option.description}-[${option.idEmployer}]`
                }
                filterSelectedOptions
                value={formik.values.companieList}  
                onChange={(event, selectedOptions) => {
                  formik.setFieldValue("companieList", selectedOptions);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formik.values.companieList}
                    error={ Boolean(formik.errors.companieList)}
                    helperText={formik.errors.companieList}
                    label="Company list"
                    placeholder="Company"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                freeSolo
                id="attribute"
                options={dataAttributes.data}
                filterSelectedOptions
                onSelect={formik.handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={formik.values.attribute}
                    onChange={formik.handleChange}
                    error={ Boolean(formik.errors.attribute)}
                    helperText={formik.errors.attribute}
                    label="Attribute"
                    placeholder="Attribute"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="value"
                label="Value"
                value={formik.values.value}
                onChange={formik.handleChange}
                error={formik.touched.value && Boolean(formik.errors.value)}
                helperText={formik.touched.value && formik.errors.value}
              />
            </Grid>
            <Grid item xs={12} pt={3}>
              <Button fullWidth variant="contained" type="submit" disabled={createEmployeeAttributeMutation.isLoading}>
                SAVE
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
