import ReactDOM from "react-dom/client";
import App from "./components/App";
import UserService from "./services/UserService";
import HttpService from "./services/HttpService";
//import 'mdb-react-ui-kit/dist/css/mdb.min.css';
//import "@fortawesome/fontawesome-free/css/all.min.css";

const renderApp = () => ReactDOM.createRoot(document.getElementById('app')).render(<App />);

UserService.initKeycloak(renderApp);
HttpService.configure();
