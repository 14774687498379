import React, { Fragment, useEffect, useState, forwardRef } from "react";
import {
  DataGrid,
  GridToolbar,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid";
import {
  Button,
  Snackbar,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import RefreshingIndicator from "./RefreshingIndicator";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MemberDetail from "./MemberDetail";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import CloseIcon from "@mui/icons-material/Close";
import ModifyCompanyMember from "./ModifyCompanyMember";
import RenderOnRole from "./RenderOnRole";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";




export default function Members(props) {
  const {
    isLoading,
    isFetching,
    isError,
    data,
    setPageFunction,
    setFilterStringFunction,
    dataCount,
    isLoadingDataCount,
    title,
  } = props;
  const queryClient = useQueryClient();

  const [select, setSelection] = useState([]);


   
  const onFilterChange= (filter,params) => {
        const filterString = filter.quickFilterValues.join(" ");
        if (filterString===undefined) {
            setFilterStringFunction("");
        }else {
            setFilterStringFunction(filterString);
        }
        return filter;
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const [openNewDlg, setOpenNewDlg] = useState(false);
  const [openEditMemberDlg, setOpenEditMemberDlg] = useState(false);
  const [memberInfo, setMemberInfo] = useState({});

  const onViewButtonClick = (e, params) => {
    setMemberInfo(params);
    setOpenNewDlg(true);
  };



  const onEditMemberClick = (e, params) => {
    setMemberInfo(params);
    setOpenEditMemberDlg(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataColumns = [
    { field: "id", headerName: "ID", width: 90, hidden: true },
    {
      field: "memberId",
      headerName: "Member Id",
      width: 110,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.memberId}
          </Typography>
        );
      },
    },
    {
      field: "companyId",
      headerName: "Company",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.companyName}
          </Typography>
        );
      },
    },
    {
      field: "firstName",
      headerName: "Name",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.firstName}
          </Typography>
        );
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.lastName}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.email}
          </Typography>
        );
      },
    },

    {
      field: "creationDate",
      headerName: "Created At",
      width: 130,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.creationDate}
          </Typography>
        );
      },
    },
    {
      field: "mobileNumber",
      headerName: "Phone",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.mobileNumber}
          </Typography>
        );
      },
    },
    {
      field: "cardType",
      headerName: "Card Type",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.cardType}
          </Typography>
        );
      },
    },
    {
      field: "cardRequestStatus",
      headerName: "Order Status",
      width: 120,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.cardRequestStatus}
          </Typography>
        );
      },
    },
    {
      field: "enrollmentStatus",
      headerName: "Enroll Status",
      width: 120,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.enrollmentStatus}
          </Typography>
        );
      },
    },
    {
      field: "userStatus",
      headerName: "User status",
      width: 100,
      renderCell: (params) => {
        return (
          <div style={{ minWidth:"4rem",   display:"flex", flexDirection:"column", alignContent:"center", alignItems:"center" }}>
            <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
              {params.row.userStatus ? params.row.userStatus : "ACTIVE"}
            </Typography>
            <Typography sx={{ fontSize: 8, mb: 0, fontWeight:"bold", color:"gray" }} gutterBottom>
              {params.row.oldStatus}
            </Typography>
          </div>

        );
      },
    },
    {
      field: "details",
      headerName: "Details",
      width: 80,
      renderCell: (params) => {
        return (
          <>
            <Button style={{ minWidth: "10px" }}>
              <VisibilityIcon
                onClick={(e) => onViewButtonClick(e, params.row)}
                variant="contained"
              />
            </Button>
            <RenderOnRole roles={["member-fix"]}>
              <Tooltip title="Edit Company/MemberId">
                <Button style={{ minWidth: "10px" }}>
                  <MoveDownIcon
                    onClick={(e) => onEditMemberClick(e, params.row)}
                    variant="contained"
                  />
                </Button>
              </Tooltip>
            </RenderOnRole>
          </>
        );
      },
    },
  ];

  const columns = React.useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        headerName: "Change Location",
        width: 100,
      },
      ...dataColumns,
    ],
    [dataColumns]
  );

  const handleRowSelection = (rows) => {
    setSelection(rows);
  };

  useEffect(() => {}, [select]);
  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "20px" }}>
          <h1>{title}</h1>
        </div>
        {isFetching ? <RefreshingIndicator /> : ""}
      </div>
      <div style={{ padding: "20px" }}>
        <span>Please, to start use the search box </span>
        <SearchIcon color="primary" />
      </div>

      {isLoading || isLoadingDataCount ? (
        <CircularProgress color="inherit" />
      ) : !isError ? (
        <div style={{ height: 450, width: "100%" }}>

          <DataGrid
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            pageSize={10}
            rowsPerPageOptions={[10]}
            columnVisibilityModel={{ id: false }}
            onSelectionModelChange={handleRowSelection}
            selectionModel={select}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 800 },
              },
            }}
            rows={data?.data}
            columns={columns}
            rowCount={dataCount?.data}
            onPageChange={(newPage) => {
              setPageFunction(newPage);
            }}
            paginationMode="server"
            filterMode="server"
            onFilterModelChange={onFilterChange}
            /*sx={{
                      "& .MuiButton-root": {
                        display: "none",
                      }
                    }}*/
            keepNonExistentRowsSelected
          />
          <Snackbar
            open={feedback.open}
            autoHideDuration={6000}
            onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={
                feedback.onClose ? feedback.onClose : handleFeedbackClose
              }
              severity={feedback.type}
              sx={{ width: "100%" }}
            >
              {feedback.message}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <h4>An error ocurred, please try again later...</h4>
      )}
      {openNewDlg ? (
        <MemberDetail
          open={openNewDlg}
          setOpen={setOpenNewDlg}
          dialogTitle="PayAnyDay Info"
          memberInfo={memberInfo}
        />
      ) : (
        ""
      )}
      {openEditMemberDlg ? (
        <Dialog
          open={openEditMemberDlg}
          maxWidth="md"
          fullWidth
          onClose={() => setOpenEditMemberDlg(false)}
        >
          <DialogTitle>Edit Company/Member Id</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpenEditMemberDlg(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <ModifyCompanyMember
              memberInfo={memberInfo}
              handleClose={() => {
                queryClient.invalidateQueries(["members"]);
                setOpenEditMemberDlg(false);
              }}
            />
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
    </Fragment>
  );
}
