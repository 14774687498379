import {Grid,Typography,Divider, Tooltip} from "@mui/material";
import React, { Fragment } from "react";
import { useQuery } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import PaidIcon from '@mui/icons-material/Paid';
import BusinessIcon from '@mui/icons-material/Business';
import NumbersIcon from '@mui/icons-material/Numbers';
import ArticleIcon from '@mui/icons-material/Article';

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function OnboardingFileDetail(props) {
  const {onboardingInfo} = props;
  
  const columns = [
    { field: "id", headerName: "ID", width: 90 , hidden: true  },
    { field: "email", headerName: "Email", width: 190,
    renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
          {params.row.email}
       </Typography>)
      } 
    },    
    { field: "firstName", headerName: "Firts name", width: 120},
    { field: "lastName", headerName: "Last name", width: 120},
    { field: "phone", headerName: "Phone", width: 90,
    renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
          {params.row.phone}
       </Typography>)
      } 
    },
    { field: "ssn", headerName: "SSN", width: 90,
    renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
          {params.row.ssn}
       </Typography>)
      } 
    },
    { field: "lastFour", headerName: "Last four", width: 70},
    { field: "status", headerName: "Status", width: 100},
    { field: "error", headerName: "Error", width: 200,
        renderCell: (params) => {
            return (<Tooltip title={params.row.error}><Typography sx={{   fontSize: 10, mb: 0 }}   gutterBottom>
              {params.row.error}
           </Typography></Tooltip>)
        } 
    },
    { field: "updateDate", headerName: "Update Date", width: 150,
    renderCell: (params) => {
        return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
          {params.row.updateDate}
       </Typography>)
    } 
},

  ];

  async function fetchOnboardingInfoDetail() {
    if (onboardingInfo.id===undefined) return null;
    var url = window.API_URL + `/onboarding/bulk/`+onboardingInfo.id;
    const response = await HttpService.getAxiosClient().get(url);
    return response;
  }

  const {
    data,
    isError,
    isLoading,
  } = useQuery(["onboardingFileDetail", onboardingInfo.id], fetchOnboardingInfoDetail);

  return (
    <Fragment>
        {isLoading ? (
        <h4>Loading...</h4>
        ) : !isError ? (
        <Fragment>
          <Grid style={{marginLeft:"20px", marginRight:"10px", marginTop:0}} container spacing={1.5}>
            <Grid container spacing={1.5}>
            <Grid item xs={1.5}>
              <Typography>Company:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography><BusinessIcon style={{ paddingRight:"5px" }}/>{onboardingInfo.employerName}</Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography>Filename:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography><ArticleIcon style={{ paddingRight:"5px" }}/>{onboardingInfo && onboardingInfo.filename}</Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography>Status:</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography><PaidIcon style={{ paddingRight:"5px" }}/>{onboardingInfo && onboardingInfo.processStatus}</Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography>Number Of Records:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography><NumbersIcon style={{ paddingRight:"5px" }}/>{onboardingInfo && onboardingInfo.numOfRecords}</Typography>
            </Grid>
            </Grid>
          <br />
          <br />
          <Divider style={{margin:"15px"}} />
          </Grid>
          <div style={{ height: 550}}>
            <DataGrid
                columnVisibilityModel={{id: false}}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={data?.data}
                columns={columns}
                getRowHeight={() => 35}
                density="compact"  
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                toolbar: {
                    showQuickFilter: true, 
                    quickFilterProps: { debounceMs: 500 },
                },
                }}
            />
          </div>
        </Fragment>
        ) : (
        <h4>An error ocurred, please try again later...</h4>
        )}
    </Fragment>
  );
}
