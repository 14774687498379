import React from 'react';
import { Card, CardContent, Grid, Typography } from "@mui/material";


export default function CardStatsElement(props) {
    const {isLoadingStats, isErrorStats, data, text, type, md=3, fontSize=40, minHeight, minWidth=275, headerTypograhy='h6'} = props;
    
    return (

        <Grid item xs={12} sm={6} md={md} p={0.5}>
          <Card sx={{ minWidth: {minWidth}, minHeight:{minHeight}, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <CardContent>
              <Typography variant={headerTypograhy} color="text.secondary">
                {text}
              </Typography>
              <Typography sx={{   fontSize: (type==="amount") ? {fontSize} : 60, mb: 0, textAlign: (type==="amount") ? 'right' : 'left'} } color="text.secondary">
                {isLoadingStats
                  ? "..."
                  : !isErrorStats
                  ? ((type==="amount") ? '$ '+ Number(data).toLocaleString("en-US", { minimumFractionDigits: 2}) : data)
                  : "?"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

    );
}