import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Fragment, useState} from  'react';
import TimeAttendance from "../components/TimeAttendance";
import PayPeriods from "../components/PayPeriods";

export default function QueryTimeAttendance(props) {

  const [page, setPage] = useState(0);
  const [filterString, setFilterString] = useState((props && props.filterString) ? props.filterString : "");



  async function fetchHours(page, filterString) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/hours/all?pageSize=10&page=`+page +`&filter=`+filterString
    );
    return response;
  }

  async function fetchHoursCount(filterString) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/hours/all/count?filter=`+filterString
    );
    return response;
  }

  async function fetchPayPeriods() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/periods`
    );
    return response;
  }


  const {
    data: dataPayPeriods,
    isLoading: isLoadingPayPeriods,
    isError: isErrorPayPeriods,
    refetch: refetchPayPeriods,
  } = useQuery(["payPeriods"], fetchPayPeriods);


  const { 
    data, 
    isFetching, 
    isLoading, 
    isError, 
    refetch 
  } = useQuery(["hours",page, filterString], () => fetchHours(page, filterString), {
    keepPreviousData: true
  });

  const { 
    data : dataCount, 
    isFetching : isFetchingDataCount, 
    isLoading : isLoadingDataCount, 
    isError: isErrorDataCount, 
    refetch: refetchDataCount 
  } = useQuery(["hoursCount", filterString], () => fetchHoursCount(filterString), {
    keepPreviousData: true
  });

  return (
    <Box sx={{margin: '0'}}>
      { (props && props.hidePeriods) ? "":
        (   
          <Fragment>

            <br />
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>Show Pay Periods</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PayPeriods 
                  isLoading={isLoadingPayPeriods} isError={isErrorPayPeriods} data={dataPayPeriods} 
                />
              </AccordionDetails>
            </Accordion>
            <br />
            <br />
          </Fragment>   


        )  
      }




      <TimeAttendance 
        isFetching={isFetching} 
        isLoading={isLoading} 
        isError={isError} 
        data={data} 
        refetchFunction={refetch} 
        setPageFunction={setPage}
        setFilterStringFunction={setFilterString}
        dataCount={dataCount} 
        isFetchingDataCount={isFetchingDataCount}
        isLoadingDataCount={isLoadingDataCount}
        isErrorDataCount={isErrorDataCount}
        refetchDataCount={refetchDataCount} 
        title={(props && props.hideTitle) ? "":"Time And Attendance"}
      />
    </Box>
  );
}
