import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import HttpService from "../services/HttpService";
import { useMutation, useQuery } from "@tanstack/react-query";
import CircularProgress from '@mui/material/CircularProgress';
import RenderOnRole from './RenderOnRole';
import { Alert, Snackbar } from '@mui/material';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, personName, theme) {
    const isBalanceUnload = name.match(/^-?\d+/) ? name.match(/^-?\d+/)[0] === "-1" : false;
    if (isBalanceUnload){
        return {
            color: theme.palette.error.main,
            fontWeight: 'bold',
        };
    }
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }


export default function GroupMultiSelect(props) {

    const [feedback, setFeedback] = React.useState({
        open: false,
        type: "error",
        message: "",
      });

    const handleFeedbackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setFeedback({ open: false, type: "error", message: "" });
    };

    async function fetchGroups() {
        const response = await HttpService.getAxiosClient().get(
            window.API_URL + `/companyGroups/list`
        );
        return response;
    }

    async function fetchDescriptorData() {
        const response = await HttpService.getAxiosClient().get(
          window.API_URL + `/employer/descriptors?idEmployer=`+props.employerId
        );
        return response;
      }

    const {
        data: dataGroups,
        isLoading: isLoadingGroups,
        isError: isErrorGroups,
    } = useQuery({ queryKey:["groups"], queryFn:fetchGroups, refetchOnWindowFocus:false });

    const { data: descriptorData, isLoading: isLoadingDescriptors } = useQuery(
        ["descriptors",props.idEmployer],
        () => fetchDescriptorData()
      );
  
    const [selectedElement, setSelectedElement] = React.useState('');
    const [amountValue, setAmountValue] = React.useState('0');

    const handleChangeSingle = (event) => {
        setSelectedElement(event.target.value);
    };

    const theme = useTheme();

    const [elements, setElements] = React.useState([]);
    const [disableGroupOptions, setDisableGroupOptions] = React.useState(true);

    const [value, setValue] = React.useState('all');

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        if (event.target.value==="all"){
            setElements([]);
            setDisableGroupOptions(true);
        } else {
            setDisableGroupOptions(false);
        }
    };
    
    const handleChange = (event) => {
        const {
        target: { value },    
        } = event;
        setElements(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
        props.setOpen(false);
        }
    };

    const generateTopUpTemplate = useMutation(
        (params) => {
          return HttpService.getAxiosClient().post(
            window.API_URL + `/payfile/template/topup/export?idEmployer=${params.employerId}`,
            {
            groups: params.elements,
            idDescriptor: params.selectedElement,
            } 
          );
        },
        {
          onSuccess: () => {
            setFeedback({
                open: true,
                type: "success",
                message: "The report is being generated. The employer administator will receive an email when it's ready.",
                });
          },
          onError: (err) => {
                if (err.response && err.response.status && err.response.status === 429){
                    setFeedback({
                        open: true,
                        type: "warning",
                        message: "The report is still in progress. Please wait until it's finished, the employer administrator will receive an email when it's ready.",
                        });
                }
            }
        }
      );

    const handleConfirm = (event, reason) => {
        if (amountValue === "TOPUP"){
            generateTopUpTemplate.mutate({
                elements: elements,
                selectedElement: selectedElement,
                employerId: props.employerId,
            });
        }else{
            props.action.mutate({
                elements: elements,
                selectedElement: selectedElement,
                employerId: props.employerId,
            });
        }
    };

    
    return (      
        <div>
        <Snackbar
            open={feedback.open}
            autoHideDuration={6000}
            onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
            <Alert
                onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                severity={feedback.type}
                sx={{ width: "100%" }}
            >
                {feedback.message}
            </Alert>
        </Snackbar>
        <Dialog disableEscapeKeyDown open={props.open} onClose={handleClose}>
            <DialogTitle>Set up Template Generation</DialogTitle>
            <DialogContent>
                {isLoadingGroups || isLoadingDescriptors ?
                    <CircularProgress color="inherit" />
                : isErrorGroups ? 
                    <h4>There was an error getting the info</h4> 
                :
                (
                <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <div>
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">Choose the desired option to generate the template file</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="all" control={<Radio />} label="All Members" />
                                <FormControlLabel value="groups" control={<Radio />} label="By Group" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-chip-label">Groups</InputLabel>
                            <Select
                            disabled={disableGroupOptions}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={elements}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Groups" />}
                            renderValue={(selected, other) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {
                                    selected.map((key) => {
                                        return <Chip key={key} label={dataGroups?.data.find((item) => item.id === key).name} />;
                                })}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                            >
                            {dataGroups?.data.map((option) => (
                                <MenuItem
                                key={option.id}
                                value={option.id}
                                style={getStyles(option.name, elements, theme)}
                                >
                                {option.name}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>


                    </div>
                    <div>
                        <FormControl key="descriptorsSingleSelect" sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-chip-label">Description</InputLabel>
                            <Select
                                labelId="demo-single-select-label"
                                id="demo-single-select"
                                value={selectedElement}
                                onChange={handleChangeSingle}
                                input={<OutlinedInput id="select-single" label="Description" />}
                                MenuProps={MenuProps}
                            >
                            {
                                descriptorData?.data?.sort((a, b) => {
                                    const idA = Number(a.split(' - ')[0]);
                                    const idB = Number(b.split(' - ')[0]);
                                    if (idA === -1) return 1;
                                    if (idB === -1) return -1;
                                    return idA - idB;
                                }).map((descriptor) => {
                                    const [id, description] = descriptor.split(' - ');
                                    return (
                                    <MenuItem
                                        key={id}
                                        name={description}
                                        value={id}
                                        style={getStyles(descriptor, selectedElement, theme)}
                                    >
                                        {descriptor}
                                    </MenuItem>
                                )})
                            }
                            </Select>

                        </FormControl>

                    </div>
                    <RenderOnRole roles={['pf-template-topup']}>
                    <div>
                        <FormControl  sx={{ m: 1, width: 300 }}>
                            <FormLabel>Amount</FormLabel>
                            <RadioGroup
                                defaultChecked="0"
                                value={amountValue}
                                onChange={(event) => setAmountValue(event.target.value)}
                            >
                                <FormControlLabel value="0" control={<Radio />} label="Manual entry" />
                                <FormControlLabel value="TOPUP" control={<Radio />} label="TopUp to monthly limit" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    </RenderOnRole>
                </Box>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm}>Generate</Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}