import {
  useMemo,
  useState,
  useRef,
  useEffect,
  Fragment,
  forwardRef,
} from "react";
import {
  useParams,
} from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import paycardImg from "./../images/paycard.lowres.svg";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { Container } from "@mui/system";
import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Snackbar } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import { useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import { hasValue } from "../util/string";
import FmdBadIcon from '@mui/icons-material/FmdBad';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = "AIzaSyBbDOjR9D0llQjIpTNV6BwxgivcmSje8hc"; //GSO_apikey

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function AssignPaycard() {
  const navigate = useNavigate();
  const { bundleDetailId } = useParams();
  const assignPaycardMutation = useMutation(
    (newPaycard) => {
      if (hideSsn) {
        newPaycard.ssn = ssn;
      }
      newPaycard.ssn = newPaycard.ssn.replace(/-/g,"")
      newPaycard.ssn = newPaycard.ssn.replace(/ /g,"")
      if (bundleDetailId!==null && bundleDetailId!==undefined){
        newPaycard.cardIdentifier = bundleDetailId;
      }
      if (hideMemberCode) {
        if (memberCode!=="") {  
          newPaycard.employeeNo = memberCode;
        } else {
          newPaycard.employeeNo = newPaycard.mobilePhone;
        }
      }
      if (hidePhone) {
        if (mobilePhone!=="") {  
          newPaycard.mobilePhone = mobilePhone;
        } 
      }
      if (hideDateOfBirth) {
        if (dateOfBirth!=="") {  
          newPaycard.dob = dateOfBirth;
        } 
      }
      newPaycard.dob = new Date(newPaycard.dob).toISOString().split("T")[0];

      if (useLastFourOfSSN) {
        newPaycard.ssn = newPaycard.ssn.padStart(9, '0');
      }

      if (noEmailChecked) { 
        newPaycard.email = "noemail"
      }

      return (
      HttpService.getAxiosClient().post(
        window.API_URL + `/paycards`,
        newPaycard
      ))
    },
    {
      onSuccess: () => {
        setFeedback({
          open: true,
          type: "success",
          message: "paycard assigned succesfully",
          onClose: () => navigate("/"),
        });
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err?.response?.data ? err?.response?.data : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  async function fetchPaycardDetail() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/paycards/preassign/`+bundleDetailId
    );
    setupFormWithServerData(response);
    return response;
  }

  const {
    data: dataPaycardDetail,
    isError: isErrorPaycardDetail,
    isFetching: isFetchingPayCardDetail,
  } = useQuery({ queryKey: ["paycardDetail"], queryFn: fetchPaycardDetail, refetchOnWindowFocus:false });

  async function fetchPaycardAsReplacementAttribute() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer/attributes/PAYCARD_AS_REPLACEMENT`
    );
    return response;
  }

  const { 
    data : dataPayCardAsReplacement, 
    isFetching : isFetchingDataPayCardAsReplacement, 
    isError: isErrorDataPayCardAsReplacement, 
    //refetch: refetchDataPayCardAsReplacement 
  } = useQuery(["dataPayCardAsReplacement"], () => fetchPaycardAsReplacementAttribute(), {
    keepPreviousData: true
  });





  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const [selectedLanguage  , setSelectedLanguage] = useState("en");
  const handleLanguageChange = (event,idLanguage) => {
    setSelectedLanguage(idLanguage.props.value);
    formik.values.idLanguage = idLanguage.props.value;
  }



  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");

  // to setup visible fields
  const [ssn, setSsn] = useState("");
  const [memberCode, setMemberCode] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [hideSsn, setHideSsn] = useState(false);
  const [hideMemberCode, setHideMemberCode] = useState(false);
  const [hideAddress, setHideAddress] = useState(false);
  const [hidePhone, setHidePhone] = useState(false);
  const [hideDateOfBirth, setHideDateOfBirth] = useState(false);
  const [useLastFourOfSSN, setUseLastFourOfSSN] = useState(false);
  const [allowNoEmailCheck, setAllowNoEmailCheck] = useState(false);

  const US_STATES = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];

  const [validationSchemaObject, setValidationSchemaObject] =
      useState({
      cardNumber: yup
        .string()
        .matches(
          /^[0-9][0-9][0-9][0-9]$/,
          "Must be the last 4 digits of the paycard"
        )
        .required("Paycard 4 last digits are required"),
      firstName: yup
        .string("Enter the member First name")
        .matches(/^\S*$/, "First name should not contain spaces")
        .required("First name is required"),
      middle: yup.string("Enter the member middle"),
      lastName: yup
        .string("Enter the member Last name")
        .matches(/^\S*$/, "Last name should not contain spaces")
        .required("Last name is required"),
      homePhone: yup.string("Enter the member home phone"),
      idLanguage: yup
        .string("Select the prefered language")
        .required("Prefered Language is required"),
      addressLine1: yup
        .string("Enter the member Address Line 1")
        .required("Address line 1 is required"),
      addressLine2: yup.string("Enter the member Address Line 2"),
      city: yup.string("Enter the member city").required("City is required"),
      state: yup.string("Enter the member state")
      .length(2, "State should be exactly 2 characters")
      .oneOf(US_STATES, "State is not valid")
      .required("State is required"),
      zipCode: yup
        .string("Enter the member zip code")
        .required("zip is required"),
    });

  const [validationSchema, setValidationSchema] = useState(yup.object(validationSchemaObject));

  const [noEmailChecked, setNoEmailChecked] = useState(false);
  const handleNoEmailCheckChange = (event,checked) => {
    setNoEmailChecked(checked);

    let finalValidationSchema = {...validationSchemaObject};

    if (!checked) {
      const emailValidation = yup
      .string("Enter the member email")
      .email("Enter a valid email")
      .required("Email is required");
      finalValidationSchema = {...finalValidationSchema, email : emailValidation};
    } 

    setValidationSchema(yup.object(finalValidationSchema));

  }

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });


  const formik = useFormik({
    initialValues: {
      cardNumber: dataPaycardDetail?.cardNumber,
      email: "",
      employeeNo: "",
      firstName: "",
      middle: "",
      lastName: "",
      mobilePhone: "",
      homePhone: "",
      dob: "",
      ssn: "",
      idLanguage: selectedLanguage,
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      assignPaycardMutation.mutate({ ...values });
    },
  });

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  const fetchPlaceDetail = useMemo(
    () => (request, callback) => {
      if (request && request.place_id) {
        const getInfo = (address_components, key) => {
          for (let i = 0; i < address_components.length; i++) {
            if (address_components[i].types.includes(key))
              return address_components[i].short_name
                ? address_components[i].short_name
                : "";
          }
          return "";
        };

        const service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails({ placeId: request.place_id }, syncWithForm);

        function syncWithForm(place, status) {
          setFormikSynced(
            "addressLine1",
            getInfo(place.address_components, "street_number") +
              " " +
              getInfo(place.address_components, "route"),
            setAddressLine1
          );
          setFormikSynced(
            "addressLine2",
            getInfo(place.address_components, "subpremise"),
            setAddressLine2
          );
          setFormikSynced(
            "city",
            getInfo(place.address_components, "locality"),
            setCity
          );
          setFormikSynced(
            "state",
            getInfo(place.address_components, "administrative_area_level_1"),
            setState
          );
          setFormikSynced(
            "zipCode",
            getInfo(place.address_components, "postal_code"),
            setZipCode
          );
        }
      }
    },
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: {
          country: ["us"],
        },
        fields: ["description", "place_id"],
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    fetchPlaceDetail(value);
  }, [value, fetchPlaceDetail]);

  const setFormikSynced = (key, value, callback) => {
    formik.setFieldValue(key, value, false);
    callback(value);
  };

  const wrapperFormikHandleChanged = (event) => {
    formik.handleChange(event);
    let callback = () => {};
    if (event.target.id === "addressLine1") callback = setAddressLine1;
    if (event.target.id === "addressLine2") callback = setAddressLine2;
    if (event.target.id === "city") callback = setCity;
    if (event.target.id === "state") callback = setState;
    if (event.target.id === "zipCode") callback = setZipCode;
    setFormikSynced(event.target.id, event.target.value, callback);
  };


  const setupFormWithServerData =  (dataPaycardDetail) => {
    formik.resetForm();

    const { data } = dataPaycardDetail ;
    const { cardNumber,
            firstName, 
            lastName, 
            addressLine1: addressLine1FromServer, 
            addressLine2: addressLine2FromServer, 
            city : cityFromServer, 
            state: stateFromServer, 
            zipCode: zipCodeFromServer, 
            ssn: ssnFromServer,
            employeeNo: employeeNoFromServer,
            mobilePhone: mobilePhoneFromServer,
            dob: dobFromServer,   
          } = data.payCardDetail ;    

    if ( dataPaycardDetail!== undefined ) {
      if (hasValue(cardNumber)) {
        formik.values.cardNumber = cardNumber;
      }
  
      if (hasValue(firstName)) {
        formik.values.firstName = firstName;
      }
    
      if (hasValue(lastName)) {
        formik.values.lastName = lastName;
      }
  
      if (hasValue(addressLine1FromServer)) {
        setAddressLine1(addressLine1FromServer);
        setFormikSynced(
          "addressLine1",
          addressLine1FromServer,
          setAddressLine1,
        );
      }
  
      if (hasValue(addressLine2FromServer)) {
        setAddressLine2(addressLine2FromServer);
        setFormikSynced(
          "addressLine2", 
          addressLine2FromServer,
          setAddressLine2,
        );
      }
  
      if (hasValue(cityFromServer)) {
        setCity(cityFromServer);
        setFormikSynced(
          "city",
          cityFromServer,
          setCity,
        );
      }
  
      if (hasValue(stateFromServer)) {
        setState(stateFromServer);
        setFormikSynced(
          "state",
          stateFromServer,
          setState,
        );
      }
  
      if (hasValue(zipCodeFromServer)) {
        setZipCode(zipCodeFromServer);
        setFormikSynced(
          "zipCode",
          zipCodeFromServer,
          setZipCode,
        );
      }
  
      const { hideSSN,
        usePhoneAsMemberId, 
        useProxyAsMemberId,
        hideAddress,
        useLastFourOfSSN,
        hidePhone,
        hideDOB,
        allowNoEmail
      } = data?.payCardAssignProperties || {};
  
      let finalValidationSchema = {...validationSchemaObject};
  
      if (hasValue(hideSSN) && hideSSN==="true"){
          setHideSsn(true);
          setSsn(ssnFromServer);
      } else {
          if (hasValue(useLastFourOfSSN) && useLastFourOfSSN==="true") {
            setUseLastFourOfSSN(true);
            const ssnValidation = yup
            .string()
            .matches(
              /^(\d{4})*$/,
              "Must be the last four of the SSN"
            )
            .required("SSN is required");
            finalValidationSchema = {...finalValidationSchema, ssn : ssnValidation};
          } else {
            const ssnValidation = yup
            .string()
            .matches(
              /^((?!666|000)[0-8][0-9\_]{2}(\-)?(?!00)[0-9\_]{2}(\-)?(?!0000)[0-9\_]{4})|(9\d{2})([ \-]?)(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])([ \-]?)(\d{4})*$/,
              "Must be a valid SSN or ITIN"
            )
            .required("SSN is required");
            finalValidationSchema = {...finalValidationSchema, ssn : ssnValidation};
          }

      }

      if (hasValue(hidePhone) && hidePhone==="true"){
        setHidePhone(true);
        setMobilePhone(mobilePhoneFromServer);
      } else {
        const phoneValidation = yup
        .string("Enter the member mobile phone")
        .matches(
          /^^\(?[2-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/,
          "Enter a valid phone number"
        )
        .required("Mobile phone is required");
        finalValidationSchema = {...finalValidationSchema, mobilePhone : phoneValidation};
      }

      if (hasValue(hideDOB) && hideDOB==="true"){
        setHideDateOfBirth(true);
        setDateOfBirth(dobFromServer);
      } else {
        const dobValidation = yup.date().required("Birthday is required");
        finalValidationSchema = {...finalValidationSchema, dob : dobValidation};
      }

      if (hasValue(hideAddress) && hideAddress==="true"){
        setHideAddress(true);
      }
  
      if (hasValue(useProxyAsMemberId) && useProxyAsMemberId==="true"){
        setMemberCode(employeeNoFromServer);
      } 

      if ( (hasValue(useProxyAsMemberId) && useProxyAsMemberId==="true") ||  (hasValue(usePhoneAsMemberId) && usePhoneAsMemberId==="true")) {
        setHideMemberCode(true);
      } else  {
        const employeeValidation = yup
        .string("Enter the employee code")
        .required("Employee code is required");
        finalValidationSchema = {...finalValidationSchema, employeeNo : employeeValidation};
      }
      setValidationSchema(yup.object(finalValidationSchema));

      if ( hasValue(allowNoEmail) && allowNoEmail==="true") {
        setAllowNoEmailCheck(true);
      }

    }

  }


  const renderAddressInfo = () => {
     return (
      <div style={{ display: hideAddress ? "none" : "block" }}>
        <h4> Address information </h4>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              id="google-control"
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
              }
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Add a location" fullWidth />
              )}
              renderOption={(props, option) => {
                const matches =
                  option.structured_formatting
                    .main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [
                    match.offset,
                    match.offset + match.length,
                  ])
                );

                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Box
                          component={LocationOnIcon}
                          sx={{ color: "text.secondary", mr: 2 }}
                        />
                      </Grid>
                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span
                            key={index}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}

                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                    <div id="map"></div>
                  </li>
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              id="addressLine1"
              label="Address line 1"
              value={addressLine1}
              onChange={wrapperFormikHandleChanged}
              error={
                formik.touched.addressLine1 &&
                Boolean(formik.errors.addressLine1)
              }
              helperText={
                formik.touched.addressLine1 && formik.errors.addressLine1
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              id="addressLine2"
              label="Address line 2"
              value={addressLine2}
              onChange={wrapperFormikHandleChanged}
              error={
                formik.touched.addressLine2 &&
                Boolean(formik.errors.addressLine2)
              }
              helperText={
                formik.touched.addressLine2 && formik.errors.addressLine2
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              id="city"
              label="City"
              value={city}
              onChange={wrapperFormikHandleChanged}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              id="state"
              label="State"
              value={state}
              onChange={wrapperFormikHandleChanged}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              fullWidth
              id="zipCode"
              label="Zip Code"
              value={zipCode}
              onChange={wrapperFormikHandleChanged}
              error={
                formik.touched.zipCode && Boolean(formik.errors.zipCode)
              }
              helperText={formik.touched.zipCode && formik.errors.zipCode}
            />
          </Grid>
        </Grid>
      </div>
     );
  }



  return (
    <Fragment>
      <br />
      <Container maxWidth="lg">
      {isFetchingPayCardDetail || isFetchingDataPayCardAsReplacement  ?
            <CircularProgress color="inherit" />
          : isErrorPaycardDetail || isErrorDataPayCardAsReplacement? 
            <h4>There was an error getting the info</h4> 
            :
        (<Grid container spacing={1.5}>
          <Grid item xs={12} md={6}>
            <br />
            <br />
            <br />
            <br />
            <img src={paycardImg} alt="paycard" width="90%" loading="lazy" />
            {/* <img decoding="async" width="600" height="600" alt="PayCard mockup transparent background" data-srcset="https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background.png?lossy=1&amp;strip=1&amp;webp=1 1000w, https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background-300x300.png?lossy=1&amp;strip=1&amp;webp=1 300w, https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background-150x150.png?lossy=1&amp;strip=1&amp;webp=1 150w, https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background-768x768.png?lossy=1&amp;strip=1&amp;webp=1 768w" data-src="https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background.png?lossy=1&amp;strip=1&amp;webp=1" data-sizes="(max-width: 1000px) 100vw, 1000px" class="attachment-full size-full wp-image-9114 lazyloaded" src="https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background.png?lossy=1&amp;strip=1&amp;webp=1" sizes="(max-width: 1000px) 100vw, 1000px" srcset="https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background.png?lossy=1&amp;strip=1&amp;webp=1 1000w, https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background-300x300.png?lossy=1&amp;strip=1&amp;webp=1 300w, https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background-150x150.png?lossy=1&amp;strip=1&amp;webp=1 150w, https://b3212019.smushcdn.com/3212019/wp-content/uploads/2023/06/PayCard-mockup-transparent-background-768x768.png?lossy=1&amp;strip=1&amp;webp=1 768w"></img>*/}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={formik.handleSubmit}
            >
              <h1> Assign paycard to member </h1>
              <br />
              <div style={{ display: dataPayCardAsReplacement?.data?.value==="true"? "none":"initial"}}>
                <h4> Paycard information </h4>
                <TextField
                  size="small"
                  fullWidth
                  id="cardNumber"
                  label="Paycard last 4 numbers"
                  variant="outlined"
                  required
                  disabled
                  value={formik.values.cardNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cardNumber && Boolean(formik.errors.cardNumber)
                  }
                  helperText={
                    formik.touched.cardNumber && formik.errors.cardNumber
                  }
                />
                <br />
                <br />

              </div>

              <h4> Member/Worker information </h4>
              <Grid container spacing={1.5}>
                <Grid item xs={12}>
                      <Box component="span" sx={{ color:"secondary", p: 1, border: '1px dashed grey' }} style={{ display:noEmailChecked ? "block" : "none" }} >
                      <FmdBadIcon color="secondary"  />
                      <Typography fontSize={14} >
                      
                      The members without email won't have access to EWA, Send Money nor Bill Pay
                      
                      </Typography>
                      </Box>


                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    fullWidth
                    id="email"
                    type="email"
                    label={ noEmailChecked === true ? "Member has no email" :"Verified email"}
                    variant="outlined"
                    required={!noEmailChecked}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    disabled={noEmailChecked}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={3}>
                <FormControlLabel style={{ display: allowNoEmailCheck ? "block": "none"}} onChange={handleNoEmailCheckChange} control={<Checkbox />} label="No email" />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    style={{ display: hideMemberCode ? "none" : "block"}}
                    size="small"
                    fullWidth
                    id="employeeNo"
                    type="text"
                    label="Member code"
                    variant="outlined"
                    required
                    value={formik.values.employeeNo}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.employeeNo &&
                      Boolean(formik.errors.employeeNo)
                    }
                    helperText={
                      formik.touched.employeeNo && formik.errors.employeeNo
                    }
                  />
                </Grid>

                <Grid item xs={5}>
                  <TextField
                    size="small"
                    fullWidth
                    id="firstName"
                    type="text"
                    label="First Name"
                    variant="outlined"
                    required
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    fullWidth
                    id="middle"
                    type="text"
                    label="Middle Initial"
                    variant="outlined"
                    value={formik.values.middle}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.middle && Boolean(formik.errors.middle)
                    }
                    helperText={formik.touched.middle && formik.errors.middle}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    fullWidth
                    id="lastName"
                    type="text"
                    label="Last Name"
                    variant="outlined"
                    required
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
                <Grid item xs={6} style={{ display: hidePhone ? "none" : "block"}}>
                  <TextField
                    size="small"
                    fullWidth
                    id="mobilePhone"
                    type="text"
                    label="Mobile Phone"
                    variant="outlined"
                    required
                    value={formik.values.mobilePhone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mobilePhone &&
                      Boolean(formik.errors.mobilePhone)
                    }
                    helperText={
                      formik.touched.mobilePhone && formik.errors.mobilePhone
                    }
                  />
                </Grid>
                <Grid item xs={6} style={{ display: hidePhone ? "none" : "block"}}>
                  <TextField
                    size="small"
                    fullWidth
                    id="homePhone"
                    type="text"
                    label="Home Phone"
                    variant="outlined"
                    value={formik.values.homePhone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.homePhone &&
                      Boolean(formik.errors.homePhone)
                    }
                    helperText={
                      formik.touched.homePhone && formik.errors.homePhone
                    }
                  />
                </Grid>
                <Grid item xs={6} style={{ display: hideDateOfBirth ? "none" : "block"}} >
                  <TextField
                    size="small"
                    fullWidth
                    id="dob"
                    type="date"
                    label="DOB"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                    helperText={formik.touched.dob && formik.errors.dob}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    style={{ display: hideSsn ? "none" : "block"}}
                    size="small"
                    fullWidth
                    id="ssn"
                    type="text"
                    label="SSN or ITIN"
                    variant="outlined"
                    value={formik.values.ssn}
                    onChange={formik.handleChange}
                    error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                    helperText={formik.touched.ssn && formik.errors.ssn}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid item xs={10}>
                  <FormControl
                    error={
                      formik.touched.idLocation &&
                      Boolean(formik.errors.idLocation)
                    }
                  > 
                    <InputLabel id="idLanguage">Language</InputLabel>
                    <Select style={{ height: "40px" , width:"200px" }}
                        labelId="idLanguage"
                        id="simple-select"
                        label="Language"
                        onChange={handleLanguageChange}
                        value={formik.values.idLanguage}
                    >   
                        <MenuItem key="en" value="en">English</MenuItem>
                        <MenuItem key="es" value="es">Spanish</MenuItem>
                    </Select>
                    <FormHelperText>{
                          formik.touched.idLanguage && formik.errors.idLanguage
                        }</FormHelperText>
                  </FormControl>
              </Grid>
              <br/>
                {renderAddressInfo()}
              <br />
              <br />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={assignPaycardMutation.isLoading || feedback.open}
              >
                {!assignPaycardMutation.isLoading ? "Assign" : "Processing..."}
              </Button>
            </Box>
          </Grid>
        </Grid>)}
        <Snackbar
          open={feedback.open}
          autoHideDuration={6000}
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
            severity={feedback.type}
            sx={{ width: "100%" }}
          >
            {feedback.message}
          </Alert>
        </Snackbar>
      </Container>
    </Fragment>
  );
}
