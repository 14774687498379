import HttpService from "../services/HttpService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { Fragment, useState } from "react";
import RefreshingIndicator from "../components/RefreshingIndicator";
import { DataGrid } from "@mui/x-data-grid";
import AddWhitelist from "../components/AddWhitelist";

export default function ManageWhitelist() {
  const queryClient = useQueryClient();
  const [addWhitelistDlg, setAddWhitelist] = useState(false);

  const deletehitelistMutation = useMutation(
    (data) => {
      return HttpService.getAxiosClient().delete(
        window.API_URL + `/whitelist/${data.id}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["whitelist"]);
      },
      onError: (err) => {
        console.log("An error occurred. " + err.response.data);
      },
    }
  );

  const onAddWhitelist = () => setAddWhitelist(true);

  const queryConst = {
    main: {
      name: "whitelist",
      url: `/whitelist`,
    },
  };

  const dataColumns = [
    { field: "id", headerName: "idWhitelist", width: 90, hide: false },
    { field: "employerName", headerName: "Company", width: 180 },
    { field: "customerSender", headerName: "Sender", width: 250 },
    { field: "recipientUsername", headerName: "Recipient user", width: 250 },
    { field: "recipientName", headerName: "Recipient name", width: 250 },
    { field: "status", headerName: "Status", width: 90 },
    {
      field: "detail",
      headerAlign: "right",
      width: 150,
      headerName: "Actions",
      align: "right",
      renderCell: (params) => (
        <div>
          <Tooltip title="Delete whitelist element">
            <Button style={{ minWidth: "10px" }} disabled={deletehitelistMutation.isLoading}>
              <DeleteIcon
                onClick={() => {
                  deletehitelistMutation.mutate({ id: params.row.id });
                }}
                variant="contained"
              />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  async function fetchMainData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `${queryConst.main.url}`
    );
    return response;
  }

  const { data, isFetching, isLoading, isError } = useQuery(
    [queryConst.main.name],
    () => fetchMainData()
  );

  const addCompanyDlgFunct = (event) => {
    return (
      <Dialog
        open={addWhitelistDlg}
        onClose={() => {}}
        maxWidth="lg"
        fullWidth
      >
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>
              <Typography variant="h4">Add to whitelist</Typography>
            </DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              setAddWhitelist(false);
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <AddWhitelist handleClose={() => setAddWhitelist(false)} />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box>
      <Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "20px" }}>
            <Grid container alignItems="center">
              <Grid item pr={2}>
                <h1>Transfer to Rellevate whitelist</h1>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onAddWhitelist}>
                  Add to whitelist
                </Button>
              </Grid>
            </Grid>
          </div>
          {isFetching ? <RefreshingIndicator /> : ""}
        </div>
        {isLoading ? (
          <CircularProgress color="inherit" />
        ) : !isError ? (
          <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
            <DataGrid
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              pageSize={100}
              rowsPerPageOptions={[100]}
              columnVisibilityModel={{ id: false }}
              rows={data?.data}
              columns={dataColumns}
            />
          </div>
        ) : (
          <h4>An error ocurred, please try again later...</h4>
        )}
        {addWhitelistDlg ? addCompanyDlgFunct() : ""}
      </Fragment>
    </Box>
  );
}
