import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import CloseIcon from "@mui/icons-material/Close";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import AddCompanyGroup from "./AddCompanyGroup";
import { useState } from "react";
import AddMemberToGroup from "./AddMemberToGroup";

export default function ManageGroupMembers(props) {
  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const queryClient = useQueryClient();

  async function fetchGroupMembers() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/companyGroups/${props?.groupInfo?.id}/members`
    );
    return response;
  }

  const deleteGroupMemberMutation = useMutation(
    (values) => {
      return HttpService.getAxiosClient().delete(
        window.API_URL + `/companyGroups/member/${values.id}`
      );
    },
    {
      onSuccess: (dataResponse, value) => {
        queryClient.invalidateQueries(["memberGroups", props?.groupInfo?.id]);
        queryClient.invalidateQueries("companyGroups");
      },
      onError: (error) => {
        setFeedback({
          open: true,
          type: "error",
          message: "Error deleting the attribute",
        });
      },
    }
  );

  const columns = [
    { field: "id", headerName: "id", width: 20, hide: true },
    { field: "memberId", headerName: "Member ID", width: 80 },
    { field: "email", headerName: "Email", width: 350 },
    { field: "name", headerName: "Name", width: 350 },
    {
      field: "action",
      headerName: "Action",
      width: 50,
      renderCell: (params) => (
        <div key={"action." + params.id}>
          <IconButton
            key={"delete." + params.id}
            onClick={() => {
              deleteGroupMemberMutation.mutate({ ...params });
            }}
          >
            <PersonRemoveIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const { data, isLoading } = useQuery(
    ["memberGroups", props?.groupInfo?.id],
    fetchGroupMembers
  );
  const [addMemberDlg, setAddMemberDlg] = useState(false);

  const addMemberDlgFunct = (event) => {
    return (
      <Dialog open={addMemberDlg} fullWidth onClose={() => {}}>
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>
              <Typography>Add Member to Group</Typography>
            </DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              setAddMemberDlg(false);
              queryClient.invalidateQueries([
                "memberGroups",
                props?.groupInfo?.id,
              ]);
              queryClient.invalidateQueries("companyGroups");
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <AddMemberToGroup
            groupInfo={props?.groupInfo}
          />
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        ""
      ) : (
        <Grid container p={0} m={0} spacing={0}>
          <Grid item xs={12} sx={{ margin: "0px 0px 10px 0px" }}>
            <Box display="flex">
              <Typography variant="h5">
                Group: {props?.groupInfo?.name}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setAddMemberDlg(true)}
                sx={{ margin: "0px 10px 0px 10px" }}
              >
                Add Members
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%", height: "70vh" }}>
            <DataGrid
              key={data?.data?.id}
              rows={data?.data}
              columns={columns}
              getRowHeight={() => 25}
              density="compact"
            />
          </Grid>
        </Grid>
      )}
      {addMemberDlg ? addMemberDlgFunct() : ""}
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
