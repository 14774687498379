import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import CircularProgress from '@mui/material/CircularProgress';
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BusinessIcon from '@mui/icons-material/Business';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import SavingsIcon from '@mui/icons-material/Savings';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PayAnyDayAdvances from "./PayAnyDayAdvances";
import PayAnyDayPaybacks from "./PayAnyDayPaybacks";
import QueryTimeAttendance from "../pages/QueryTimeAttendance";
import CardCredits from "./CardCredits";

export default function MemberDetail(props) {
  const [basicInfoData, setBasicInfoData] = useState({});
  const {memberInfo} = props;
  async function fetchAdvancesDetail() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/advances?memberId=`+memberInfo.memberId+`&companyId=`+memberInfo.companyId
    );
    return response;
  }

  async function fetchPaybackDetail() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/payback?memberId=`+memberInfo.memberId+`&companyId=`+memberInfo.companyId
    );
    return response;
  }

  async function fetchCardCredits() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/credits?memberId=`+memberInfo.memberId+`&companyId=`+memberInfo.companyId
    );
    return response;
  }

  async function fetchBasicInfo() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/info?memberId=`+memberInfo.memberId+`&companyId=`+memberInfo.companyId
    );
    return response;
  }

  
  const {
    data: dataAdvancesDetail,
    isError: isErrorAdvancesDetail,
    isFetching: isFetchingAdvancesDetail,
  } = useQuery(["advancesDetail"], fetchAdvancesDetail);


  const {
    data: dataPaybackDetail,
    isError: isErrorPaybackDetail,
    isFetching: isFetchingPaybackDetail,
  } = useQuery(["paybackDetail"], fetchPaybackDetail);


  const {
    data: dataCardCredits,
    isError: isErrorCardCredits,
    isFetching: isFetchingCardCredits,
  } = useQuery(["cardCredits"], fetchCardCredits);

  const {
    data: dataBasicInfo,
    isError: isErrorBasicInfo,
    isFetching: isFetchingBasicInfo,
  } = useQuery(["basicInfo"], fetchBasicInfo);

  useEffect(() => {
    const { data } = dataBasicInfo || { };
    setBasicInfoData(data);
  },[dataBasicInfo])

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
  };


  return (
    <Dialog   maxWidth="100%"
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown={true}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <DialogActions>
          <Button onClick={handleClose} color={"error"}>
            {isFetchingBasicInfo ? <CircularProgress color="inherit" /> : "Close"}
          </Button>
        </DialogActions>
        <DialogTitle >{props.dialogTitle}<AccountCircleIcon  style={{marginLeft:"20px", marginRight:"10px"}}/><span style={{fontSize:"1rem"}} ></span></DialogTitle>

        
        <DialogContent sx={{ width: '95%',  height:'80%' ,flexShrink: 0 }}>
          <br />
          {isFetchingBasicInfo ?
            <CircularProgress color="inherit" />
          : isErrorBasicInfo ? 
            <h4>There was an error getting the info</h4> 
            :
            (<Grid style={{marginLeft:"20px", marginRight:"10px"}} container spacing={1.5}>
              <Grid container spacing={1.5}>
                <Grid item xs={2.5}>
                  <Typography>Name:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><BadgeIcon style={{ paddingRight:"5px" }}/>{memberInfo.firstName+" "+memberInfo.lastName}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography>Email:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><AlternateEmailIcon style={{ paddingRight:"5px" }}/>{memberInfo.email}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography>Member Id:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><FingerprintIcon style={{ paddingRight:"5px" }}/>{memberInfo.memberId}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography>Company:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><BusinessIcon style={{ paddingRight:"5px" }}/>{memberInfo.companyName+" ("+memberInfo.companyId+")"}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography>Period Start Date:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><CalendarMonthIcon style={{ paddingRight:"5px" }}/>{basicInfoData && basicInfoData.periodStartDate}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography>Period End Date:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><CalendarMonthIcon style={{ paddingRight:"5px" }}/>{basicInfoData && basicInfoData.periodEndDate}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                <Typography>PAD Balance:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><PaidIcon style={{ paddingRight:"5px" }}/>{basicInfoData && basicInfoData.balance}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                <Typography>PAD Taken:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><DownloadDoneIcon style={{ paddingRight:"5px" }}/>{basicInfoData && basicInfoData.padTaken}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                <Typography>PAD Accumulated:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><SavingsIcon style={{ paddingRight:"5px" }}/>{basicInfoData && basicInfoData.padGenerated}</Typography>
                </Grid>
                <Grid item xs={2.5}>
                <Typography>Pendig Payback:</Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography><MoneyOffIcon style={{ paddingRight:"5px" }}/>{basicInfoData && basicInfoData.pending}</Typography>
                </Grid>
              </Grid>
              <br />
              <br />
              <Divider style={{margin:"15px"}} />

            </Grid>)
          }
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ width: '100%', flexShrink: 0 }}>PayAnyDay Advances</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PayAnyDayAdvances 
                isLoading={isFetchingAdvancesDetail} isError={isErrorAdvancesDetail} data={dataAdvancesDetail} 
              />
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ width: '100%', flexShrink: 0 }}>PayAnyDay Payback</Typography>
            </AccordionSummary>
            <PayAnyDayPaybacks 
              isLoading={isFetchingPaybackDetail} isError={isErrorPaybackDetail} data={dataPaybackDetail} 
            />
          </Accordion>
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ width: '100%', flexShrink: 0 }}>Time And Attendance</Typography>
            </AccordionSummary>
            <QueryTimeAttendance 
              hidePeriods={true} hideTitle={true} filterString={memberInfo.email}
            />
          </Accordion>
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ width: '100%', flexShrink: 0 }}>Card Credits</Typography>
            </AccordionSummary>
            <CardCredits 
              isLoading={isFetchingCardCredits} isError={isErrorCardCredits} data={dataCardCredits}
            />
          </Accordion>
          <br />

      <br />
        </DialogContent>

      </Box>
    </Dialog>
  );
}
