import React, { Fragment, useEffect, useState, forwardRef} from 'react';
import { DataGrid,  GridToolbar, GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { Snackbar, Typography  } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import RefreshingIndicator from "./RefreshingIndicator"


export default function TimeAttendance(props) {

    const {
            isLoading, 
            isFetching, 
            isError, 
            data, 
            setPageFunction, 
            setFilterStringFunction,
            dataCount,
            isLoadingDataCount,
            title,
        } = props;

    const [select, setSelection] = useState([]);
    

    const onFilterChange= (filter,params) => {
        const filterString = filter.quickFilterValues.join(" ");
        if (filterString===undefined) {
            setFilterStringFunction("");
        }else {
            setFilterStringFunction(filterString);
        }
        return filter;
    }

    const [feedback, setFeedback] = useState({
        open: false,
        type: "error",
        message: "",
      });

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
    });

    const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }
    setFeedback({ open: false, type: "error", message: "" });
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dataColumns = [
        { field: "id", headerName: "ID", width: 90 , hidden: true  },
        { field: "memberId", headerName: "Member Id", width: 110,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.memberId}
                    </Typography>)
                }
        
        },
        { field: "companyId", headerName: "Company", width: 80,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.companyId}
                    </Typography>)
                }
        
        },
        { field: "dateOfWork", headerName: "Date of Work", width: 100, align:'right',
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.dateOfWork}
                    </Typography>)
                }
        }, 

        { field: "periodStartDate", headerName: "Period Start Date", width: 130,             
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.periodStartDate}
                     </Typography>)
                } 
        },
        { field: "periodEndDate", headerName: "Period End Date", width: 130,             
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.periodEndDate}
                     </Typography>)
                } 
        },
        { field: "updateDate", headerName: "Update Date", width: 130, 
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.updateDate}
                    </Typography>)
                } 
        },
        { field: "hoursWorked", headerName: "Hours", width: 90,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.hoursWorked+" ("+params.row.regularHoursRate+") " }
                    </Typography>)
                } 
        },
        { field: "holidayHoursWorked", headerName: "Holidays Hours", width: 90,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.holidayHoursWorked+" ("+params.row.holidayHoursRate+") "}
                    </Typography>)
                } 
        },
        { field: "overtimeHoursWorked", headerName: "Overtime Hours", width: 90,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.overtimeHoursWorked+" ("+params.row.overtimeHoursRate+") "}
                    </Typography>)
                } 
        },
        { field: "sickPaidHoursWorked", headerName: "Sickpaid Hours", width: 90,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.sickPaidHoursWorked+" ("+params.row.sickPaidHoursRate+") "}
                    </Typography>)
                } 
        },
        { field: "email", headerName: "Email", width: 180,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.email}
                    </Typography>)
                }
        }, 
        { field: "firstName", headerName: "Name", width: 100,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.firstName}
                    </Typography>)
                }
        },
        { field: "lastName", headerName: "Last Name", width: 100,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.lastName}
                    </Typography>)
                }
            },
      ];

    const columns = React.useMemo(
    () => [
        { 
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        headerName: "Change Location",
        width: 100,
        },
        ...dataColumns,
    ],
    [dataColumns],
    );


    const handleRowSelection = (rows) => {
            setSelection(rows);
    }


    useEffect(() => {
    }, [select]);
    return (

        <Fragment>
            <div style={{display : "flex"}}>
                <div style={{marginRight:"20px"}}><h1>{title}</h1></div> 
                {isFetching ? (<RefreshingIndicator/>) :""}
            </div>
            
            {isLoading || isLoadingDataCount ? (
                <CircularProgress color="inherit" />
            ) : !isError ? (
            <div style={{ height: 450, width: "100%" }}>
                
                <DataGrid
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    columnVisibilityModel={{id: false}}
                    onSelectionModelChange={handleRowSelection}
                    selectionModel={select}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                    toolbar: {
                        showQuickFilter: true, 
                        quickFilterProps: { debounceMs: 800 },
                    },
                    }}
                    rows={data?.data}
                    columns={columns}
                    rowCount={dataCount?.data}
                    onPageChange={(newPage) => { setPageFunction(newPage);} }
                    paginationMode="server"
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    sx={{
                      "& .MuiButton-root": {
                        display: "none",
                      }
                    }}
                    keepNonExistentRowsSelected
                />
                <Snackbar
                    open={feedback.open}
                    autoHideDuration={6000}
                    onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                    <Alert
                        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
                        severity={feedback.type}
                        sx={{ width: "100%" }}
                    >
                        {feedback.message}
                    </Alert>
                </Snackbar>
            </div>
            ) : (
            <h4>An error ocurred, please try again later...</h4>
            )}
        </Fragment>

    );
}