import {
  AccordionSummary,
  Accordion,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Chip} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardTransactions from "./CardTransactions";
import RefreshingIndicator from "./RefreshingIndicator";
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useQueryClient } from "@tanstack/react-query";



export default function MemberTransactions(props) {
  const {memberInfo} = props;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState("POSTED");

  const queryClient = useQueryClient()


  async function fetchCardTransactions(page, pageSize, statusFilter) {
    const dateTo = new Date();
    const dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() - 90);
    
    const dateToFormatted = dateTo.toISOString().split('T')[0];
    const dateFromFormatted = dateFrom.toISOString().split('T')[0];

    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/member/card-transactions?memberId=`+memberInfo.memberId+`&companyId=`+memberInfo.companyId+`&dateFrom=`+dateFromFormatted+`&dateTo=`+dateToFormatted+`&page=`+page+`&pageSize=`+pageSize+`&status=`+statusFilter
    );
    return response;
  }


  async function fetchCardTransactionsCount(statusFilter) {
    const dateTo = new Date();
    const dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() - 90);
    
    const dateToFormatted = dateTo.toISOString().split('T')[0];
    const dateFromFormatted = dateFrom.toISOString().split('T')[0];

    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/member/card-transactions/count?memberId=`+memberInfo.memberId+`&companyId=`+memberInfo.companyId+`&dateFrom=`+dateFromFormatted+`&dateTo=`+dateToFormatted+`&status=`+statusFilter
    );
    return response;
  }

  const {
    data: dataCardTransactions,
    isError: isErrorCardTransactions,
    isFetching: isFetchingCardTransactions,
    isLoading: isLoadingCardTransactions,
  } = useQuery(["cardTransactions", page, pageSize, statusFilter,memberInfo.memberId], () => fetchCardTransactions(page,pageSize,statusFilter),    {
    keepPreviousData: true,
  });

  const {
    data: dataCardTransactionsCount,
    isError: isErrorCardTransactionsCount,
    isFetching: isFetchingCardTransactionsCount,
    isLoading: isLoadingCardTransactionsCount,
  } = useQuery(["cardTransactionsCount", page, pageSize, statusFilter,memberInfo.memberId], () => fetchCardTransactionsCount(statusFilter),     {
    keepPreviousData: true,
  });


  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    props.setOpen(false);
  };


  return (
    <Dialog   maxWidth="100%"
      open={props.open}
      onClose={props.handleClose}
      disableEscapeKeyDown={true}
      fullScreen
      PaperProps={{
      style: {
        width: '90%',
        height: '90%',
        margin: 'auto',
      },
    }}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <DialogActions>
          <Button onClick={handleClose} color={"error"}>
             Close
          </Button>
        </DialogActions>

        <DialogContent sx={{ width: '95%' ,flexShrink: 0 }}>
          <br />

          {isLoadingCardTransactions || isLoadingCardTransactions ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', color:"primary" }}>
              <CircularProgress color="primary" />
            </div>
          : isErrorCardTransactions || isErrorCardTransactionsCount ? 
            <h4>There was an error getting the info</h4> 
            :
            (          
              <>
                  <Typography sx={{ width: '100%', flexShrink: 0, fontWeight:"bold" }}>Card Transactions             
                  <div style={{height:"2rem"}}>
                    {isFetchingCardTransactions || isFetchingCardTransactionsCount ? (<RefreshingIndicator  />) :""}
                    </div> 
                  </Typography>  
                  <div style={{paddingBottom:"1rem", display:"flex", alignContent:"space-between"}}>
                    <Chip style={{width:"10rem", marginRight:"1rem"}} onClick={() => {setStatusFilter("POSTED"); }} icon={<CheckCircleOutlineIcon color="primary"  />} label="Posted" variant={statusFilter==="POSTED"?"none":"outlined"} sx={statusFilter==="POSTED" ? { fontWeight:"bold", border:"1px solid black" } : {}} />
                    <Chip style={{width:"10rem", marginRight:"1rem"}} onClick={() => {setStatusFilter("PENDING"); }} icon={<PendingIcon color="secondary"  />} label="Pending" variant={statusFilter==="PENDING"?"none":"outlined"} sx={statusFilter==="PENDING" ? {fontWeight:"bold", border:"1px solid black" } : {}}  />
                    <Chip style={{width:"10rem", marginRight:"1rem"}} onClick={() => {setStatusFilter("DENIED"); }} icon={<DoDisturbIcon color="error"  />} label="Declined" variant={statusFilter==="DENIED"?"none":"outlined"}  sx={statusFilter==="DENIED" ? { fontWeight:"bold", border:"1px solid black" } : {}} />
                  </div>


                <CardTransactions 
                  isLoading={isLoadingCardTransactions} isError={isErrorCardTransactions} data={dataCardTransactions}
                  dataCount={dataCardTransactionsCount} isFetchingDataCount={isLoadingCardTransactionsCount}
                  statusFilter={statusFilter} setStatusFilter={setStatusFilter}
                  setPage={setPage} setPageSize={setPageSize} pageSize={pageSize} page={page}
                />
              </>



          )
          }


          <br />

      <br />
        </DialogContent>

      </Box>
    </Dialog>
  );
}
