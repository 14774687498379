import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";

export default function AddCompanyBalance(props) {
  const queryClient = useQueryClient();

  async function fetchCompanyData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/balance/companiesToAdd`
    );
    return response;
  }

  const { data, isLoading } = useQuery(
    ["/balance/companiesToAdd"],
    () => fetchCompanyData()
  );

  const [company, setCompany] = React.useState('');

  const handleChange = (event) => {
    setCompany(event.target.value);
  };

  const addCompanyMutation = useMutation(
    (addCompanyPayload) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/balance/company`,
        addCompanyPayload
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['balances']);
        props.handleClose();
      },
      onError: (err) => {
        console.error(err)
      },
    }
  );

  const saveCompany = (event) => {
    if (company==='' || company===null || company===undefined)
      return;
    addCompanyMutation.mutate(company)
  }

  return (    
    <div>
      <Box
      component="form"
      noValidate
      autoComplete="off"
    >
          { isLoading ? "" : 
      <Grid container>
      <FormControl fullWidth>
        <InputLabel id="simple-select-label">Company</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={company}
          label="Company"
          onChange={handleChange}
        >
           {data.data.map((menuItem) => (
              <MenuItem key={menuItem.id_company} value={menuItem.id_company} name={menuItem.name}>
                    {menuItem.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
        <Grid item xs={12} pt={3}>
          <Button fullWidth variant="contained"  onClick={saveCompany}>
            SAVE
          </Button>
        </Grid>
      </Grid>}
    </Box>  
    </div>
  );
}
