import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpService from "../services/HttpService";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from "react";
import OnboardingUpload from "../components/OnboardingUpload";
import OnboardingDetail from "../components/OnboardingDetail";
import OnboardingFileDetail from "../components/OnboardingFileDetail";

async function fetchBulkOnboardings() {
  const response = await HttpService.getAxiosClient().get(
    window.API_URL + `/onboarding/bulk`
  );
  return response;
}

export default function BulkOnboarding() {
  const [onboardingFileDetail, setOnboardingFileDetail] = useState();
  const [openUploadDlg, setOpenUploadDlg] = useState(false);
  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  
  const columns = [
    { field: "id", headerName: "id", width: 10, hide: true },
    { field: "employerName", headerName: "Employer", width: 200 },
    { field: "filename", headerName: "Filename", width: 350 },
    { field: "processStatus", headerName: "Status", width: 150, editable: false },
    { field: "numOfRecords", headerName: "Number of records", width: 150, editable: false },
    { 
      field: "viewDetails",
      headerName: "View Details",
      width: 100,
      renderCell: (params) => (
          <div key={"action." + params.id}>
              <IconButton
                key={"view." + params.id}
                onClick={() => {
                  setOnboardingFileDetail({ ...params.row })
              }}
              >
                <VisibilityIcon />
              </IconButton>
          </div>
        )
    },
  ];

 
  const { data, isLoading } = useQuery(
    ["bulk_onboardings"],
    fetchBulkOnboardings
  );

  const queryClient = useQueryClient();

  const uploadDialog = () => { return (
    <Dialog open={openUploadDlg} fullWidth onClose={() => {}} >
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>
              <Typography>Upload Onboarding File</Typography>
            </DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              setOpenUploadDlg(false);
              queryClient.invalidateQueries({ queryKey: ["bulk_onboardings"] });
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <OnboardingUpload/>
        </DialogContent>
      </Dialog>)
  }

  const onboardingFileDetailDialog = (event) => {
    return (
      <Dialog open={onboardingFileDetail!=="undefined"} fullWidth onClose={() => {}} maxWidth="xl">
        <DialogActions
          style={{ backgroundColor: "#DDE6ED", margin: 0, padding: 0 }}
        >
          <div style={{ width: "100%" }}>
            <DialogTitle>
              <Typography>Onboarding File Detail</Typography>
            </DialogTitle>
          </div>
          <IconButton
            onClick={() => {
              setOnboardingFileDetail();
            }}
          >
            <CloseIcon variant="Outlined" />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <OnboardingFileDetail onboardingInfo={onboardingFileDetail}/>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {isLoading ? (
        ""
      ) : (
        <Grid container p={0} m={0} spacing={0}>
          <Grid item xs={12}>
            <Box display="flex">
              <Typography variant="h5">Bulk Onboardings</Typography>
              <Button
                variant="outlined"
                size="small"
                sx={{ marginLeft: "10px" }}                
                onClick={() =>  setOpenUploadDlg(true)}
              >
                Upload Onboarding File
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%", height: "80vh" }}>
            <DataGrid
              rows={data.data}
              columns={columns}
              getRowHeight={() => 25}
              density="compact"
              disableDensitySelector
              disableColumnSelector
              disableColumnFilter
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Grid>
        </Grid>
      )}
      {onboardingFileDetail ? onboardingFileDetailDialog() : ""}
      {openUploadDlg ? uploadDialog() : ""}
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
