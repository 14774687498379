import React, { Fragment, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Typography } from "@mui/material";
import AssignedCardDetail from './AssignedCardDetail';
import RenderOnRole from './RenderOnRole';

export default function PayPeriods(props) {

    const onViewButtonClick = (e,params) => {
       setCardIdentifier(params.id);
       setOpenNewDlg(true);

    }

    const [openNewDlg, setOpenNewDlg] = useState(false);
    const [cardIdentifier, setCardIdentifier] = useState();

    const columns = [
        { field: "id", headerName: "ID", width: 70,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.periodStartDate}
                    </Typography>)
                } 
        }, 
        { field: "name", headerName: "Company Name", width: 150 ,
            renderCell: (params) => {

                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                    {params.row.name}
                </Typography>)
            } 
        },         
        { field: "companyId", headerName: "Company Id", width: 100, 
            renderCell: (params) => {

                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                    {params.row.companyId}
                </Typography>)
            } 
        },  
        { field: "periodStartDate", headerName: "Start Date", width: 130,
            renderCell: (params) => {

                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                    {params.row.periodStartDate}
                </Typography>)
            } 
        },  
        { field: "periodEndDate", headerName: "End Date", width: 130,
            renderCell: (params) => {

                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                    {params.row.periodEndDate}
                </Typography>)
            } 
        },  

      ];
    const {isLoading, isError, data} = props;
    return (

        <Fragment>
            <h1>Pay Periods</h1>
            {isLoading ? (
            <h4>Loading...</h4>
            ) : !isError ? (
            <div style={{ height: 500, width: "60%" }}>
                <DataGrid
                    columnVisibilityModel={{id: false}}
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rows={data?.data}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                    toolbar: {
                        showQuickFilter: true, 
                        quickFilterProps: { debounceMs: 500 },
                    },
                    }}
                    sx={{
                      "& .MuiButton-root": {
                        display: "none",
                      }
                    }}
                />
            </div>
            ) : (
            <h4>An error ocurred, please try again later...</h4>
            )}
            {openNewDlg ?
                <AssignedCardDetail
                    action="SAVE"
                    open={openNewDlg}
                    setOpen={setOpenNewDlg}
                    dialogTitle="Assigned Card Detail"
                    dialogContentText="To edit the information, click the pencil."
                    idBundleDetail={cardIdentifier}
                /> :""

            }

        </Fragment>

    );
}