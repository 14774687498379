import { Fragment, useState, forwardRef } from "react";
import HttpService from "../services/HttpService";
import { Container, Grid, Button, Alert as MuiAlert , Snackbar } from "@mui/material";
import uploadImg from "./../images/upload.svg";
// import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
// import DeleteIcon from "@mui/icons-material/Delete";
import FileUpload from "react-mui-fileuploader"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useMutation } from "@tanstack/react-query";


export default function UploadCipFile() {


  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

  const [filesToUpload, setFilesToUpload] = useState([])
  const [buttonToUploadEnabled, setButtonToUploadEnabled] = useState(false);

  const handleFilesChange = (files) => {
    setFilesToUpload([ ...files ])
    if (files.length===1) {
      setButtonToUploadEnabled(true);
    } else {
      setButtonToUploadEnabled(false);
    }
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
        return;
    }
    setFeedback({ open: false, type: "error", message: "" });
    };


  const uploadFileMutation = useMutation(
    (formData) => {
      const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
      };
      return (
      HttpService.getAxiosClient().post(
        window.API_URL + `/paycards/cip/file`,
        formData,
        config
      ))
    },
    {
      onSuccess: () => {
        setFeedback({
          open: true,
          type: "success",
          message: "file uploaded succesfully",
          onClose: () => {  setFeedback({open: false}); },
        });
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );


  const uploadFiles = () => {
    let formData = new FormData()
    filesToUpload.forEach((file) => formData.append("file", file))
    uploadFileMutation.mutateAsync(formData);
  }


  return (
    <Container maxWidth="xlg">
      <br />
      <br />
      <Grid container spacing={1.5} style={{maxWidth:"80%"}}>
        <Grid item xs={12} md={4}>
          <br />
          <br />
          <img src={uploadImg} alt="upload" width="90%" loading="lazy" />
        </Grid>
        <Grid item xs={12} md={8}>
          <h1>Upload CIP File</h1>

          <Fragment>
            <FileUpload
              multiFile={false}
              onFilesChange={handleFilesChange}
              title="Please upload the CSV file"
              showPlaceholderImage={false}
              allowedExtensions={['csv']}
              maxUploadFiles={1}
              header="> DRAG AND DROP HERE <"
              onContextReady={(context) => {}}
            />
              <Button
                  disabled={!buttonToUploadEnabled}
                  style={{ margin: "10px" }}
                  onClick={(e) => uploadFiles(e)}
                  variant="contained"
                >
                  <CloudUploadIcon style={{paddingRight:"5px"}}/>
                  Upload
              </Button>
          </Fragment>
        </Grid>
      </Grid>


      <Snackbar
          open={feedback.open}
          autoHideDuration={6000}
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
          <Alert
              onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
              severity={feedback.type}
              sx={{ width: "100%" }}
          >
              {feedback.message}
          </Alert>
      </Snackbar>

    </Container>
  );
}
