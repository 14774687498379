import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid } from "@mui/material";
import Payments from "../components/Payments";
import CardStatsElement from "../components/CardStatsElement";
import UserService from "../services/UserService";
import {useState} from  'react';

export default function QueryPayments() {

  const [page, setPage] = useState(0);
  const [filterString, setFilterString] = useState("");



  async function fetchPayments(page, filterString) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/payfile/all?pageSize=10&page=`+page +`&filter=`+filterString
    );
    return response;
  }

  async function fetchPaymentsCount(filterString) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/payfile/all/count?filter=`+filterString
    );
    return response;
  }

  async function fetchPayfileStats() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/payfile/stats`
    );
    return response;
  }

  async function fetchLocations() {
    if (UserService.hasRole(['pc-query-locations'])){
      const response = await HttpService.getAxiosClient().get(
        window.API_URL + `/locations`
      );
      return response;
    }
    return [];
  }

  const {
    data: dataStats,
    isLoading: isLoadingStats,
    isError: isErrorStats,
    refetch: refetchStats,
  } = useQuery(["payfileStats"], fetchPayfileStats);


  const { 
    data, 
    isFetching, 
    isLoading, 
    isError, 
    refetch 
  } = useQuery(["payments",page, filterString], () => fetchPayments(page, filterString), {
    keepPreviousData: true
  });

  const { 
    data : dataCount, 
    isFetching : isFetchingDataCount, 
    isLoading : isLoadingDataCount, 
    isError: isErrorDataCount, 
    refetch: refetchDataCount 
  } = useQuery(["paymentsCount", filterString], () => fetchPaymentsCount(filterString), {
    keepPreviousData: true
  });


  const {     
    data: dataLocations,
    isLoading: isLoadingLocations,
    isError: isErrorLocations, 
  } = useQuery(["locations"], fetchLocations);

  return (
    <Box sx={{margin: '0', padding: '0 150px'}}>
      <br />
      <Grid container spacing={2}>
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Payments received today" data={dataStats && dataStats.data.receivedToday} type="amount" />
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Payments loaded today" data={dataStats && dataStats.data.loadedToday} type="amount" />
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Payments pending today" data={dataStats && dataStats.data.pendingToday} type="amount" />
        <CardStatsElement isErrorStats={isErrorStats} isLoadingStats={isLoadingStats} text="Total pending payments" data={dataStats && dataStats.data.pendingTotal} type="amount" />

      </Grid>
      <br />
      <br />
      <Payments 
        isFetching={isFetching} 
        isLoading={isLoading} 
        isError={isError} 
        data={data} 
        dataStats={dataStats} 
        dataLocations={dataLocations} 
        isLoadingLocations={isLoadingLocations} 
        isErrorLocations={isErrorLocations} 
        refetchFunction={refetch} 
        refetchStatsFunction={refetchStats} 
        setPageFunction={setPage}
        setFilterStringFunction={setFilterString}
        dataCount={dataCount} 
        isFetchingDataCount={isFetchingDataCount}
        isLoadingDataCount={isLoadingDataCount}
        isErrorDataCount={isErrorDataCount}
        refetchDataCount={refetchDataCount} 
        title="Payments"
        type="all"
      />
    </Box>
  );
}
