import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import HttpService from "../services/HttpService";
import { useMutation, useQueryClient  } from "@tanstack/react-query";
import UserService from "../services/UserService";

export default function CreatePayFile(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [rows, setRows] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const { data, isLoading } = useQuery(["members", inputValue], () =>
    fetchMembersData(inputValue)
  );
  const { data: descriptorData, isLoading: descriptorIsLoading } = useQuery(
    ["descriptors",props.idEmployer ],
    () => fetchDescriptorData()
  );

  const [isProcessing, setIsProcessing] = useState(false);

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  async function fetchDescriptorData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer/descriptors?idEmployer=`+props.idEmployer
    );
    return response;
  }

  async function fetchMembersData(inputValue) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/member/all?search=${inputValue}`
    );
    return response;
  }

  const queryClient = useQueryClient()

  const uploadFileMutation = useMutation(
    (formData) => {
      const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      };
      const company = formData.get('company');
      return (
      HttpService.getAxiosClient().post(
        window.API_URL + `/payfile/upload?idEmployer=`+company,
        formData,
        config
      ))
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['paymentsSummary'] });
        props.setCreateNewDlg(false);
        setFeedback({
          open: true,
          type: "success",
          message: "file uploaded succesfully",
          onClose: () => { 
            props.setOpen(false);
            setFeedback({open: false});
          },
        });
      },
      onError: (err) => {
        const msg = err?.response?.data
          ? err.response.data
          : err.message;
        //props.setCreateNewDlg(false);

        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const steps = [
    "Select Individuals to Pay",
    "Define Payment Amounts",
    "Confirm and Generate Payfile",
  ];

  const apiRef = useGridApiRef();

  const handleCellEditCommit = useCallback(
    ({ id, field, value }) => {
      if (field === "amount") {
        const newValue = Number(value);
        if (newValue < 0 || newValue > 25000) {
          setFeedback({
            open: true,
            type: "error",
            message: "Amount must be between 0 and 25000",
          });
          const row = rows.find((row) => row.id === id);
          const originalValue = row[field];
          setRows((prevRows) => {
            const index = prevRows.findIndex((row) => row.id === id);
            if (index > -1) {
              prevRows[index][field] = originalValue;
            }
            return [...prevRows];
          });
          return;
        }
      }
      setRows((prevRows) => {
        const index = prevRows.findIndex((row) => row.id === id);
        if (index > -1) {
          prevRows[index][field] = value;
        }
        return [...prevRows];
      });
    },
    [rows, setRows]
  );

  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    { field: "companyId", headerName: "Company ID", width: 90, hide: true },    
    { field: "memberId", headerName: "Member Id", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "right",
      width: 200,
      type: "number",
      align: "right",
      editable: true,
      renderCell: (params) => {
        return (
          <span>
            ${" "}
            {Number(params.value).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        );
      },
    },
    {
      field: "descriptor",
      headerName: "Descriptor",
      width: 200,
      editable: true,
      valueOptions: descriptorIsLoading ? [] : descriptorData.data
      .filter(descriptor => {
        const id = Number(descriptor.split(' - ')[0]);
        return id !== -1 || UserService.hasRole(['pf-unload']);
      })
      .sort((a, b) => {
        const idA = Number(a.split(' - ')[0]);
        const idB = Number(b.split(' - ')[0]);
        if (idA === -1) return 1;
        if (idB === -1) return -1;
        return idA - idB;
    }),
      type: "singleSelect",
      renderCell: (params) => {
        const color = Number(params.value.split(' - ')[0]) === -1 ? 'red' : 'black';
        if (color === 'red') {
          return <span style={{ color: color, fontWeight: 'bold' }}>{params.value}</span>;
        }else{
          return <span style={{ color: color }}>{params.value}</span>;
        }
      },
    },
  ];

  const fillAmounts = () => (
    <DataGrid
      autoHeight
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      getRowHeight={() => 30}
      onCellEditCommit={handleCellEditCommit}
      hideFooterPagination={true}
      hideFooterSelectedRowCount={true}
      isRowSelectable={(params) => false}
    />
  );

  const debouncedSetInputValue = useMemo(() => {
    let timerId;
    return (value) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setInputValue(value);
      }, 400);
    };
  }, []);

  useEffect(() => {
    if (activeStep === 1) {

      // if selectedMembers.memberId is not in rows, then include it
      const selectedMemberIds = selectedMembers.map(
        (selectedMember) => selectedMember.memberId
      );
      const rowsMemberIds = rows.map((row) => row.memberId);
      const newMembers = selectedMembers.filter(
        (selectedMember) => !rowsMemberIds.includes(selectedMember.memberId)
      );
      if (newMembers.length > 0) {
        setRows((prevRows) => [
          ...prevRows,
          ...newMembers.map((member) => ({
            id: member.memberId,
            companyId: member.companyId,
            memberId: member.memberId,
            email: member.email,
            name: member.name,
            amount: 0,
            descriptor: "",
          })),
        ]);
      }
      
      // if rows.memberId is not in selectedMembers, then remove it from rows 
      const removedMembers = rows.filter(
        (row) => !selectedMemberIds.includes(row.memberId)
      );
      if (removedMembers.length > 0) {
        setRows((prevRows) =>
          prevRows.filter(
            (row) => !removedMembers.some((removedMember) => removedMember.id === row.id)
          )
        );
      }
    }
  }, [activeStep]);

  const chooseEmployees = () => (
    <Autocomplete
      fullWidth
      multiple
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) =>
        option.memberId === value.memberId
      }
      id="companieList"
      options={!isLoading ? data.data : []}
      getOptionLabel={(option) =>
        `${option.memberId} - ${option.email} - ${option.name} `
      }
      filterSelectedOptions
      value={selectedMembers}
      onChange={(event, selectedOptions) => {
        setSelectedMembers(selectedOptions);
      }}
      onInputChange={(event, newInputValue) => {
        debouncedSetInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Member list" placeholder="Members" />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <div key={index} style={{ marginTop: 8 }}>
            <Chip
              label={`${option.memberId} - ${option.email} - ${option.name}`}
              {...getTagProps({ index })}
            />
          </div>
        ))
      }
    />
  );

  const summary = () => {
    const isNegativeOne = rows.every(row => row.descriptor.match(/^-?\d+/) && row.descriptor.match(/^-?\d+/)[0] === "-1");
    
    return (
    <div>
      <div>
        <h3>Summary</h3>
        {isNegativeOne ? <li style={{ color: 'red', fontWeight: 'bold' }}>This is an unload file</li> : ""}
        <li>Number of individuals to {!isNegativeOne? "pay" : "unload"}: {rows.length}</li>
        <li>
          Total amount  {isNegativeOne && "to unload"} :{" USD. "}
          {Number(
            rows.reduce((acc, row) => acc + row.amount, 0)
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </li>
      </div>
    </div>
  )}
  

  const generateAndUploadCSV = (data, filename, companyId) => {
    const csvContent =
      "EMPLOYER_ID,EMPLOYEE_ID,EMAIL,AMOUNT,COMPLETE_NAME,DESCRIPTOR_ID,PHONE\n" +
      data;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    //const url = URL.createObjectURL(blob);
    //const link = document.createElement("a");
    //link.setAttribute("href", url);
    //link.setAttribute("download", filename);
    //document.body.appendChild(link);
    //link.click();
   // URL.revokeObjectURL(url);
   // document.body.removeChild(link);

    const formData = new FormData();
    formData.append("file", blob, filename);
    formData.append("company", companyId);
    uploadFileMutation.mutateAsync(formData);
  };

  const handleStep = (action) => {
    if (action === "back") {
      setActiveStep((prev) => prev - 1);
      return;
    }
    if (activeStep === 0) {
      if (selectedMembers.length === 0) {
        setFeedback({
          open: true,
          type: "error",
          message: "Please select at least one individual to pay",
        });
        return;
      }
    }
    if (activeStep === 1) {
      // Check if all rows have amount filled greater than 0
      if (rows.some((row) => row.amount === 0)) {
        setFeedback({
          open: true,
          type: "error",
          message: "Please fill the amount for each individual",
        });
        return;
      }
      // Check if all rows have descriptor filled
      if (rows.some((row) => row.descriptor === "")) {
        setFeedback({
          open: true,
          type: "error",
          message: "Please fill the descriptor for each individual",
        });
        return;
      }

      const hasNegativeOneDescriptor = rows.some(row => row.descriptor.match(/^-?\d+/) && row.descriptor.match(/^-?\d+/)[0] === "-1");
      const allRowsNegativeOne = rows.every(row => row.descriptor.match(/^-?\d+/) && row.descriptor.match(/^-?\d+/)[0] === "-1");
      
      if (hasNegativeOneDescriptor && !allRowsNegativeOne) {
        setFeedback({
          open: true,
          type: "error",
          message: "Not allowed to mix descriptors with -1 with other descriptors",
        });
      return;
      }
    }
    setActiveStep((prev) => prev + 1);
  };


  return (
    <div sx={{ border: "1px solid red", borderRadius: "10px" }}>
      <Dialog
        open={props.openCreateDlg}
        maxWidth="xl"
        fullWidth
        sx={{ border: "1px solid blue", borderRadius: "10px" }}
        onClose={() => props.setCreateNewDlg(false)}
        disableEscapeKeyDown={true}
      >
        <DialogTitle>Create Pay File</DialogTitle>
        <DialogContent sx={{ height: "70vh" }}>
          <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 0 ? chooseEmployees() : ""}
          {activeStep === 1 ? fillAmounts() : ""}
          {activeStep === 2 ? summary() : ""}
        </DialogContent>
        <DialogActions>
          {activeStep > 0 && activeStep < 3 ? (
            <Button onClick={() => handleStep("back")}>
              Back
            </Button>
          ) : (
            ""
          )}
          {activeStep < 2 ? (
            <Button onClick={() => handleStep("next")}>
              Next
            </Button>
          ) : (
            ""
          )}
          {activeStep === 2 ? (
            <Button
              disabled={uploadFileMutation.isLoading}
              color="primary"
              variant="contained"
              onClick={() => {
                const csvData = rows
                  .map(
                    (row) =>
                      `${row.companyId},${row.memberId},${row.email},${row.amount},${row.name},${row.descriptor.match(/^-?\d+/)[0]}`
                  )
                  .join("\n");

                  const currentDate = new Date();
                  const year = currentDate.getFullYear();
                  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
                  const day = String(currentDate.getDate()).padStart(2, '0');
                  const hours = String(currentDate.getHours()).padStart(2, '0');
                  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
                  const seconds = String(currentDate.getSeconds()).padStart(2, '0');
                  const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
                  const currentTime = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
                  const companyIdForFile = (props.idEmployer!==undefined && props.idEmployer!==null && props.idEmployer!=="") ? props.idEmployer : rows[0].companyId;
                  const isNegativeOne = rows.every(row => row.descriptor.match(/^-?\d+/) && row.descriptor.match(/^-?\d+/)[0] === "-1");
                  const filename = isNegativeOne ? "Unload_Input_"+companyIdForFile+"_"+currentTime : "PayList_Input_"+companyIdForFile+"_"+currentTime;
                  generateAndUploadCSV(csvData, filename, companyIdForFile);
              }}
            >
            PROCESS
                  {uploadFileMutation.isLoading ? 
                  <CircularProgress style={{color:"white" , marginLeft:"10px", marginRight:"10px"}} size="1rem" color="inherit" />
                  : "" }

            </Button>
          ) : (
            ""
          )}
          <Button color="secondary" onClick={() => props.setCreateNewDlg(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback({ ...feedback, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setFeedback({ ...feedback, open: false })}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
