// import "bootstrap-css-only/css/bootstrap.min.css";
// import "mdbreact/dist/css/mdb.css";

import { BrowserRouter } from "react-router-dom";
import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Welcome from "../pages/Welcome";
import MainSite from "../pages/MainSite";
import { ThemeProvider } from "@mui/material";
import theme from "../theme/theme";

const queryClient = new QueryClient();



function App() {
  return (
    <div>

    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <RenderOnAnonymous>
            <Welcome />
          </RenderOnAnonymous>
          <RenderOnAuthenticated>
            <MainSite />
          </RenderOnAuthenticated>
        </BrowserRouter>
      </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
