import React, { Fragment } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Typography  } from "@mui/material";


export default function MissingTimeAttendance(props) {

    const columns = [
        { field: "id", headerName: "ID", width: 70},
        { field: "transactionDate", headerName: "Transaction Date", width: 130,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.transactionDate}
                    </Typography>)
            } 
        },
        { field: "transactionDescription", headerName: "Description", width: 120,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.transactionDescription}
                    </Typography>)
            } 
        },
        { field: "email", headerName: "Email", width: 180,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.email}
                    </Typography>)
            } 
        },
        { field: "firstName", headerName: "First Name", width: 100,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.firstName}
                    </Typography>)
            } 
        },
        { field: "lastName", headerName: "Last Name", width: 100,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.lastName}
                    </Typography>)
            } 
        },
        { field: "memberId", headerName: "Member Id", width: 120,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.memberId}
                    </Typography>)
            } 
        },
        { field: "companyName", headerName: "Company Name", width: 200,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.companyName+" ("+params.row.companyId+")"}
                    </Typography>)
            } 
        },
        { field: "amount", headerName: "Amount", width: 90,
            renderCell: (params) => {
                return (<Typography sx={{   fontSize: 12, mb: 0 }}   gutterBottom>
                        {params.row.amount}
                    </Typography>)
            } 
        },
      ];
    const {isLoading, isError, data} = props;
    return (

        <Fragment>
            <h1>Missing Time and Attendance</h1>
            {isLoading ? (
            <h4>Loading...</h4>
            ) : !isError ? (
            <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                    columnVisibilityModel={{id: false}}
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    rows={data?.data}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                    toolbar: {
                        showQuickFilter: true, 
                        quickFilterProps: { debounceMs: 500 },
                    },
                    }}
                />
            </div>
            ) : (
            <h4>An error ocurred, please try again later...</h4>
            )}
        </Fragment>
    );
}